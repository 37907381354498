//Reveal

.reveal {
  min-height: 550px;
}

.reveal_list {
  background-color: $white;
}

.reveal_heading {
  font-size: 38px;
  margin-bottom: $padding-large;
}

.reveal_term {
  display: block;
  padding: $padding-large $padding-vertical;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: $light-action-color;
  }

  &:after {
    content: '';
    display: block;
    bottom: -40px;
    transition: bottom .3s ease-out;
  }
}

.reveal_term--is-selected {
  background-color: $light-copen-blue;

  &:hover {
    background-color: $light-copen-blue;
  }

  &:after {
    border-bottom: 34px solid $blue;
    border-left: 34px solid transparent;
    border-right: 34px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    bottom: -0;
    left: calc(50% - 34px);
  }
}

.reveal_definition {
  max-height: 0;
  overflow: hidden;
}

.reveal_definition--is-active {
  max-height: 600px;
  transition: max-height .5s ease-out;
}
