// Transaction Table

.transaction-table {
  font-size: 14px;

  @media
    not screen and (-webkit-min-device-pixel-ratio: 2),
    not screen and (   min--moz-device-pixel-ratio: 2),
    not screen and (     -o-min-device-pixel-ratio: 2/1),
    not screen and (        min-device-pixel-ratio: 2),
    not screen and (                min-resolution: 192dpi),
    not screen and (                min-resolution: 2dppx) { 
      /* non-Retina-specific stuff here */
      font-weight: bold;
  }

  @media screen and (max-width: $screen-xs-max) {
    display: block;

    thead {
      display: none;
    }

    tbody {
      display: block;
    }

    tfoot {
      display: block;
    }
  }

  tfoot {
    border-top: 2px solid $base-font-color;

    tr {
      border-bottom: none;
    }

    td {
      padding-top: $padding-horizontal;
      border-bottom: none;
    }
  }
  
  tr {

    @media screen and (max-width: $screen-xs-max) {
      border-bottom: 1px solid $transparent-gray;
      padding-top: $padding-horizontal;
      padding-bottom: $padding-horizontal;
      position: relative;
      display: block;
      min-height: 110px;
    }

    > * {
      @media screen and (max-width: $screen-xs-max) {
        display: block;
        border: none;
        padding-top: 2px;
        padding-bottom: 2px;

        &:nth-child(1) {
          width: 90px;
          position: absolute;
          top: $padding-horizontal;
          left: 0;
          font-size: 12px;
          opacity: .75;
          padding-right: 0;
        }

        &:nth-child(2) {
          width: 75px;
          position: absolute;
          top: $padding-horizontal;
          left: 80px;
          font-size: 12px;
          opacity: .75;
          padding-right: 0;
          padding-left: 0;
        }

        &:nth-child(3) {
          width: 60%;
          padding-top: 32px;
        }

        &:nth-child(4) {
          width: 60%;
        }

        &:nth-child(5) {
          width: 38%;
          margin-bottom: $padding-small;
          border-bottom: 1px dashed rgba($base-font-color, .5);
          padding-bottom: $padding-small;
          font-size: 16px;
          position: absolute;
          top: $padding-horizontal;
          right: 0;
        }

        &:nth-child(6) {
          width: 38%;
          position: absolute;
          top: 40px;
          right: 0;
        }

        &:nth-child(7) {
          width: 38%;
          position: absolute;
          top: 60px;
          right: 0;
        }

        &:nth-child(8) {
          width: 38%;
          position: absolute;
          top: 80px;
          right: 0;
        }
      }

      @media screen and (min-width: $screen-sm-min) {
        border-bottom-color: $transparent-gray;
      }

      &:nth-last-child(1),
      &:nth-last-child(2),
      &:nth-last-child(3) {
        text-align: right;
      }
    }

    > th {
      vertical-align: bottom;
    }

    a {
      color: $base-font-color;
      text-decoration: underline;

      &:hover {
        color: $action-color;
      }
    }
  }  
}
