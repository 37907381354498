//Users

.user {
  border-top: 1px solid rgba($white, .25);
  display: table;
  width: 100%;

  &:last-child {
    border-bottom: 1px solid rgba($white, .25);
  }
}

.user_cell {
  display: table-cell;
  padding: $padding-small;
  vertical-align: middle;
}

.user_cell--action {
  text-align: right;

  .btn {
    width: 70px;
  }
}

.user_link {
  color: $white;

  &:hover {
    color: $white;

    h3 {
      text-decoration: underline;
    }
  }
}

.user_avatar {
  display: inline-block;
  vertical-align: middle;
  width: 50px;

  img,
  .avatar {
    display: block;
    border: 1px solid rgba($white, .5);
  }
}

.user_name {
  display: inline-block;
  vertical-align: middle;
  padding-left: $padding-horizontal;

  @media screen and (max-width: $screen-base-max) {
    h3 {
      max-width: 125px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
