//Derbicons

.dw--neg-75x {
  font-size: .75em;
}

.dw--1-25x {
  font-size: 1.25em;
}

.dw--1-5x {
  font-size: 1.5em;
}

.dw--1-75x {
  font-size: 1.75em;
}

.dw--2x {
  font-size: 2em;
}

.dw--3x {
  font-size: 3em;
}

.dw--4x {
  font-size: 4em;
}

.dw--5x {
  font-size: 5em;
}

.dw--r-neg-10 {
  display: inline-block;
  transform: rotate(-10deg);
}

.dw--r-45 {
  display: inline-block;
  transform: rotate(45deg);
}

.dw--r-90 {
  display: inline-block;
  transform: rotate(90deg);
}

.dw--valign-top {
  vertical-align: top !important;

  & + span {
    vertical-align: top !important;
  }
}

@font-face {
  font-family: 'derbicons';
  src: url("../public/fonts/derbicons.ttf") format("truetype"), url("../public/fonts/derbicons.woff") format("woff"), url("../public/fonts/derbicons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="dw-"], [class*=" dw-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'derbicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & + span {
    vertical-align: middle;
  }
}

.dw--baseline {
  & + span {
    vertical-align: baseline;
  }
}

.dw-horseshoe-4-solid:before {
  content: "\e92e";
}

.dw-horseshoe-3-solid:before {
  content: "\e92f";
}

.dw-horseshoe-2-solid:before {
  content: "\e93f";
}

.dw-horseshoe-solid:before {
  content: "\e902";
}

.dw-caret-double:before {
  content: "\e930";
}

.dw-caret:before {
  content: "\e9cf";
}

.dw-horse-running-jockey:before {
  content: "\e95a";
}

.dw-horse-running:before {
  content: "\e964";
}

.dw-horse-head:before {
  content: "\e963";
}

.dw-ticket-solid-outline-dashed:before {
  content: "\e959";
}

.dw-harness-rider:before {
  content: "\e929";
}

.dw-harness:before {
  content: "\e92a";
}

.dw-wheel:before {
  content: "\e92b";
}

.dw-real-money:before {
  content: "\e907";
}

.dw-bullet-solid:before {
  content: "\e91b";
}

.dw-survivor:before {
  content: "\e9dc";
}

.dw-points-solid:before {
  content: "\e94f";
}

.dw-points:before {
  content: "\e950";
}

.dw-token:before {
  content: "\e932";
}

.dw-ticket-solid:before {
  content: "\e931";
}

.dw-horseshoe:before {
  content: "\e900";
}

.dw-document-diagrams:before {
  content: "\e92d";
}

.dw-warning6:before {
  content: "\e9cb";
}

.dw-warning7:before {
  content: "\e9cc";
}

.dw-document-edit:before {
  content: "\e970";
}

.dw-clipboard-edit:before {
  content: "\e971";
}

.dw-award:before {
  content: "\e933";
}

.dw-award2:before {
  content: "\e934";
}

.dw-award3:before {
  content: "\e935";
}

.dw-award4:before {
  content: "\e936";
}

.dw-calendar2:before {
  content: "\e9c1";
}

.dw-calendar:before {
  content: "\e96f";
}

.dw-phone:before {
  content: "\e987";
}

.dw-tablet:before {
  content: "\e988";
}

.dw-window:before {
  content: "\e989";
}

.dw-monitor:before {
  content: "\e98a";
}

.dw-tv:before {
  content: "\e904";
}

.dw-camera:before {
  content: "\e905";
}

.dw-film:before {
  content: "\e98c";
}

.dw-drink:before {
  content: "\e937";
}

.dw-drink2:before {
  content: "\e938";
}

.dw-mug:before {
  content: "\e939";
}

.dw-checkmark:before {
  content: "\e948";
}

.dw-checkmark3:before {
  content: "\e949";
}

.dw-minus:before {
  content: "\e951";
}

.dw-minus2:before {
  content: "\e952";
}

.dw-notice:before {
  content: "\e9c2";
}

.dw-notice2:before {
  content: "\e9c3";
}

.dw-cog:before {
  content: "\e985";
}

.dw-warning3:before {
  content: "\e9c4";
}

.dw-pacman:before {
  content: "\e98e";
}

.dw-chronometer:before {
  content: "\e95b";
}

.dw-alarmclock:before {
  content: "\e95c";
}

.dw-time:before {
  content: "\e95d";
}

.dw-wallet:before {
  content: "\e906";
}

.dw-checkmark4:before {
  content: "\e94a";
}

.dw-sitemap:before {
  content: "\e98f";
}

.dw-sitemap2:before {
  content: "\e990";
}

.dw-chart:before {
  content: "\e991";
}

.dw-chart2:before {
  content: "\e986";
}

.dw-chart3:before {
  content: "\e998";
}

.dw-chart4:before {
  content: "\e9c6";
}

.dw-chart5:before {
  content: "\e9c7";
}

.dw-gamepad:before {
  content: "\e992";
}

.dw-sale:before {
  content: "\e993";
}

.dw-direction:before {
  content: "\e994";
}

.dw-graduate:before {
  content: "\e995";
}

.dw-medal:before {
  content: "\e909";
}

.dw-medal2:before {
  content: "\e90a";
}

.dw-key:before {
  content: "\e90b";
}

.dw-locked:before {
  content: "\e90c";
}

.dw-unlocked:before {
  content: "\e90d";
}

.dw-unlocked2:before {
  content: "\e90f";
}

.dw-spaceinvaders:before {
  content: "\e997";
}

.dw-bomb:before {
  content: "\e910";
}

.dw-megaphone:before {
  content: "\e911";
}

.dw-megaphone2:before {
  content: "\e912";
}

.dw-lamp:before {
  content: "\e999";
}

.dw-cube:before {
  content: "\e99b";
}

.dw-box:before {
  content: "\e99c";
}

.dw-moneybag:before {
  content: "\e913";
}

.dw-tools:before {
  content: "\e99d";
}

.dw-chat2:before {
  content: "\e93a";
}

.dw-comments:before {
  content: "\e93b";
}

.dw-chat3:before {
  content: "\e93c";
}

.dw-chat4:before {
  content: "\e93d";
}

.dw-equalizer:before {
  content: "\e914";
}

.dw-stats:before {
  content: "\e9d0";
}

.dw-pen:before {
  content: "\e972";
}

.dw-pin:before {
  content: "\e915";
}

.dw-edit:before {
  content: "\e973";
}

.dw-profile:before {
  content: "\e974";
}

.dw-profile2:before {
  content: "\e975";
}

.dw-profile3:before {
  content: "\e976";
}

.dw-dollar:before {
  content: "\e916";
}

.dw-dollars:before {
  content: "\e917";
}

.dw-coins:before {
  content: "\e9d1";
}

.dw-pig:before {
  content: "\e918";
}

.dw-bookmark:before {
  content: "\e919";
}

.dw-envelope:before {
  content: "\e977";
}

.dw-envelope2:before {
  content: "\e978";
}

.dw-heart:before {
  content: "\e91a";
}

.dw-rocket:before {
  content: "\e99e";
}

.dw-blocked:before {
  content: "\e91f";
}

.dw-tags:before {
  content: "\e920";
}

.dw-heart2:before {
  content: "\e921";
}

.dw-heart3:before {
  content: "\e922";
}

.dw-clipboard2:before {
  content: "\e99f";
}

.dw-clipboard3:before {
  content: "\e9a0";
}

.dw-checkmark5:before {
  content: "\e94b";
}

.dw-check-alt:before {
  content: "\e94c";
}

.dw-denied:before {
  content: "\e9c5";
}

.dw-cog2:before {
  content: "\e9d2";
}

.dw-plus-alt:before {
  content: "\e953";
}

.dw-minus3:before {
  content: "\e954";
}

.dw-minus-alt:before {
  content: "\e955";
}

.dw-lightbulb:before {
  content: "\e9a2";
}

.dw-transfer:before {
  content: "\e927";
}

.dw-pen-alt-fill:before {
  content: "\e97a";
}

.dw-add2:before {
  content: "\e956";
}

.dw-subtract:before {
  content: "\e957";
}

.dw-alarm2:before {
  content: "\e9cd";
}

.dw-stats2:before {
  content: "\e9d3";
}

.dw-dashboard:before {
  content: "\f07d";
}

.dw-graph:before {
  content: "\f043";
}

.dw-link:before {
  content: "\f05c";
}

.dw-link-external:before {
  content: "\f07f";
}

.dw-list-ordered:before {
  content: "\f062";
}

.dw-list-unordered:before {
  content: "\f061";
}

.dw-move-down:before {
  content: "\f0a8";
}

.dw-move-left:before {
  content: "\f074";
}

.dw-move-right:before {
  content: "\f0a9";
}

.dw-move-up:before {
  content: "\f0a7";
}

.dw-pencil3:before {
  content: "\f058";
}

.dw-pulse:before {
  content: "\f085";
}

.dw-three-bars:before {
  content: "\f05e";
}

.dw-quill:before {
  content: "\e97c";
}

.dw-film2:before {
  content: "\e9ce";
}

.dw-price-tag:before {
  content: "\e924";
}

.dw-price-tags:before {
  content: "\e925";
}

.dw-coin-dollar:before {
  content: "\e903";
}

.dw-lifebuoy:before {
  content: "\e9a3";
}

.dw-alarm:before {
  content: "\e95e";
}

.dw-user2:before {
  content: "\e97d";
}

.dw-users2:before {
  content: "\e97e";
}

.dw-user-plus:before {
  content: "\e97f";
}

.dw-user-minus:before {
  content: "\e980";
}

.dw-user-check:before {
  content: "\e981";
}

.dw-enlarge:before {
  content: "\e908";
}

.dw-shrink:before {
  content: "\e90e";
}

.dw-enlarge2:before {
  content: "\e91c";
}

.dw-shrink2:before {
  content: "\e91d";
}

.dw-magic-wand:before {
  content: "\e9a4";
}

.dw-stats-bars:before {
  content: "\e9d4";
}

.dw-list-numbered:before {
  content: "\e9d5";
}

.dw-list3:before {
  content: "\e9d6";
}

.dw-list2:before {
  content: "\e9d7";
}

.dw-tree4:before {
  content: "\e944";
}

.dw-warning5:before {
  content: "\e9c9";
}

.dw-notification:before {
  content: "\e9ca";
}

.dw-checkmark6:before {
  content: "\e94d";
}

.dw-checkmark2:before {
  content: "\e94e";
}

.dw-move-up2:before {
  content: "\e91e";
}

.dw-move-down2:before {
  content: "\e926";
}

.dw-window2:before {
  content: "\e9a5";
}

.dw-gamepad2:before {
  content: "\e9a6";
}

.dw-gamepad3:before {
  content: "\e9a7";
}

.dw-shield:before {
  content: "\e9a8";
}

.dw-bug:before {
  content: "\e9aa";
}

.dw-earth:before {
  content: "\e9ab";
}

.dw-addressbook:before {
  content: "\e92c";
}

.dw-clipboard:before {
  content: "\e984";
}

.dw-board:before {
  content: "\e9ad";
}

.dw-user3:before {
  content: "\e9ae";
}

.dw-user4:before {
  content: "\e9af";
}

.dw-book:before {
  content: "\e901";
}

.dw-gift:before {
  content: "\e93e";
}

.dw-lamp3:before {
  content: "\e9b0";
}

.dw-support:before {
  content: "\e9b1";
}

.dw-flag5:before {
  content: "\e9b4";
}

.dw-star2:before {
  content: "\e9b5";
}

.dw-cup2:before {
  content: "\e9b6";
}

.dw-lightning:before {
  content: "\e9b7";
}

.dw-tree2:before {
  content: "\e942";
}

.dw-direction2:before {
  content: "\e9b8";
}

.dw-thumbsup:before {
  content: "\e9b9";
}

.dw-thumbsdown:before {
  content: "\e9ba";
}

.dw-pointer:before {
  content: "\e9bb";
}

.dw-pointer2:before {
  content: "\e9bc";
}

.dw-pointer3:before {
  content: "\e9bd";
}

.dw-pointer4:before {
  content: "\e9be";
}

.dw-flag3:before {
  content: "\e945";
}

.dw-warning-outline:before {
  content: "\e9c8";
}

.dw-error:before {
  content: "\e000";
}

.dw-error_outline:before {
  content: "\e001";
}

.dw-warning:before {
  content: "\e002";
}

.dw-equalizer2:before {
  content: "\e01d";
}

.dw-not_interested:before {
  content: "\e033";
}

.dw-playlist_add:before {
  content: "\e03b";
}

.dw-videocam:before {
  content: "\e04b";
}

.dw-chat:before {
  content: "\e0b7";
}

.dw-comment:before {
  content: "\e0b9";
}

.dw-chat_bubble:before {
  content: "\e0ca";
}

.dw-chat_bubble_outline:before {
  content: "\e0cb";
}

.dw-add:before {
  content: "\e145";
}

.dw-add_box:before {
  content: "\e146";
}

.dw-add_circle:before {
  content: "\e147";
}

.dw-add_circle_outline:before {
  content: "\e148";
}

.dw-flag:before {
  content: "\e153";
}

.dw-border_color:before {
  content: "\e22b";
}

.dw-format_list_bulleted:before {
  content: "\e241";
}

.dw-format_list_numbered:before {
  content: "\e242";
}

.dw-insert_chart:before {
  content: "\e24b";
}

.dw-insert_comment:before {
  content: "\e24c";
}

.dw-mode_comment:before {
  content: "\e253";
}

.dw-laptop:before {
  content: "\e31e";
}

.dw-phone_android:before {
  content: "\e324";
}

.dw-broken_image:before {
  content: "\e3ad";
}

.dw-cancel:before {
  content: "\e5c9";
}

.dw-do_not_disturb:before {
  content: "\e612";
}

.dw-ondemand_video:before {
  content: "\e63a";
}

.dw-people:before {
  content: "\e7fb";
}

.dw-person:before {
  content: "\e7fd";
}

.dw-poll:before {
  content: "\e801";
}

.dw-star:before {
  content: "\e838";
}

.dw-star_half:before {
  content: "\e839";
}

.dw-star_outline:before {
  content: "\e83a";
}

.dw-favorite:before {
  content: "\e87d";
}

.dw-favorite_outline:before {
  content: "\e87e";
}

.dw-help:before {
  content: "\e887";
}

.dw-history:before {
  content: "\e889";
}

.dw-list:before {
  content: "\e896";
}

.dw-settings:before {
  content: "\e8b8";
}

.dw-thumb_down:before {
  content: "\e8db";
}

.dw-thumb_up:before {
  content: "\e8dc";
}

.dw-help_outline:before {
  content: "\e8fd";
}

.dw-indeterminate_check_box:before {
  content: "\e958";
}

.dw-watch_later:before {
  content: "\e95f";
}

.dw-clock:before {
  content: "\e960";
}

.dw-stopwatch:before {
  content: "\e961";
}

.dw-heart4:before {
  content: "\f004";
}

.dw-close:before {
  content: "\f00d";
}

.dw-remove:before {
  content: "\f00d";
}

.dw-times:before {
  content: "\f00d";
}

.dw-clock-o:before {
  content: "\f017";
}

.dw-download:before {
  content: "\f019";
}

.dw-flag2:before {
  content: "\f024";
}

.dw-bookmark2:before {
  content: "\f02e";
}

.dw-video-camera:before {
  content: "\f03d";
}

.dw-pencil:before {
  content: "\f040";
}

.dw-chevron-left:before {
  content: "\f053";
}

.dw-chevron-right:before {
  content: "\f054";
}

.dw-plus-circle:before {
  content: "\f055";
}

.dw-minus-circle:before {
  content: "\f056";
}

.dw-times-circle:before {
  content: "\f057";
}

.dw-mail-forward:before {
  content: "\f064";
}

.dw-share:before {
  content: "\f064";
}

.dw-exclamation-circle:before {
  content: "\f06a";
}

.dw-eye:before {
  content: "\f06e";
}

.dw-eye-slash:before {
  content: "\f070";
}

.dw-exclamation-triangle:before {
  content: "\f071";
}

.dw-warning2:before {
  content: "\f071";
}

.dw-chevron-up:before {
  content: "\f077";
}

.dw-chevron-down:before {
  content: "\f078";
}

.dw-bar-chart:before {
  content: "\f080";
}

.dw-bar-chart-o:before {
  content: "\f080";
}

.dw-twitter-square:before {
  content: "\f081";
}

.dw-facebook-square:before {
  content: "\f082";
}

.dw-heart-o:before {
  content: "\f08a";
}

.dw-trophy2:before {
  content: "\f091";
}

.dw-twitter:before {
  content: "\f099";
}

.dw-facebook:before {
  content: "\f09a";
}

.dw-facebook-f:before {
  content: "\f09a";
}

.dw-bell-o:before {
  content: "\f0a2";
}

.dw-certificate:before {
  content: "\f0a3";
}

.dw-filter:before {
  content: "\f0b0";
}

.dw-group:before {
  content: "\f0c0";
}

.dw-users:before {
  content: "\f0c0";
}

.dw-list-ul:before {
  content: "\f0ca";
}

.dw-list-ol:before {
  content: "\f0cb";
}

.dw-table:before {
  content: "\f0ce";
}

.dw-magic:before {
  content: "\f0d0";
}

.dw-google-plus-square:before {
  content: "\f0d4";
}

.dw-google-plus:before {
  content: "\f0d5";
}

.dw-lightbulb-o:before {
  content: "\f0eb";
}

.dw-bell:before {
  content: "\f0f3";
}

.dw-circle-o:before {
  content: "\f10c";
}

.dw-circle:before {
  content: "\f111";
}

.dw-search:before {
  content: "\f111";
}

.dw-instagram:before {
  content: "\f111";
}

.dw-flag-o:before {
  content: "\f11d";
}

.dw-flag-checkered:before {
  content: "\f11e";
}

.dw-chevron-circle-left:before {
  content: "\f137";
}

.dw-chevron-circle-right:before {
  content: "\f138";
}

.dw-chevron-circle-up:before {
  content: "\f139";
}

.dw-chevron-circle-down:before {
  content: "\f13a";
}

.dw-pencil-square:before {
  content: "\f14b";
}

.dw-tree:before {
  content: "\f1bb";
}

.dw-file-pdf-o:before {
  content: "\f1c1";
}

.dw-toggle-off:before {
  content: "\f204";
}

.dw-toggle-on:before {
  content: "\f205";
}

.dw-chevron-down2:before {
  content: "\e965";
}

.dw-chevron-left2:before {
  content: "\e966";
}

.dw-chevron-right2:before {
  content: "\e967";
}

.dw-chevron-up2:before {
  content: "\e968";
}

.dw-chevron-with-circle-down:before {
  content: "\e969";
}

.dw-chevron-with-circle-left:before {
  content: "\e96a";
}

.dw-chevron-with-circle-right:before {
  content: "\e96b";
}

.dw-chevron-with-circle-up:before {
  content: "\e96c";
}

.dw-dots-three-horizontal:before {
  content: "\e96d";
}

.dw-dots-three-vertical:before {
  content: "\e96e";
}

.dw-bar-graph:before {
  content: "\e9d8";
}

.dw-blackboard:before {
  content: "\e9d9";
}

.dw-flag4:before {
  content: "\e947";
}

.dw-gauge:before {
  content: "\e9da";
}

.dw-graduation-cap:before {
  content: "\e9db";
}

.dw-link2:before {
  content: "\e9dd";
}

.dw-share-alternative:before {
  content: "\e928";
}

.dw-time-slot:before {
  content: "\e962";
}

.dw-trophy:before {
  content: "\e923";
}
