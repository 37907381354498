//Friendly Message

.friendly-message {
  color: $dark-gray;
  background-color: $white;
  border: 1px solid $action-color;
  border-radius: $base-border-radius;
  padding: $padding-vertical;
  margin: 0 auto $padding-vertical;
  text-align: center;
  max-width: calc(100% - #{$padding-vertical});
  position: relative;
  opacity: 0;
  animation: appearup .3s .2s ease-out forwards;

  @media screen and (min-width: $screen-xs-max) {
    max-width: 600px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 0;
  }

  p {
    line-height: 1.25em;
  }

  .btn {
    margin-top: $padding-horizontal;
  }
}

.friendly-message--has-avatar {

  @media screen and (min-width: $screen-xs-max) {
    padding-left: 55px;
    padding-right: 55px;

    &::before {
      position: absolute;
      display: block;
      content: '';
      height: 100px;
      width: 90px;
      background-image: $brand-logo-avatar;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      top: calc(50% - 50px);
      left: calc(0px - 45px);
    }
  }
}

.friendly-message--has-logo {

  @media screen and (min-width: $screen-xs-max) {
    padding-top: 30px;
    margin-top: 85px;

    &::before {
      position: absolute;
      display: block;
      content: '';
      height: 80px;
      width: 220px;
      background-image: $brand-logo-color;
      background-repeat: no-repeat;
      background-size: 100% auto;
      top: calc(0px - 70px);
      left: calc(50% - 110px);
    }
  }
}

.friendly-message_dismiss {
  color: $action-color;
  position: absolute;
  right: $padding-horizontal;
  top: 0;
  font-size: 20px;

  &:hover {
    color: $dark-action-color;
  }
}
