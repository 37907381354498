//Tables

table {
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
  width: 100%;

  &.monthly-leaderboard-table {
    table-layout: auto;

    .monthly-leaderboard-list-item {
      &:nth-of-type(n+26) {
        display: none;

        @media screen and (min-width: $screen-md-min) {
          display: table-row;
        }
      }

      .avatar {
        float: left;
        margin-right: 0.664rem;
      }
    }
  }
}

.table-layout--is-auto {
  table-layout: auto;
}

.table--padding-is-5 {
 
  td,
  th {
    padding: 5px;
  }
}

.table--padding-is-small {
 
  td,
  th {
    padding: 2px 4px;
  }
}

.table--padding-is-none {
 
  td,
  th {
    padding: 0;
  }
}

.table--border-is-none {

  td,
  th {
    border: none;
  }
}

.table--border--width-1 {
  border: 1px solid $base-border-color;

  &.border-radius--is-3 {
    border-collapse: separate;
    border-spacing: 0;

    tbody {

      tr {
        &:last-child {

          th,
          td {
            border: none;
          }
        }
      }
    }
  }
}

th {
  border-bottom: $base-border;
  font-weight: 600;
  padding: 5px 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: 5px 0;

  &.rank-column {
    width: 15%;
  }
}

tr,
td,
th {
  vertical-align: middle;
}
