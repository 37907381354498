//Mixins
@mixin wrapper() {
  margin-left: auto;
  margin-right: auto;
  max-width: $wrapper-width;
}

//Supersize
@mixin wrapper-ss() {
  @media screen and (min-width: $screen-ss-min) {
    margin-left: auto;
    margin-right: auto;
    max-width: $wrapper-ss-width;
  }
}
