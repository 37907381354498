//Footer List

.footer-list {
  line-height: 1.5em;
  margin-bottom: $padding-horizontal;

  @media screen and (min-width: $screen-sm-min) {
    line-height: 1em;
  }
}

.footer-list--links {
  margin-bottom: 40px;
}

.footer-list_item {
  display: inline-block;
  border-right: 1px solid rgba($base-font-color, .25);
  padding: 0 $padding-small;

  &:last-child {
    border-right: 0;
  }

  a {
    color: $base-font-color;
    margin-bottom: 40px;
    text-decoration: underline;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }

  @media screen and (max-width: $screen-sm-min) {

    &:first-child {
      padding-left: 0;
    }
  }

  .footer-list--links & {

    @media screen and (max-width: $screen-sm-min) {
      font-size: 14px;
      display: inline-block;
      width: 48%;
      border: none;
      padding: $padding-small 0;
    }
  }
}
