//Hero
$hero-cta-color: $action-color !default;
$hero-button-bkg-color: $action-color !default;
$hero-button-bkg-hover-color: $dark-action-color !default;

.hero {
  background-color: $brand-dark-primary-color;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  text-align: center;
  padding: $padding-medium 0;

  @media screen and (min-width: $medium-screen) {
    padding-top: $padding-xlarge;
    padding-bottom: $padding-xlarge;
  }
}

.hero--landing {
  background-image: url(../public/images/fans-cheering.jpg);
}

.hero--has-video {
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $medium-screen) {
    background-image: url(../public/images/fans-cheering.jpg);
  }

  .l-wrapper {
    position: relative;
    z-index: 1;
  }

  .link--is-underlined {
    color: $white;

    @media screen and (max-width: $medium-screen) {
      font-size: .75em;
    }

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

.hero--short {
  padding: $padding-medium;
}

.hero_video {

  position: absolute;
  top: 0;
  left: 0;
  opacity: .25;
  min-width: 100%;
  min-height: 100%;

  @media screen and (max-width: $medium-screen) {
    display: none;
  }
}

.hero_pre {
  font-size: 13px;
  margin-bottom: $padding-medium;

  @media screen and (min-width: $medium-screen) {
    font-size: 16px;
    opacity: .6;
  }

  @media screen and (min-width: $large-screen) {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.hero_heading {
  margin-bottom: $padding-small;
  text-transform: uppercase;

  @media screen and (min-width: $medium-screen) {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: $padding-medium;
  }

  @media screen and (min-width: $large-screen) {
    font-size: 70px;
    line-height: 60px;
  }

  em {
    font-style: normal;
  }
}

.hero_heading--medium {

  @media screen and (min-width: $medium-screen) {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: $padding-medium;
  }

  @media screen and (min-width: $large-screen) {
    font-size: 60px;
    line-height: 60px;
  }
}

.hero_heading_cta {
  color: $hero-cta-color;
  display: block;
  font-size: .75em;
  margin-bottom: $padding-horizontal;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;
  }
}

.hero_heading_strong {
  display: block;
}

.hero_sub {
  font-size: 12px;
  margin-bottom: $padding-medium;
  text-transform: uppercase;

  @media screen and (min-width: $medium-screen) {
    margin-bottom: $padding-large;    
    font-size: 18px;
  }

  @media screen and (min-width: $large-screen) {
    margin-bottom: $padding-xlarge;
    font-size: 24px;
    padding: 0 30px;
    position: relative;
    display: inline-block;

    &:before,
    &:after {
      font-family: 'derbicons';
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: block;
      content: '\e900';
      font-size: 14px;
      opacity: .6;
      position: absolute;
      left: 0;
      top: calc(50% - 9px);

      @media screen and (min-width: $medium-screen) {
        font-size: 20px;
        top: calc(50% - 14px);
      }
    }

    &:after {
      left: auto;
      right: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.hero_cta {
  margin-bottom: $padding-horizontal;

  @media screen and (min-width: $medium-screen) {
    margin-bottom: $padding-vertical;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .btn--primary {
    background-color: $hero-button-bkg-color;

    &:hover {
      background-color: $hero-button-bkg-hover-color
    }
  }
}
