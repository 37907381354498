//Variables

//Typography
$base-font-family: 'Roboto', sans-serif;
$fixed-font-family: 'Cambay', sans-serif;
$heading-font-weight: 700;

// Font Sizes
$base-font-size: 1em;
$base-font-weight: 300;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

//Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: calc($base-spacing / 2);

// Avatar Sizes
$avatar-tiny-width: 30px;
$avatar-small-width: 50px;
$avatar-medium-width: 100px;
$avatar-width: 150px;

// Padding Sizes
$padding-vertical: 20px;
$padding-horizontal: 10px;
$padding-row: 1px;
$padding-cell: 2px;
$padding-small: 5px;
$padding-small-medium: 15px;
$padding-medium: 20px;
$padding-large: 40px;
$padding-xlarge: 60px;

// Header Sizes
$header-height-mobile: 80px;
$header-height: 60px;

// Wrapper Sizes
$wrapper-width: 1080px;
$wrapper-ss-width: 1220px; //supersize

// Leaderboard Sizes
$leaderboard-top-pos: 120px;
$leaderboard-list-height: 400px;
$leaderboard-width: 300px;
$leaderboard-width-large: 340px;

// Game Board Sizes
$tickets-height: 120px;
$game-table-width: 738px;
$game-table-ss-width: 828px; //supersize

// Accordion Sizes
$accordion-width: 200px;
$accordion-mobile-nav-calc: 40px;
$accordion-mobile-nav-height: 50px;
$accordion-heading-height: 30px;

// Chat Sizes
$chat-avatar-width-medium: 40px;

// Other Sizes
$tab-width: 300px;
$saf-ios-bottom-height: 60px;
$notification-fixed-height: 60px;

// Z indexes
$base-z-index: 0;
$game-table-header-z-index: 20;
$product-header-z-index: 30;
$game-table-lightbox-z-index: 34;
$leaderboard-mobile-index: 34;
$notification-z-index: 39;
$accordion-z-index: 40;
$nag-notification-z-index: 42;
$loading-z-index: 45;
$accordion-mobile-nav-z-index: 50;
$leaderboard-z-index: 53;
$header-z-index: 55;
$nav-menu-z-index: 60;
$tooltip-z-index: 70;
$lightbox-z-index: 100;

// Colors
//Towel Colors
$red: #c93702; //1 towel color
$white: #fff; //2 towel color
$blue: #2335a1; //3 towel color
$yellow: #f9d336; //4 towel color
$green: #1ba74f; //5 towel color
$black: #000; //6 towel color
$orange: #f5551f; //7 towel color
$pink: #ff89e8; //8 towel color
$turquoise: #c6efe8; //9 towel color
$purple: #983cd8; //10 towel color
$gray: #7a7a7a; //11 towel color
$lime: #75ae21; //12 towel color
$brown: #d47739; //13 towel color
$maroon: #a54045; //14 towel color
$khaki: #ecd470; //15 towel color
$copen-blue: #4ab7e1; //16 towel color
$navy: #16226c; //17 towel color
$forest-green: #1e8348; //18 towel color
$moonstone: #b0b0b0; //19 towel color
$fuchsia: #f034e8; //20 towel color

//Site Colors
$dark-gray: #1e1e1e;
$transparent-gray: rgba($gray, .4);
$medium-gray: $gray;
$light-gray: $moonstone;
$lighter-gray: #f7f7f7;
$steel-gray: #233547;
$medium-steel-gray: darken($steel-gray, 15%);
$steel-gradient: linear-gradient(to bottom, $medium-steel-gray 0, $steel-gray 100%);
$blue-gradient: linear-gradient($navy, darken($blue, 5%), $navy);
$lighter-yellow: lighten($yellow, 37%);
$light-yellow: lighten($yellow, 30%);
$medium-yellow: darken($yellow, 10%);
$dark-yellow: darken($yellow, 30%);
$light-copen-blue: lighten($copen-blue, 35%);
$lighter-copen-blue: lighten($copen-blue, 39%);
$medium-copen-blue: lighten($copen-blue, 35%);
$ticket-color: $copen-blue;

//Border Colors
$base-border-color: #ddd;
$base-border: 1px solid $base-border-color;

//Background Colors
$base-background-color: $white;
$secondary-background-color: tint($base-border-color, 75%);

// Box Shadows
$component-box-shadow: 0 0 8px rgba($black, .25);

// Animations
$base-duration: 150ms;
$drawer-speed-duration: .3s;
$base-timing: ease;
$drawer-timing: ease-out;

//Breakpoints
$screen-xs-min: 480px;
// Small screen / tablet
$screen-sm-min: 768px;
// Medium screen / desktop
$screen-md-min: 992px;
// Large screen / wide desktop
$screen-lg-min: 1024px;
// Wall desktop
$screen-wall-min: 1280px;
// Supersize
$screen-ss-min: 1400px;
// So media queries don't overlap when required, provide a maximum
$screen-xs-max: (
  $screen-sm-min - 1) !default;
$screen-sm-max: (
  $screen-md-min - 1) !default;
$screen-md-max: (
  $screen-lg-min - 1) !default;
$screen-lg-max: (
  $screen-wall-min - 1) !default;
$screen-wall-max: (
  $screen-ss-min - 1) !default;

//Custom screen size
$screen-base-max: (
  $screen-xs-min - 1
);
$screen-desktop-min: (
  $screen-lg-min + 1
);
$screen-details-break: 1100px;
$screen-leaderboard-break: $screen-md-max;
$screen-tab-size-max: 600px;
$screen-iphone5-max: 350px;
$screen-filters-break: 1230px;
$screen-nav-mobile-max: 820px;
$screen-nav-desktop-min: (
  $screen-nav-mobile-max + 1
);
