//Avatar

$avatar-top: 26px !default;
$avatar-tiny-top: 3px !default;
$avatar-small-top: 8px !default;
$avatar-medium-top: 20px !default;
$avatar-letter-spacing: -.08em !default;

@mixin avatar-tiny() {
  font-size: 12px;
  letter-spacing: normal;
  background-size: 30%;
  width: $avatar-tiny-width;
  height: $avatar-tiny-width;

  &::after {
    top: calc(50% - #{$avatar-tiny-top});
  }
}

@mixin avatar-small() {
  background-size: 35%;
  font-size: 26px;
  width: $avatar-small-width;
  height: $avatar-small-width;

  &::after {
    top: calc(50% - #{$avatar-small-top});
  }
}

@mixin avatar-medium() {
  background-size: 45%;
  font-size: 54px;
  width: $avatar-medium-width;
  height: $avatar-medium-width;

  &::after {
    top: calc(50% - #{$avatar-medium-top});
  }
}

.avatar {
  background-color: $blue;
  background-image: url(../public/images/backgrounds/bkg-avatar-polka-dot.svg);
  background-size: 70%;
  border: 1px solid $base-font-color;
  color: $white;
  display: block;
  text-align: center;
  position: relative;
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  font-size: 80px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: $avatar-letter-spacing;
  width: $avatar-width;
  height: $avatar-width;

  .profile_picture & {

    @media screen and (max-width: $screen-base-max) {
      @include avatar-small();
    }

    @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
      @include avatar-medium();
    }
  }

  .side-tabs_picture & {

    @media screen and (max-width: $screen-base-max) {
      @include avatar-small();
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &::after {
    display: block;
    content: attr(aria-label);
    position: absolute;
    width: 98%;
    top: calc(50% - #{$avatar-top});
    left: 0;
  }
}

img.avatar {
  background-image: none;
  background-color: transparent;
  background-size: auto;
  max-width: none;

  &:after {
    display: none;
  }
}

.avatar--is-tiny {
  @include avatar-tiny();
}

.avatar--is-small {
  @include avatar-small();
}

.avatar--is-medium {
  @include avatar-medium();
}

.avatar--is-diamond {
  background-color: $blue;
  background-image: url(../public/images/backgrounds/bkg-avatar-diamond.svg);
  background-size: 50%;
}


.avatar--is-checkered {
  background-color: $blue;
  background-image: url(../public/images/backgrounds/bkg-avatar-checkered.svg);
}

.avatar--is-striped {
  background-color: $blue;
  background-image: url(../public/images/backgrounds/bkg-avatar-striped.svg);
}

.avatar--is-diagonal {
  background-color: $blue;
  background-image: url(../public/images/backgrounds/bkg-avatar-diagonal.svg);
}


@mixin avatar-factory($color-name, $color) {

  .avatar--is-#{$color-name} {
    background-color: $color;
  }
}

@include avatar-factory('red', $red);

@include avatar-factory('forest-green', $forest-green);

@include avatar-factory('khaki', $khaki);

@include avatar-factory('fuchsia', $fuchsia);

@include avatar-factory('purple', $purple);

@include avatar-factory('black', $black);

@include avatar-factory('brown', $brown);

@include avatar-factory('maroon', $maroon);

@include avatar-factory('green', $green);

@include avatar-factory('pink', $pink);

@include avatar-factory('yellow', $yellow);

@include avatar-factory('gray', $gray);
