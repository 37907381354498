//Helpers

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-small {
  font-size: 12px;
}

.text-tiny {
  font-size: 10px;
}

.text-medium {
  font-size: 14px;
  line-height: 1.25em;
}

.text-24--desktop {

  @media screen and (min-width: $screen-md-min) {
    font-size: 24px;
    line-height: 1.2em;
  }
}

.text-small--mobile {

   @media screen and (max-width: $screen-xs-max) {
    font-size: 14px;
   }
}

.text-small--tablet {

   @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    font-size: 14px;
   }
}

.text-large {
  font-size: 1.25em;
  line-height: 1.25em;

  @media screen and (min-width: $medium-screen) {
    font-size: 1.5em;
  }

  @media screen and (min-width: $large-screen) {
    font-size: 2em;
  }
}

.text-larger {
  font-size: 1.5em;

  @media screen and (min-width: $medium-screen) {
    font-size: 2em;
  }

  @media screen and (min-width: $large-screen) {
    font-size: 3em;
  }
}

.text--is-italic {
  font-style: italic;
}

.text--is-uppercase {
  text-transform: uppercase;
}

.text--is-bold {
  font-weight: bold;
}

.text--is-normal {
  font-weight: normal !important;
}

.text--is-base-weight {
  font-weight: $base-font-weight !important;
}

.font-family--is-heading {
  font-family: $heading-font-family;
}

.line-height--is-1 {
  line-height: 1em;
}

.line-height--is-small {
  line-height: 1.25em;
}

.color--is-dark-gray {
  color: $dark-gray;
}

.color--is-medium-gray {
  color: $medium-gray;
}

.color--is-light-gray {
  color: $light-gray;
}

.color--is-white {
  color: $white;
}

.color--is-navy {
  color: $navy;
}

.color--is-blue {
  color: $blue;
}

.color--is-red {
  color: $error-color;
}

.color--is-copen-blue {
  color: $copen-blue;
}

.color--is-lime {
  color: $success-color;
}

.color--is-dark-lime {
  color: $dark-success-color;
}

.color--is-yellow {
  color: $yellow;
}

.color--is-medium-yellow {
  color: $medium-yellow;
}

.color--is-dark-yellow {
  color: $dark-yellow;
}

.color--is-success {
  color: $success-color;
}

.color--is-caution {
  color: $caution-color;
}

.color--is-error {
  color: $error-color;
}

.color--is-action {
  color: $action-color;
}

.color--is-base-font-color {
  color: $base-font-color;
}

.opacity--is-25 {
  opacity: .25;
}

.opacity--is-50 {
  opacity: .5;
}

.opacity--is-75 {
  opacity: .75;
}

.content--is-gray {
  background-color: $lighter-gray;

  > * {
    opacity: .3;
  }
}

.note-text {
  font-size: 13px;
  line-height: 1.25em;

  h2 + & {
    margin-top: -10px;
    margin-bottom: $padding-vertical;
  }
}

.link--is-basic {

  &:hover {
    color: $white;
    text-decoration: none;
  }
}

.link--is-underlined {
  text-decoration: underline;

  &:focus {
    text-decoration: underline;
  }
}

.link--is-inline {
  display: inline-block;
  margin-right: $padding-horizontal;

  &:last-child {
    margin-right: 0;
  }
}

.link--is-white {
  color: $white;
  text-decoration: underline;

  &:hover {
    color: $white;
  }
}

.max-width--is-320 {
  max-width: 320px;
}

.margin--is-auto {
  margin-right: auto;
  margin-left: auto;
}

.margin-bottom--is-0 {
  margin-bottom: 0 !important;
}

.margin-bottom--is-2 {
  margin-bottom: 2px !important;
}

.margin-bottom--is-5 {
  margin-bottom: $padding-small !important;
}

.margin-bottom--is-10 {
  margin-bottom: $padding-horizontal !important;
}

.margin-bottom--is-15 {
  margin-bottom: $padding-small-medium !important;
}

.margin-bottom--is-20 {
  margin-bottom: $padding-vertical !important;
}

.margin-bottom--is-20-mobile {
  
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: $padding-vertical !important;
  }
}

.margin-bottom--is-30 {
  margin-bottom: 30px !important;
}

.margin-bottom--is-40 {
  margin-bottom: $padding-large !important;
}

.margin-top--is-0 {
  margin-top: 0 !important;
}

.margin-top--is-5 {
  margin-top: $padding-small !important;
}

.margin-top--is-neg-15 {
  margin-top: -$padding-small-medium !important;
}

.margin-top--is-10 {
  margin-top: $padding-horizontal !important;
}

.margin-bottom--is-10-mobile {
  
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: $padding-horizontal !important;
  }
}

.margin-top--is-15 {
  margin-top: $padding-small-medium !important;
}

.margin-top--is-20 {
  margin-top: $padding-vertical !important;
}

.margin-bottom--is-20-mobile {
  
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: $padding-vertical !important;
  }
}

.margin-top--is-30 {
  margin-top: 30px !important;
}

.margin-top--is-40 {
  margin-top: $padding-large !important;
}

.padding-right--is-0 {
  padding-right: 0 !important;
}

.padding--is-5 {
  padding: $padding-small !important;
}

.padding-right--is-5 {
  padding-right: $padding-small !important;
}

.padding-right--is-10 {
  padding-right: $padding-horizontal !important;
}

.padding-right--is-20 {
  padding-right: $padding-vertical !important;
}

.padding-right--is-10--tablet {

  @media screen and (min-width: $screen-sm-min) {
    padding-right: $padding-horizontal !important;
  }
}

.padding-left--is-0 {
  padding-left: 0 !important;
}

.padding-left--is-5 {
  padding-left: $padding-small !important;
}

.padding-left--is-5-mobile {

  @media screen and (max-width: $screen-xs-max) {
    padding-left: $padding-small !important;
  }
}

.padding-left--is-10 {
  padding-left: $padding-horizontal !important;
}

.padding-top--is-0 {
  padding-top: 0 !important;
}

.padding-right--is-20 {
  padding-right: $padding-vertical !important;
}

.padding-top--is-5 {
  padding-top: $padding-small !important;
}

.padding-top--is-10 {
  padding-top: $padding-horizontal !important;
}

.padding-left--is-20 {
  padding-left: $padding-vertical !important;
}

.padding-top--is-10-mobile {
  
  @media screen and (max-width: $screen-xs-max) {
    padding-top: $padding-horizontal !important;
  }
}

.padding-top--is-20 {
  padding-top: $padding-vertical !important;
}

.padding-top--is-40 {
  padding-top: $padding-large !important;
}

.padding-bottom--is-0 {
  padding-bottom: 0 !important;
}

.padding-bottom--is-5 {
  padding-bottom: $padding-small !important;
}

.padding-bottom--is-10 {
  padding-bottom: $padding-horizontal !important;
}

.padding-bottom--is-10-mobile {
  
  @media screen and (max-width: $screen-xs-max) {
    padding-bottom: $padding-horizontal !important;
  }
}

.padding-bottom--is-20 {
  padding-bottom: $padding-vertical !important;
}

.padding-bottom--is-20-mobile {
  
  @media screen and (max-width: $screen-xs-max) {
    padding-bottom: $padding-vertical !important;
  }
}

.padding-bottom--is-40 {
  padding-bottom: $padding-large !important;
}

.display--is-none {
  display: none !important;
}

.display--is-block {
  display: block;
}

.display--is-inline-block {
  display: inline-block;
}

.display--is-block-mobile {

  @media screen and (max-width: $screen-xs-max) {
    padding-top: $padding-small;
    display: block;
   }
}

.display--fade-in {
  display: block;
  animation: appear .5s linear forwards;
}

.vertical-align--is-middle {
  vertical-align: middle !important;
}

.vertical-align--is-top {
  vertical-align: top !important;
}

.vertical-align--is-bottom {
  vertical-align: bottom !important;
}

.border--width-1 {
  border: 1px solid $base-border-color;
}

.border-top--width-1 {
  border-top: 1px solid $base-border-color;
}

.border-right--width-1 {
  border-right: 1px solid $base-border-color;
}

.border-right--width-1-desktop {

  @media screen and (min-width: $screen-md-min) {
    border-right: 1px solid $base-border-color;
  }
}

.border-bottom--width-1 {
  border-bottom: 1px solid $base-border-color;
}

.border-left--width-1 {
  border-left: 1px solid $base-border-color;
}

.border-top--is-none {
  border-top: none;
}

.border-radius--is-3 {
  border-radius: $base-border-radius;
}

.border-color--white-25 {
  border-color: rgba($white, .25);
}

.width--is-auto {
  width: auto;
}

.animation--is-waving {
  display: inline-block;
  position: relative;
  left: 5px;
  transform-origin: 5px 25px;
  animation: waving .9s linear 6 forwards;
}

.hidden--base {

   @media screen and (max-width: $screen-base-max) {
    display: none !important;
   }
}

.hidden--mobile {

   @media screen and (max-width: $screen-xs-max) {
    display: none !important;
   }
}

.visible--mobile {

   @media screen and (min-width: $screen-xs-max) {
    display: none !important;
   }
}

.hidden--tablet {

  @media screen and (min-width: $screen-sm-min) {
    display: none !important;
  }
}

.hidden--desktop {

  @media screen and (min-width: $screen-md-min) {
    display: none !important;
  }
}

.visible--desktop {

  @media screen and (max-width: $screen-sm-max) {
    display: none !important;
  }
}

.hidden--lg-min {

  @media screen and (min-width: $screen-lg-min) {
    display: none !important;
  }
}

.visible--lg-min {

  @media screen and (max-width: $screen-lg-min) {
    display: none !important;
  }
}

.visible--only-base {
  @media screen and (min-width: $screen-xs-min) {
    display: none !important;
  }
}

.visible--only-xs {
  @media screen and (max-width: $screen-base-max) {
    display: none !important;
  }
  @media screen and (min-width: $screen-sm-min) {
    display: none !important;
  }
}

.visible--only-sm {
  @media screen and (max-width: $screen-xs-max) {
    display: none !important;
  }
  @media screen and (min-width: $screen-md-min) {
    display: none !important;
  }
}

.visible--only-md {
  @media screen and (max-width: $screen-sm-max) {
    display: none !important;
  }
  @media screen and (min-width: $screen-lg-min) {
    display: none !important;
  }
}

.visible--only-lg {
  @media screen and (max-width: $screen-md-max) {
    display: none !important;
  }
  @media screen and (min-width: $screen-wall-min) {
    display: none !important;
  }
}

.visible--only-wall {
  @media screen and (max-width: $screen-lg-max) {
    display: none !important;
  }
  @media screen and (min-width: $screen-ss-min) {
    display: none !important;
  }
}

.visible--only-ss {
  @media screen and (max-width: $screen-wall-max) {
    display: none !important;
  }
}
