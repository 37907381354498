//Tickets

.tickets_nav {
  color: $action-color;
  display: block;
  font-size: 16px;
  margin-top: 15px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $dark-action-color;
  }
}

.tickets_nav--is-disabled {
  color: $transparent-gray;

  &:hover,
  &:focus {
    color: $transparent-gray;
    cursor: default;
    text-decoration: none;
  }
}

.tickets_link {
  border: 1px solid transparent;
  line-height: 1em;
  display: inline-block;
  padding: $padding-horizontal $padding-small $padding-small;

  &:hover,
  &:focus {
    border-color: $action-color;
    background-color: $light-action-color;
    text-decoration: none;
  }
}

.tickets_link--is-active {
  border-color: $blue;
  background-color: $light-copen-blue;

  &:hover,
  &:focus {
    border-color: $blue;
    background-color: $light-copen-blue;
    text-decoration: none;
  }
}

.tickets_item {
  color: $white;
  display: block;
  font-family: $fixed-font-family;
  font-size: 14px;
  line-height: 1em;
  height: 35px;
  margin: $padding-horizontal auto 0;
  position: relative;
  text-align: left;
  width: 50px;
  z-index: 0;

  &:before {
    color: $ticket-color;
    content: '\e931';
    font-family: 'derbicons';
    font-size: 40px;
    left: 0;
    line-height: 1em;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
  }
}

.tickets_item--is-large {
  margin-top: $padding-horizontal;

  @media screen and (min-width: $screen-sm-min) {
    height: 53px;
    width: 73px;

    &:before {
      font-size: 60px;
      top: -3px;
    }
  }
}

@mixin ticket-variant($color) {
  &:before {
    color: $color;
  }
}

.tickets_item--is-eliminated {
  @include ticket-variant($error-color);
}

.tickets_item--is-winner {
  @include ticket-variant($success-color);
}

.tickets_item--is-loser {
  @include ticket-variant($gray);
}

.tickets_number {
  position: absolute;
  top: 15px;
  left: 2px;
  width: 25px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;

  .tickets_item--is-large & {
    @media screen and (min-width: $screen-sm-min) {
      font-size: 30px;
      top: 24px;
      left: 8px;
      width: 25px;
    }
  }
}

.tickets_rank {
  font-family: $base-font-family;
  font-size: 9px;
  font-weight: 300;
  line-height: .9em;
  position: absolute;
  width: 36px;
  top: 12px;
  left: 24px;
  border-left: 1px dotted rgba($white, 0.5);
  padding-left: 2px;

  span {
    display: block;

    &:first-child {
      font-size: 6px;
      opacity: .85;

      .tickets_item--is-large & {
        @media screen and (min-width: $screen-sm-min) {
          font-size: 10px;
        }
      }
    }
  }

  .tickets_item--is-large & {
    @media screen and (min-width: $screen-sm-min) {
      font-size: 14px;
      top: 15px;
      left: 35px;
      width: 25px;
    }
  }
}

.tickets_score {
  color: $gray;

  .game-page &:not(.tickets_score--points) {
    color: rgba($white, 0.75);
  }
}

.tickets_score--points {
  color: $ticket-color;
  font-weight: 700;
}

.tickets_score--is-eliminated {
  color: $red;
}

.tickets_score--is-winner {
  color: $lime;
}

.tickets_prize {
  color: $lime;
  display: block;
  font-size: 13px;
  position: absolute;
  text-align: center;
  top: -6px;
  width: 100%;

  [class^="dw-"] {
    font-size: .75em;
  }

  .tickets_item--is-large & {
    @media screen and (min-width: $screen-sm-min) {
      font-size: 18px;
    }
  }
}
