//Animations

@keyframes slidedown {
  0% {
    max-height: 0;
    overflow: hidden;
  }

  99% {
    max-height: 400px;
  }

  100% {
    max-height: none;
    overflow: visible;
  }
}

@keyframes slideup {
  from {
    max-height: 400px;
  }

  to {
    max-height: 0;
  }
}

@keyframes slidedowntall {
  0% {
    max-height: 0;
    overflow: hidden;
  }

  99% {
    max-height: 1000px;
  }

  100% {
    max-height: none;
    overflow: visible;
  }
}

@keyframes spin {
  from {
    transform: scale(1.5, 1.5) rotate(0deg);
  }

  to {
    transform: scale(1.5, 1.5) rotate(360deg);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  60% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes loadingpulsate {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(4, 4);
  }

  60% {
    transform: scale(4, 4);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes disappearup {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    top: -10px;
  }
}

@keyframes appearup {
  0% {
    opacity: 0;
    top: 10px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes appearupfrombottom {
  0% {
    opacity: 0;
    bottom: -40px;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes appeardown {
  0% {
    opacity: 0;
    transform: rotateX(90deg);
  }

  2% {
    opacity: 1;
    transform: rotateX(0deg);
  }

  10% {
    opacity: 1;
    transform: rotateX(0deg);
  }

  12% {
    opacity: 0;
    transform: rotateX(90deg);
  }

  100% {
    opacity: 0;
    transform: rotateX(90deg);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appearscaleup {
  0% {
    opacity: 0;
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.75, 1.75);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes animatedown {
  0% {
    top: -30px;
    background-color: $copen-blue;
  }

  100% {
    top: 0;
    background-color: $lighter-gray;
  }
}

@keyframes animateup {
  0% {
    top: 30px;
    z-index: 20;
    background-color: $copen-blue;
  }

  100% {
    top: 0;
    z-index: 20;
    background-color: $lighter-gray;
  }
}

@keyframes pulsecountdowncaution {
  0% {
    border-color: $caution-color;
    color: $black;
    box-shadow: 0 0 0 $bkg-caution-color;
  }

  70% {
    border-color: $caution-color;
    color: $black;
    box-shadow: 0 0 0 $bkg-caution-color;
  }

  75% {
    border-color: $white;
    color: $caution-color;
    box-shadow: 0 0 10px $bkg-caution-color;
  }

  100% {
    border-color: $caution-color;
    color: $black;
    box-shadow: 0 0 0 $bkg-caution-color;
  }
}

@keyframes pulsecountdownwarning {
  0% {
    border-color: $dark-error-color;
    color: $white;
    box-shadow: 0 0 0 $error-color;
  }

  70% {
    border-color: $dark-error-color;
    color: $white;
    box-shadow: 0 0 0 $error-color;
  }

  75% {
    border-color: $white;
    color: $light-error-color;
    box-shadow: 0 0 10px $error-color;
  }

  100% {
    border-color: $dark-error-color;
    color: $white;
    box-shadow: 0 0 0 $error-color;
  }
}

@keyframes bounce {
  0% {
    padding-bottom: $padding-small;
    bottom: 0;
  }

  5% {
    padding-bottom: 30px;
  }

  10% {
    padding-bottom: $padding-small;
  }

  15% {
    padding-bottom: 15px;
  }

  20% {
    padding-bottom: $padding-small;
  }

  100% {
    bottom: 0;
  }
}

@keyframes caution {
  0% {
    background-color: $light-yellow;
  }

  5% {
    background-color: $light-yellow;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes highlight {
  0% {
    background-color: $light-copen-blue;
  }

  5% {
    background-color: $light-copen-blue;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes highdark {
  0% {
    background-color: $blue;
    color: $white;
  }

  5% {
    background-color: $blue;
    color: $white;
  }

  100% {
    background-color: $medium-copen-blue;
  }
}

@keyframes showtooltipcaret {
  0% {
    bottom: 115%;
    opacity: 0;
  }

  100% {
    bottom: calc(100%  + 2px);
    opacity: 1;
  }
}

@keyframes showtooltip {
  0% {
    bottom: 115%;
    opacity: 0;
  }

  100% {
    bottom: calc(100%  + 7px);
    opacity: 1;
  }
}

@keyframes showpopup {
  0% {
    transform: scale(.1, .1);
    // bottom: calc(100% - 80px);
    bottom: 90%;
    opacity: 0;
  }

  80% {
    transform: scale(1.3, 1.3);
    // bottom: calc(100% + 25px);
    bottom: 120%;
  }

  100% {
    transform: scale(1, 1);
    // bottom: calc(100% + 10px);
    bottom: 105%;
    opacity: 1;
  }
}

@keyframes showtooltipcaretright {
  0% {
    left: 85%;
    opacity: 0;
  }

  100% {
    left: 100%;
    opacity: 1;
  }
}

@keyframes showtooltipright {
  0% {
    left: 85%;
    opacity: 0;
  }

  100% {
    left: calc(100%  + 6px);
    opacity: 1;
  }
}

@keyframes showtooltiprightie {//target IE10+
  0% {
    left: 85%;
    opacity: 0;
  }

  100% {
    left: 100%;
    opacity: 1;
  }
}

@keyframes showtooltipcaretleft {
  0% {
    left: 0px;
    opacity: 0;
  }

  100% {
    left: -8px;
    opacity: 1;
  }
}

@keyframes showtooltipleft {
  0% {
    left: -122px;
    opacity: 0;
  }

  100% {
    left: -128px;
    opacity: 1;
  }
}

@keyframes flyup {
  0% {
    top: -20px;
    left: 0;
    opacity: 0;
    transform: rotate(0deg);
  }

  10% {
    opacity: 1;
  }

  90% {
    top: -70px;
    left: -10px;;
    opacity: 0;
    transform: rotate(-180deg);
  }

  100% {
    opacity: 0;
  }
}

@keyframes flyupleft {
  0% {
    top: -20px;
    left: 0;
    opacity: 0;
    transform: rotate(0deg);
  }

  10% {
    opacity: 1;
  }

  90% {
    top: -40px;
    left: -40px;;
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    opacity: 0;
  }
}

@keyframes flyupright {
  0% {
    top: -20px;
    right: 0;
    opacity: 0;
    transform: rotate(0deg);
  }

  10% {
    opacity: 1;
  }

  90% {
    top: -40px;
    right: -20px;;
    opacity: 0;
    transform: rotate(90deg);
  }

  100% {
    opacity: 0;
  }
}

@keyframes waving {
  0% {
    transform: rotate(0deg) scale(.75, 1);
  }

  33% {
    transform: rotate(-25deg) scale(-1.05, 1);
  }

  66% {
    transform: rotate(20deg) scale(.05, 1);
  }

  100% {
    transform: rotate(0deg) scale(.75, 1);
  }
}

@keyframes loadingbtnbkg {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -44px 0;
  }
}

@keyframes loadingpulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadethrulime {
  0% {
    color: $success-color;
    opacity: 0;
    transform: scale(1, 1);
  }

  15% {
    opacity: 1;
    transform: scale(1.5, 1.5);
  }

  20% {
    transform: scale(1, 1);
  }

  100% {
    color: $dark-gray;
  }
}

@keyframes fadethrulimetowhite {
  0% {
    color: $success-color;
    opacity: 0;
    transform: scale(1, 1);
  }

  15% {
    opacity: 1;
    transform: scale(1.5, 1.5);
  }

  20% {
    transform: scale(1, 1);
  }

  100% {
    color: $base-font-color;
  }
}

@keyframes fadeborderthrulime {
  0% {
    background-color: $light-success-color;
    border-color: $success-color;
    outline-size: 4px;
  }

  100% {
    background-color: transparent;
    border-color: $light-gray;
    outline-size: 1px;
  }
}

@keyframes fadebkgthrulime {
  0% {
    background-color: $light-success-color;
  }

  80% {
    background-color: $light-success-color;
  }

  100% {
    background-color: rgba($copen-blue, .2);
  }
}

@keyframes flipgrow {
  0% {
    transform: rotate3d(1, 0, 0, 0deg) scale(1, 1);
  }

  20% {
    transform: rotate3d(1, 0, 0, -20deg);
  }

  100% {
    transform: rotate3d(1, 0, 0, 0deg) scale(1.05, 1.05);
  }
}

@keyframes pulsecopenblue {
  0% {
    background-color: $copen-blue;
  }

  10% {
    background-color: $brand-primary-color;
  }

  15% {
    background-color: $copen-blue;
  }

  20% {
    background-color: $brand-primary-color;
  }

  100% {
    background-color: $brand-primary-color;
  }
}

@keyframes pulsecautioncolor {
  0% {
    background-color: $bkg-caution-color;
  }

  10% {
    background-color: $brand-primary-color;
  }

  15% {
    background-color: $bkg-caution-color;
  }

  20% {
    background-color: $brand-primary-color;
  }

  100% {
    background-color: $brand-primary-color;
  }
}

@keyframes pulseerrorcolor {
  0% {
    background-color: $error-color;
  }

  10% {
    background-color: $brand-primary-color;
  }

  15% {
    background-color: $error-color;
  }

  20% {
    background-color: $brand-primary-color;
  }

  100% {
    background-color: $brand-primary-color;
  }
}

@keyframes btnpulsecopenblue {
  0% {
    border-color: $white;
    background-color: $copen-blue;
  }

  10% {
    background-color: $white;
  }

  15% {
    border-color: $white;
    background-color: $copen-blue;
  }

  20% {
    background-color: $white;
  }

  100% {
    background-color: $white;
  }
}

@keyframes btnpulsecautioncolor {
  0% {
    border-color: $white;
    background-color: $bkg-caution-color;
  }

  10% {
    background-color: $white;
  }

  15% {
    border-color: $white;
    background-color: $bkg-caution-color;
  }

  20% {
    background-color: $white;
  }

  100% {
    background-color: $white;
  }
}

@keyframes btnpulseerrorcolor {
  0% {
    border-color: $white;
    background-color: $error-color;
    color: $white;
  }

  10% {
    background-color: $white;
    color: $action-color;
  }

  15% {
    border-color: $white;
    background-color: $error-color;
    color: $white;
  }

  20% {
    background-color: $white;
    color: $action-color;
  }

  100% {
    background-color: $white;
    color: $action-color;
  }
}
