//Summary

.summary {
  display: table;
  margin: 0;
  width: 100%;

  // @media screen and (min-width: $screen-sm-min) {
  //   margin-bottom: $padding-xlarge;
  // }
}

.summary_row {
  display: table-row;

  dt {
    margin: 0;
    display: table-cell;
    vertical-align: middle;
    border-top: 1px solid $base-font-color;
    padding: $padding-small 0;
    font-weight: 100;
    font-size: 14px;

    .lightbox & {
      border-top-color: $light-gray;
    }
  }

  dd {
    margin: 0;
    display: table-cell;
    vertical-align: middle;
    border-top: 1px solid $base-font-color;
    padding: $padding-small 0;
    font-weight: 700;
    font-size: 18px;
    text-align: right;

    .lightbox & {
      border-top-color: $light-gray;
    }
  }
}

.summary_row--bonus {
  color: $lime;

  dt {
    font-weight: 400;
  }
}

.summary_row--total {

  dt {
    border-top-width: 3px;
  }

  dd {
    border-top-width: 3px;
    font-size: 24px;
  }
}
