//Device

.device {
  background: $light-gray;
  box-shadow: 0 0 15px rgba($black, .5);
  border-radius: $padding-small-medium;
  border: 1px solid $gray;
  margin: 0 auto;
  overflow: hidden;

  img {
    display: block;
  }
}

.device--desktop {
  max-width: 960px;
}

.device--mobile {
  border-left-width: 8px;
  border-right-width: 8px;
  border-color: $black;
  border-radius: $padding-medium;
  background-color: $black;
  max-width: 300px;
}

.device_controls {
  border-bottom: 1px solid $gray;
  padding: $padding-small $padding-small $padding-small $padding-horizontal;
  text-align: left;

  li {
    background-color: $lighter-gray;
    border-radius: $padding-horizontal;
    border: 1px solid $lighter-gray;
    height: $padding-horizontal;
    width: $padding-horizontal;
    margin: 0 5px;
    display: inline-block;
  }
}

.device_controls--speaker {
  border-bottom: 1px solid $dark-gray;
  text-align: center;
  padding: $padding-small-medium $padding-small;

  li {
    border-color: transparent;
    background-color: $transparent-gray;
    width: 55px;
  }
}

.device_controls--home {
  border-bottom: 1px solid $dark-gray;
  border-top: 1px solid $dark-gray;
  text-align: center;
  padding: $padding-small $padding-small 0;

  li {
    border-color: transparent;
    background-color: $transparent-gray;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
}
