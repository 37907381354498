//Layout
$base-header-bkg-gradient: $base-header-bkg-color !default;

.l-container {
  // background-color: $white;
}

.l-header_placeholder {
  height: $header-height-mobile;

  @media screen and (min-width: $screen-nav-desktop-min) {
    height: $header-height;
  }
}

.l-header {
  background: $base-header-bkg-gradient;
  position: fixed;
  top: 0;
  transition: background-color .3s linear;
  width: 100%;
  z-index: $header-z-index;
  min-height: $header-height-mobile;

  .body--lightbox-is-open & {
    z-index: $leaderboard-z-index - 1;
  }

  @media screen and (min-width: $screen-nav-desktop-min) {
    min-height: $header-height;
  }

  // .viewport--is-scrolled & {

  //   @media screen and (min-width: $screen-nav-desktop-min) {
  //     min-height: $header-height-short;
  //   }
  // }
}

.l-wrapper {
  @include wrapper();
}

.l-wrapper--supersize {
  @include wrapper-ss();
}

.l-content {
  padding: $padding-horizontal;

  @media screen and (min-width: $screen-sm-min) {
    padding-top: $padding-vertical;
    padding-bottom: $padding-vertical;
  }
}

.l-content--80-percent {

  @media screen and (min-width: $screen-md-min) {
    margin: 0 auto;
    max-width: 80%;
  }
}

.l-content--no-v-padding {
  padding-bottom: 0;
  padding-top: 0;
}

.l-row {
  padding: $padding-horizontal 0;

  @media screen and (min-width: $screen-sm-min) {
    padding: $padding-vertical 0;
  }

  &.l-row--no-padding {
    padding: 0;
  }
}

.l-row--bottom-border {
  border-bottom: $base-border;
}

.l-row--top-border {
  border-top: $base-border;
}

.l-row--gray {
  background-color: $gray;
}

.l-row--lighter-gray {
  background-color: $lighter-gray;
  color: $dark-gray;
}

.l-row--white {
  background-color: $white;
  color: $dark-gray;
}

.l-row--light-gray {
  background-color: $light-gray;
  color: $dark-gray;
}

.l-row--dark-gray {
  background-color: $dark-gray;
  color: $white;

  .link--is-underlined {
    color: $white;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

.l-row--blue {
  background-color: $blue;
  color: $white;

  .link--is-underlined {
    color: $white;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

.l-row--navy {
  background-color: $navy;
  color: $white;

  .link--is-underlined {
    color: $white;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

.l-row--orange {
  background-color: $orange;
  color: $white;

  .link--is-underlined {
    color: $white;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

.l-row--blue-gradient {
  background: $blue-gradient;
  color: $white;

  .link--is-underlined {
    color: $white;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

.l-row--steel-gradient {
  background: $steel-gradient;
  color: $white;

  .link--is-underlined {
    color: $white;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

.l-row--brand-color {
  background: $brand-row-bkg-color;
  color: $brand-row-font-color;

  .link--is-underlined {
    color: $brand-row-font-color;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

.l-footer {
  color: rgba($base-font-color, .4);
  font-size: 12px;
  padding-bottom: $padding-vertical;
  padding-top: $padding-vertical;
}

.l-footer_image {
  display: block;
  width: 100px;
}

.l-main {

  .accordion + & {

    @media screen and (min-width: $screen-lg-min) {
      padding-right: ($accordion-width + 10);
    }
  }
}
