//Chat

.chat {
  color: $dark-gray;
  font-size: 14px;
  line-height: 1.25em;
  padding-bottom: 44px;
  height: 100%;
  position: relative;

  @media screen and (min-width: $screen-lg-min) {
    font-size: 11px;
  }
}

.chat_list {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: $lighter-gray;
  height: 100%;
}

.chat_item {
  background-color: $lighter-gray;
  overflow: hidden;
  border-bottom: 1px solid $light-gray;
  padding: $padding-small;

  &:last-child {
    padding-bottom: $padding-horizontal;
  }
}

.chat_avatar {
  float: left;
  margin-right: $padding-small;
  display: block;

  .avatar {
    border-color: $light-gray;
  }

  .avatar--is-tiny {

    @media screen and (max-width: $screen-md-max) {
      font-size: 16px;
      height: $chat-avatar-width-medium;
      width: $chat-avatar-width-medium;

      &::after {
        top: calc(50% - 5px);
      }
    }
  }
}

.chat_message {
  overflow: hidden;
}

.chat_name {
  font-size: 12px;
  font-weight: $heading-font-weight;
}

.chat_meta {
  color: $gray;
  padding: 0 $padding-small;
  font-size: 11px;
  font-weight: 300;
}

.chat_body {

}

.chat_form {
  border-top: 1px solid $light-gray;
  background-color: $white;
  box-shadow: 0 0 8px rgba($black, .35);
  position: absolute;
  bottom: 4px;
  width: 100%;
  padding: $padding-small;
}
