//Loading

.loading {
  display: inline-block;
  padding: 0 9px;
  cursor: wait;
  height: 20px;
  width: auto;
  opacity: .5;
}

.loading--fullscreen {
  position: fixed;
  padding: 0;
  z-index: $loading-z-index;
  background-color: $base-body-bkg-color;
  color: $base-font-color;
  font-weight: 400;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
}

.loading--inline {
  width: auto;
  height: auto;
  display: block;
  padding-top: $padding-vertical;
  padding-bottom: $padding-vertical;
  color: $base-font-color;
  font-weight: 400;
  text-align: center;
}

.loading--is-dark {
  color: $dark-gray;
}

.loading_ellipsis {
  display: inline-block;
  margin-left: 2px;
  padding-bottom: 5px;
  vertical-align: bottom;

  li {
    display: inline-block;
    margin: 0 4px;
    animation: loadingpulsate 1500ms linear infinite;
    height: 2px;
    width: 2px;
    border-radius: 50%;
    background-color: $base-font-color;

    .loading--is-dark & {
      background-color: $dark-gray;
    }

    &:nth-child(2) {
      animation-delay: 500ms;
    }

    &:nth-child(3) {
      animation-delay: 1000ms;
    }
  }
}

.loading_content {
  flex: 0 0 auto;
  width: 100%;
}

.loading_heading {
  font-size: 24px;

  .loading--inline & {
    font-size: 18px;
  }
}

.loading_gif {
  width: 100%;
  max-width: 150px;
  opacity: .75;
  display: inline-block;
}
