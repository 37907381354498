//Tooltip

.tooltip {
  position: relative;

  &::before {
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
    border-top: 0 solid $dark-gray;
    content: '';
    display: block;
    left: calc(50% - 7px);
    opacity: 0;
    position: absolute;
    z-index: ($tooltip-z-index + 1);
  }

  &::after {
    background-color: $dark-gray;
    border-radius: $base-border-radius;
    bottom: 100%;
    color: $white;
    content: attr(data-tooltip);
    display: block;
    height: 0;
    overflow: hidden;
    pointer-events: none;
    font-family: $base-font-family;
    font-size: 12px;
    font-weight: 400;
    -webkit-font-smoothing: auto; //reset when used on buttons
    left: calc(50% - 61px);
    line-height: 1.2em;
    opacity: 0;
    position: absolute;
    text-align: center;
    width: 120px;
    white-space: normal;
    z-index: $tooltip-z-index;
    box-shadow: $component-box-shadow;
  }

  @media screen and (min-width: $screen-desktop-min) {

    &:hover {

      &::before {
        border-left-width: 6px;
        border-right-width: 6px;
        border-top-width: 6px;
        animation: showtooltipcaret .2s ease-out 1.5s forwards;
      }

      &::after {
        height: auto;
        padding: 6px;
        animation: showtooltip .2s ease-out 1.5s forwards;
      }
    }
  }
}

.tooltip--is-fast {

  @media screen and (min-width: $screen-sm-min) {

    &:hover {

      &:before,
      &:after {
        animation-delay: .5s;
      }
    }
  }
}

.tooltip--immediate,
.tooltip--is-immediate {

  @media screen and (min-width: $screen-sm-min) {

    &:hover {

      &:before,
      &:after {
        animation-delay: initial;
      }
    }
  }
}

.tooltip--white,
.tournament-table--game-page-heading .tooltip {

  &::before {
    border-top-color: $white;
  }

  &::after {
    background-color: $white;
    color: $dark-gray;
  }
}

.tooltip--is-right {

  &::before {
    border-left: 0 solid transparent;
    border-right: 0 solid $dark-gray;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    top: calc(50% - 6px);
  }

  &.tooltip--white {

    &::before {
      border-right-color: $white;
    }
  }

  &::after {
    top: 0;
    bottom: auto;
  }

  @media screen and (min-width: $screen-desktop-min) {

    &:hover {

      &::before {
        border-left-width: 0;
        border-right-width: 6px;
        border-top-width: 6px;
        border-bottom-width: 6px;
        animation-name: showtooltipcaretright;
      }

      &::after {
        animation-name: showtooltipright;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {//target IE10+

    &:hover {

      &::after {
        animation-name: showtooltiprightie !important;
      }
    }
  }

  @supports (-ms-ime-align:auto) {//target Edge

    &:hover {

      &::after {
        animation-name: showtooltiprightie !important;
      }
    }
  }

  &.tooltip--is-last {

    &::after {
      top: auto;
      bottom: 0;
    }
  }
}

.tooltip--is-left {

  &::before {
    border-left: 0 solid $dark-gray;
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    top: calc(50% - 6px);
    left: 0;
  }

  &.tooltip--white {

    &::before {
      border-left-color: $white;
    }
  }

  &::after {
    top: 0;
    left: 0;
    bottom: auto;
  }

  @media screen and (min-width: $screen-desktop-min) {

    &:hover {

      &::before {
        border-left-width: 6px;
        border-right-width: 0;
        border-top-width: 6px;
        border-bottom-width: 6px;
        animation-name: showtooltipcaretleft;
      }

      &::after {
        animation-name: showtooltipleft;
      }
    }
  }

  &.tooltip--is-last {

    &::after {
      top: auto;
      bottom: 0;
    }
  }
}
