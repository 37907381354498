//Side Tabs

.side-tabs {

  @media screen and (min-width: $screen-lg-min) {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
  }

  input[type="file"] {
    font-size: 12px;
  }
}

.side-tabs_cell {

  @media screen and (min-width: $screen-lg-min) {
    box-sizing: border-box;
    flex: 0 0 auto;
  }
}

.side-tabs_cell--tree {
  border-bottom: 1px solid $transparent-gray;
  position: relative;
  min-height: 42px;


  @media screen and (min-width: $screen-lg-min) {
    border-bottom: none;
    border-right: 1px solid $transparent-gray;
    width: 25%;
    position: static;
    min-height: 0;
  }
}

.side-tabs_cell--pane {
  padding-top: $padding-horizontal;

  @media screen and (min-width: $screen-lg-min) {
    padding-top: $padding-vertical;
    border-top: 1px solid $transparent-gray;
    padding-left: $padding-vertical;
    width: 75%;
  }
}

.side-tabs_links {
  border: 1px solid $transparent-gray;
  border-bottom-width: 0;

  @media (min-width: $screen-tab-size-max) and (max-width: $screen-md-max) {
    max-width: $tab-width;
  }

  @media screen and (min-width: $screen-lg-min) {
    width: 100%;
    border-right: 0;
  }
}

.side-tabs_link-item {
  
  @media screen and (max-width: $screen-md-max) {

    &:last-child {

      .side-tabs_link {
        border-bottom: 0;
      }
    }
  }
}

.side-tabs_links--is-open {
  
  @media screen and (max-width: $screen-md-max) {
    background-color: $tab-bkg-color;
    position: absolute;
    z-index: 1;
    box-shadow: 0 2px 10px rgba($black, .8);
    width: 100%;
    border-bottom-width: 1px;
  }
}

.side-tabs_link {
  color: $base-font-color;
  display: block;
  width: 100%;
  padding: $padding-small;
  text-decoration: none;
  border-bottom: 1px solid $transparent-gray;
  cursor: pointer;

  @media screen and (max-width: $screen-md-max) {
    padding: 8px 35px 7px $padding-small;
    display: none;
    color: $white;

    .side-tabs_links--is-open &:not(.side-tabs_link--is-active) {
      display: block;
      animation: appear $base-duration linear forwards;
    }
  }

  &:hover {
    text-decoration: none;
    background-color: $action-color;
    color: $white;

    [class^="dw-"] {
      color: $white;
    }

    .badge {
      border-color: rgba($white, .5);
      color: $white;
    }
  }

  [class^="dw-"] {
    opacity: .5;
    color: $base-font-color;

    @media screen and (max-width: $screen-md-max) {
      color: $white;
    }
  }

  .side-tabs_links--has-badges & {
    padding-right: 45px;
    position: relative;

    @media screen and (max-width: $screen-md-max) {
      padding-right: 73px;
    }
  }

  .badge {
    position: absolute;
    right: $padding-small;
    top: 9px;
    border-color: rgba($base-font-color, .5);
    color: $base-font-color;

    @media screen and (max-width: $screen-md-max) {
      right: 38px;
      top: 12px;
      border-color: rgba($white, .5);
      color: $white;
    }
  }
}

.side-tabs_link--is-active {
  background-color: $brand-darker-primary-color;
  color: $white;
  display: none;

  @media screen and (min-width: $screen-lg-min) {
    display: block;
  }

  &:hover {
    background-color: $brand-darker-primary-color;

    &:focus {
      background-color: $brand-darker-primary-color;
    }
  }

  [class^="dw-"] {
    color: $white;
  }

  .badge {
    border-color: rgba($white, .5);
    color: $white;
  }
}

.side-tabs_link--label {
  background-color: $brand-darker-primary-color;
  display: block;
  position: relative;
  border-bottom: none;

  &::before {
    border-top: 8px solid $white;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: '';
    height: 0; 
    right: 15px;
    position: absolute;
    top: 17px;
    width: 0;
    transition: transform $base-duration linear;
  }

  .side-tabs_links--is-open & {
    border-bottom: 1px solid $transparent-gray;

    &::before {
      transform: rotate(180deg);
    }
  }

  &:focus {
    background-color: $brand-darker-primary-color;
  }

  &:hover {
    background-color: $brand-darker-primary-color;

    &:focus {
      background-color: $brand-darker-primary-color;
    }

    &::before {
      border-top-color: $white;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    display: none;
  }
}

.side-tabs_content {
  display: none;
  opacity: 0;
  padding-bottom: $padding-horizontal;

  @media screen and (min-width: $screen-sm-max) {
    min-height: 500px;
  }
}

.side-tabs_heading {
  opacity: .5;

  @media screen and (max-width: $screen-md-max) {
    display: none;
  }
}

.side-tabs_content--is-visible {
  display: block;
  animation: appear .5s linear forwards;
}

.side-tabs_picture {
  display: inline-block;
}
