//Filters

$filters-drawer-width: 215px;
$filters-min-height: 400px;

.filters {

  @media screen and (max-width: $screen-filters-break) {
    position: relative;
    overflow-x: hidden;

    &.filters--is-open {
      overflow: hidden;
    }
  }

  @media screen and (min-width: ($screen-filters-break + 1px)) {
    padding: $padding-vertical $padding-horizontal;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .alert-message {
    color: $error-color;
    display: block;
    font-weight: bold;
    width: 100%;
  }

}

.filters_heading {
  margin-bottom: $padding-horizontal;
  display: none;

  @media screen and (min-width: ($screen-filters-break + 1px)) {
    text-align: center;
    display: block;
    width: 100%;
  }
}

.filters_subheading {
  font-size: 12px;
  line-height: 1.25em;
  position: relative;
  padding: 0 $padding-horizontal 0 45px;
  margin-bottom: $padding-horizontal;
  min-height: 30px;

  @media (min-width: $screen-sm-min) and (max-width: $screen-filters-break) {
    padding-top: 9px;
  }

  @media screen and (min-width: ($screen-filters-break + 1px)) {
    display: none;
  }
}

.filters_action_toggle {
  border: 1px solid $base-font-color;
  padding: $padding-small 8px;
  border-radius: $base-border-radius;
  position: absolute;
  top: 0;
  left: 0;

  .filters--is-open & {

    .dw-filter {

      &::before {
        content: '\f053';
      }
    }
  }
}

.filters_drawer {
  background-color: $filter-bkg-color;
  position: static;
  width: $filters-drawer-width;

  @media screen and (max-width: $screen-filters-break) {
    position: absolute;
    overflow-x: auto;
    left: 0;
    top: 0;
    height: calc(100% - 10px);
    padding-top: $padding-horizontal;
  }

  @media screen and (min-width: ($screen-filters-break + 1px)) {
    box-shadow: $component-box-shadow;
    padding-top: 6px;
  }
}

.filters_group {
  padding: $padding-horizontal 0;
  border-bottom: 1px solid rgba($filter-font-color, .25);

  label {
    padding: 0 $padding-horizontal;
    font-size: 12px;
    margin-bottom: $padding-horizontal;
    opacity: .75;
    color: $filter-font-color;
  }

  &:last-child {
    border-bottom: none;
  }
}

.filters_facet-list {
  font-size: 14px;
}

.filters_facet {
  display: block;
  width: 100%;
  padding: $padding-small 45px $padding-small 25px;
  position: relative;
  color: $filter-font-color;

  &::before {
    display: block;
    content: '\e94a';
    position: absolute;
    left: 4px;
    top: 6px;
    font-family: 'derbicons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 18px;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: opacity 100ms linear;
    opacity: 0;
  }

  &:hover {
    background-color: $filter-hover-color;
    color: $filter-font-color;
  }
}

.filters_facet--is-active {
  background-color: $filter-active-color;

  &::before {
    opacity: 1;
    animation: appearscaleup .3s $base-timing forwards;
  }

  &:hover {
    background-color: $filter-active-color;

    @media screen and (min-width: $screen-sm-min) {
      background-color: $filter-hover-color;
    }

    &::before {
      opacity: 1;
    }
  }

  &:focus {
    background-color: $filter-active-color;
  }
}

.filters_facet--is-disabled {
  opacity: .5;

  &:hover {
    background-color: transparent;

    &::before {
      display: none;
    }
  }
}

.facet_badge {
  position: absolute;
  right: $padding-small;
  top: 7px;
  border-color: rgba($filter-font-color, .5);
  color: $filter-font-color;
}

.filters_facet-label {
  display: inline-block;
  vertical-align: middle;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .badge--icon {
    vertical-align: top;
  }
}

.filters_pane {

  @media screen and (max-width: $screen-filters-break) {
    box-shadow: $component-box-shadow;
    background-color: $base-body-bkg-color;
    position: relative;
    left: 0;
    padding: $padding-horizontal;
    transition: left .2s linear;
    min-height: $filters-min-height;

    .filters--is-open & {
      left: $filters-drawer-width;
    }
  }

  @media screen and (min-width: ($screen-filters-break + 1px)) {
    width: calc(100% - #{$filters-drawer-width});
    padding-left: $padding-horizontal;
  }
}

.filters_action-close {
  display: none;

  .filters--is-open & {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
