//Lightbox

.lightbox {
  color: $dark-gray;
  cursor: default;
  background-color: $lightbox-bkg-color;
  z-index: $lightbox-z-index;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: $padding-vertical;
  text-align: left;
  font-size: 16px;
  line-height: $base-line-height;

  &.lightbox--is-open {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.lightbox_frame {
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: $base-border-radius;
  box-shadow: 0 0 10px rgba($black, .95);
  min-height: 165px;
  max-height: 100%;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: auto;

  .lightbox--small & {
    max-width: 470px;
  }

  .lightbox--overflow & {
    overflow: visible;
  }

  .lightbox--wide & {
    max-width: $wrapper-width;
  }

  .lightbox--tall & {
    height: 100%;
    overflow: hidden;
  }
}

.lightbox_container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
}

.lightbox_header {
  border-bottom: 1px solid $light-gray;
  color: $gray;
  padding: $padding-horizontal 30px $padding-horizontal $padding-vertical;
  position: relative;
  flex: 0 0 auto;

  .lightbox--small & {
    padding-left: $padding-horizontal;
  }

  @media screen and (max-width: $screen-xs-max) {
    padding-left: $padding-horizontal;
  }
}

.lightbox_title {
  margin: 0;
}

.lightbox_close {
  display: block;
  height: 30px;
  width: 30px;
  position: absolute;
  right: $padding-horizontal;
  top: 3px;
  text-align: center;
  font-size: 18px;
  color: $action-color;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $dark-action-color;
  }
}

.lightbox_body {
  padding: $padding-vertical;
  overflow: auto;
  flex: 0 1 auto;

  .lightbox--overflow & {
    overflow: visible;
  }

  .lightbox--small & {
    padding-left: $padding-horizontal;
    padding-right: $padding-horizontal;
  }

  @media screen and (max-width: $screen-xs-max) {
    padding-left: $padding-horizontal;
    padding-right: $padding-horizontal;
  }
}

.lightbox_footer {
  border-top: 1px solid $light-gray;
  padding: $padding-horizontal $padding-vertical;
  text-align: right;
  flex: 0 0 auto;
}
