.group-betting-pool {
  color: $dark-gray;
  background-color: $white;
  border-color: $white;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  line-height: 1em;
  padding: $padding-medium;

  &:nth-child(even) {
    background-color: $lighter-gray;
    border-color: $base-border-color;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.15em;
    margin-bottom: 3px;
  }
}
.group-betting-pool_sub {
  font-size: 12px;
  line-height: 1em;
  font-style: italic;
  margin: -2px 0 3px;
}
.group-betting-pool_long-description {
  margin-top: 15px;
  margin-bottom: 15px;
}
