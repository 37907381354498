// Marquee

.marquee_item {
  display: block;
  border: 2px solid $black;
  box-shadow: 0 4px 14px rgba($black, .25);

  img {
    display: block;
  }
}

.marquee_item--is-link {

  &:hover {
    border-color: $action-color;
  }
}
