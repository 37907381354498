//Tournament Table

$center-col-width: 160px;
$tournament-table-game-page-heading-link-color: $action-color !default;

.tournament-table_wrapper {
  background-color: $white;
  position: relative;

  @media screen and (min-width: $screen-lg-min) {
    box-shadow: $component-box-shadow;
  }
}

.tournament-table {
  color: $dark-gray;
  background-color: $white;
  border-color: $white;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  line-height: 1em;
  padding: 0 $padding-small;
  position: relative;
  // transition: all $base-duration linear;

  @media screen and (min-width: $screen-md-min) {
    font-size: 14px;
  }

  .game-page & {
    padding: 0;
  }

  &:not(.tournament-table--header):nth-child(even) {
    background-color: $lighter-gray;
    border-color: $base-border-color;
  }

  &:last-child {
    border-bottom-color: $base-border-color;
  }

  &:not(.tournament-table--header) {

    &:hover {
      background-color: $lighter-copen-blue;
      border-color: $copen-blue;
      cursor: pointer;
    }

    &.tournament-table--is-featured {

      &:hover {
        background-color: $light-success-color;
        border-color: $success-color;
      }

      &.tournament-table--is-open {
        background-color: $light-success-color;
        border-color: $dark-success-color;
      }
    }
  }

  &.tournament-table--is-open {
    background-color: $lighter-copen-blue;
    border-color: $base-header-bkg-color !important;
    border-bottom-width: 2px;
    border-top-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
    border-style: solid;
    margin-bottom: 1px;

    // .game-page & {
    //   border-top: 2px solid $copen-blue;
    // }

    > .game-table:first-child {
      background-color: rgba($copen-blue, .3);
    }

    // &.game-table--race-status--final {
    //   border-color: $success-color !important;

    //   > .game-table:first-child {
    //     background-color: rgba($success-color, .2);
    //   }
    // }

    &:not(.tournament-table--header):not(.tournament-table--is-featured):nth-child(even) {
      background-color: $lighter-copen-blue;
    }

    .dw-dots-three-horizontal {

      &::before {
        content: "\f00d";
      }
    }
  }

  &.tournament-table--game-page-heading {
    background-color: $base-header-bkg-color !important;
    // border: none !important;
    // border-bottom: 1px solid transparent !important;
    border: 1px solid $base-header-bkg-color !important;
    // border-width: 1px !important;
    color: $white;
    padding: $padding-small $padding-small $padding-vertical $padding-small;

    // &.tournament-table--is-open {
    //   border-bottom-color: 1px solid transparent !important;
    // }

    @media (min-width: $screen-wall-min) and (max-width: $screen-wall-max) {
      width: $game-table-width;
    }

    @media screen and (min-width: $screen-ss-min) {
      width: $game-table-ss-width;
    }
  }
}

.tournament-table--is-featured {
  position: relative;
}

.tournament-table--link {
  display: block;

  &:hover {
    text-decoration: none;
    color: $dark-gray;

    .tournament-table_link {
      text-decoration: underline;
    }
  }
}

.tournament-table--cursor-is-default {

  &:hover {
    cursor: default !important;
  }
}

.tournament-table--header {
  background-color: $tab-active-color;
  border-color: $tab-active-color;
  font-size: 12px;
  font-weight: 300;
  padding-top: 4px;
  padding-bottom: 4px;

  @media screen and (max-width: $screen-lg-min) {
    padding: 1px 0;

    > * {
      display: none;
    }
  }
}

.tournament-table--is-transparent {
  background-color: transparent !important;
  border-color: transparent !important;

  &:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    cursor: default !important;
  }
}

.tournament-table--game-page-heading {

  @media (min-width: $screen-lg-min) and (max-width: $screen-wall-min) {
    
    .batch-c-md-1-2 {
      width: 100%;
    }

    .batch-c-md-1-12,
    .batch-c-md-1-10,
    .batch-c-md-1-8 {
      width: 20%;
    }
  }
}

.tournament-table_row--game {

  &.batch-g {

    @media screen and (max-width: $screen-xs-max) {
      align-items: flex-start;
    }
  }
}

.tournament-table_row--details {
  overflow: hidden;
  max-height: 0;
  padding: 0 $padding-small;
  position: relative;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {//target IE10+
    transition: max-height .3s ease-out;
  }

  @supports (-ms-ime-align:auto) {//target Edge
    transition: max-height .3s ease-out;
  }

  .tournament-table--is-open & {
    animation: slidedowntall .3s ease-out forwards;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {//target IE10+
      animation: none;
      max-height: 1000px;
    }

    @supports (-ms-ime-align:auto) {//target Edge
      animation: none;
      max-height: 1000px;
    }

    &:hover {
      overflow: visible;
    }
  }

  .game-page & {
    padding: 0;
  }
}

.tournament-table_cell--cta {

  @media screen and (max-width: $screen-xs-max) {
    padding-top: $padding-horizontal !important;
  }

  @media screen and (min-width: $screen-sm-min) {
    text-align: center;
  }

  > div > .btn--small { //TODO: Refactor for lightbox instance
    width: 64px;
  }
}

.tournament-table_label {
  font-weight: 700;
  vertical-align: middle;
}

@mixin mobileLabel() {
  display: inline-block;
  position: relative;
  margin-top: $padding-horizontal;

  &::before {
    color: $medium-gray;
    content: attr(aria-label);
    display: block;
    position: absolute;
    width: 100%;
    top: -15px;
    left: 0;
    font-size: 11px;
    font-weight: 100;
    text-align: left;

    .game-page & {
      color: rgba($white, .5);
    }
  }

  &:empty {
    display: none;
  }
}

.tournament-table_mobile-label {

  @media screen and (max-width: $screen-md-max) {
    @include mobileLabel();
  }

  .tournament-table--game-page-heading & {
    @include mobileLabel();
  }
}

.tournament-table_label--prize {
  color: $success-color;
}

// .tournament-table_label--emphasis {

//   @media screen and (min-width: $screen-lg-min) {
//     font-size: 14px;
//   }
// }

.tournament-table_players_total {
  position: relative;
}

.tournament-table_players_count {
  left: 0;
  position: absolute;
  top: 0;

  &.tournament-table_players_count--is-dissappearing {
    animation: disappearup .3s linear forwards;
  }

  &.tournament-table_players_count--is-appearing {
    animation: appearup .3s linear forwards;
  }
}

.tournament-table_players_count--is-next {
  opacity: 0;
}

.tournament-table_players_count--hidden {
  position: static;
  opacity: 0;
}

.tournament-table_sort {
  color: rgba($white, 0.75);
}

a.tournament-table_sort {
   
  &:hover {
    color: $white;
    text-decoration: none;
  }
}

.tournament-table_sort--is-active {

  &::after {
    content: '';
    font-family: FontAwesome;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 8px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    padding-left: $padding-small;
    vertical-align: top;
  }
}

.tournament-table_sort--is-ascending {

  &::after {
    content: '\f078';
  }
}

.tournament-table_sort--is-descending {

  &::after {
    content: '\f077';
  }
}

.tournament-table_link {
  color: $tournament-table-link-color;
  display: inline-block;
  // font-size: 16px;
  font-weight: 700;
  line-height: 1.15em;
  transition: font-size $base-duration linear;
  margin-bottom: 3px;

  @media screen and (max-width: $screen-base-max) {
    min-height: 28px;
  }

  &:hover,
  &:focus {
    color: $tournament-table-link-hover-color;
  }

  .tournament-table--game-page-heading & {
    color: $white;
    margin-bottom: $padding-horizontal;
    text-decoration: none !important;
    cursor: default !important;
    pointer-events: none;
  }

  @media screen and (min-width: $screen-sm-min) {
    padding-right: $padding-horizontal;

    .tournament-table--is-open &,
    .tournament-table--game-page-heading & {
      font-size: 18px;
    }
  }

  .tournament-table--is-featured & {
    color: $dark-success-color;

    &:hover,
    &:focus {
      color: darken($forest-green, 20%);
    } 
  }
}

.tournament-table_sub {
  font-size: 12px;
  line-height: 1em;
  font-style: italic;
  margin: -2px 0 3px;

  // @media screen and (min-width: $screen-md-min) {
  //   font-size: 14px;
  // }
  // transition: font-size $base-duration linear;

  // .tournament-table--is-open & {
  //   // display: block;

  //   @media screen and (min-width: $screen-sm-min) {
  //     font-size: 16px;
  //   }
  // }
}

.tournament-table_inline-list {
  // display: none;
  font-size: 12px;
  line-height: 1em;
  min-width: 290px;

  // @media screen and (min-width: $screen-lg-min) {
  //   // display: block;
  //   // margin: 4px 0 1px;

  //   .tournament-table--is-open & {
  //     margin-bottom: $padding-horizontal;
  //   }
  // }

  li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 $padding-small;
    border-left: 1px solid rgba($dark-gray, .25);

    &:nth-last-child(2) {

      span {
        display: inline-block;

        &::after {
          display: inline-block;
          padding-right: 2px;
          content: ',';
        }

        &:last-child {

          &::after {
            display: none;
          }
        }
      }
    }

    &:first-child {
      padding-left: 0;
      border-left: 0;
      height: 19px;
    }

    &:empty + li {
      padding-left: 0;
      border-left: 0;
    }

    &:empty {
      padding: 0;
    }
  }

  .badge {
    margin-top: -1px;
  }
}

.tournament-table_badges_container {
  padding-bottom: $padding-horizontal;
}

.tournament-table_badges {
  display: table;
  color: $dark-gray;
  font-size: 11px;
  margin: 0 0 $padding-small;
  text-align: left;

  .tournament-table--game-page-heading .tournament-table_row--details & {
    color: $white;

    dd a {
      color: $tournament-table-game-page-heading-link-color;
    }
  }

  dt {
    display: table-cell;
    margin: 0;
    vertical-align: middle;
    width: 110px;

    .badge {
      width: 100%;
      text-align: left;
    }
  }

  dd {
    display: table-cell;
    margin: 0;
    padding-left: $padding-horizontal;
    vertical-align: middle;
    line-height: 1.25em;
  }
}

.tournament-table_meta {
  display: table;
  margin-bottom: $padding-small;
  width: 100%;
}

.tournament-table_meta--details {
  position: relative;
  padding-top: $padding-vertical;
}

.tournament-table_meta--product {
  margin-bottom: 0;

  > .tournament-table_meta_cell {
    border-right: 1px solid $transparent-gray;

    &:last-child {
      border-right: 0;
    }

    &.tournament-table_meta_cell--cta {
      border-right: none;
    }
  }

  @media screen and (max-width: $screen-lg-min) {
    border: 1px solid $transparent-gray;
  }
}

.tournament-table_meta--product-info {
  margin-bottom: $padding-small;
}

.tournament-table_meta_cell {
  display: table-cell;
  padding: $padding-small;
  // text-align: center;
  vertical-align: top;
}

.tournament-table_meta_cell--badges {

  @media screen and (max-width: $screen-wall-max) {
    position: absolute;
    left: $padding-small;
    bottom: 0;
    padding: 0 0 $padding-small;
    border: 0 !important;

    .tournament-table_meta_item {

      dt {
        display: none;
      }
    }
  }

  @media screen and (max-width: $screen-xs-min) {
    bottom: 60px;
  }

  @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    bottom: 59px;
  }

   @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    bottom: 70px;
   }
}

.tournament-table_meta_cell--players {

  @media screen and (max-width: $screen-xs-min) {
    border: 0 !important;
  }
}

.tournament-table_meta_cell--cta {
  vertical-align: middle;

  @media screen and (max-width: $screen-xs-min) {
    position: absolute;
    right: 0;
    bottom: -56px;
    padding: 0 $padding-small $padding-small;
    width: 100%;

    .tournament-table_meta_item {

      dt {
        display: none;
      }
    }
  }
}

.tournament-table_meta_cell--prize {
  text-align: center;

  @media screen and (max-width: $screen-xs-max) {
    display: block;
    // margin-bottom: $padding-horizontal;

    .tournament-table_meta_item {
      text-align: left;
    }
  }

  // @media (min-width: $screen-sm-min) and (max-width: $screen-details-break) {
  //   padding-right: 60%;
  // }
}

// .tournament-table_meta_cell--details {
//   width: $center-col-width;

//   .product_info & {
//     width: auto;
//   }

//   @media screen and (max-width: $screen-details-break) {
//     display: block;
//     width: 100% !important;

//     .tournament-table_meta_item {
//       text-align: left;
//     }
//   }

//   @media (min-width: $screen-sm-min) and (max-width: $screen-details-break) {
//     padding-right: 60%;
//   }
// }

.tournament-table_meta_cell--details {
  width: 50%;

  @media screen and (max-width: $screen-xs-max) {
    display: block;
    width: 100%;
    // padding-top: $padding-small;
  }

  // @media (min-width: $screen-sm-min) and (max-width: $screen-details-break) {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   width: 60%;
  // }
}

.tournament-table_meta_prize {
  color: $success-color;
}

.tournament-table_meta_item {
  margin: 0 0 $padding-horizontal;
  position: relative;

  dt {
    color: $dark-gray;
    font-size: 12px;
    font-weight: 300;
    margin: 0 0 $padding-small;

    .tournament-table--game-page-heading .tournament-table_row--details & {
      color: $white;
      opacity: .75;
    }

    .product_info .tournament-table_tracks-group & {
      opacity: .5;
    }

    .product_heading & {
      // margin-bottom: $padding-small;

      @media screen and (max-width: $screen-xs-max) {
        margin-bottom: 0;
      }
    }
  }

  &.tournament-table_meta_item--large {

    dt {
      color: $dark-gray;
      text-align: left;
    }
  }

  &.tournament-table_meta_item--left {

    dt {
      color: $gray;
    }
  }
}

.tournament-table_meta_note {
  color: $medium-gray;
  font-size: 11px;
  font-weight: 300;
}

.tournament-table_meta_note--bottom {
  bottom: -12px;
  left: 0;
  position: absolute;
  width: 100%;
}

.tournament-table_meta_label {
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;

  @media screen and (min-width: $screen-xs-max) {
    font-size: 24px;
  }
}

.tournament-table_players {
  // color: $medium-gray;
  // text-align: center;
  // margin: 0 auto;
  // margin: 0 0 $padding-horizontal;
  // width: $center-col-width;

  // @media screen and (max-width: $screen-details-break) {
  //   text-align: left;
  //   margin-left: 0;
  //   margin-right: 0;
  //   max-width: none;
  // }

  // @media (min-width: $screen-details-break) and (max-width: $screen-wall-max) {
  //   max-width: 160px;
  // }

  // @media screen and (min-width: $screen-ss-min) {
  //   max-width: 252px;
  // }

  // > dd {

  //   @media screen and (min-width: $screen-details-break) {
  //     padding-left: 4px;
  //   }
  // }

  a {
    border: 1px solid $light-gray;
    color: $medium-gray;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    margin: 0 4px 4px 0;
    text-align: center;
    vertical-align: middle;
    transition: all $base-duration $base-timing;

    span:not(.avatar) {
      display: block;
      padding: 9px 0;
      height: $avatar-tiny-width;
      width: $avatar-tiny-width;
    }
  }

  a {

    &:hover {
      background-color: $dark-action-color;
      border-color: $dark-action-color;
      color: $white;
      text-decoration: none;
    }
  }

  .avatar {
    border: none;
  }
}

.tournament-table_player--separator {
  border-color: transparent;
  font-weight: normal;
  width: 15px;
  margin: 0 6px 0 2px;
  font-size: 14px;
}

.tournament-table_players--large {

  dt {
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-bottom: $padding-horizontal;
    text-align: center;
    color: $medium-gray;

    @media screen and (max-width: $screen-details-break) {
      text-align: left;
    }
  }
}

.tournament-table_tracks-group {
  // margin: 0 auto $padding-horizontal;
  margin-bottom: $padding-horizontal;
  // display: inline-block;
  // text-align: left;
  // width: $center-col-width;

  &:only-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $screen-details-break) {
    margin-bottom: 0;
    max-width: none;
  }

  li {
    padding-bottom: $padding-small;

    .tournament-table_list .tournament-table_row--details & {
      display: inline-block;
    
      dd {

        &::after {
          display: inline-block;
          padding-right: 2px;
          content: ',';
        }
      }

      &:last-child {

        dd {

          &::after {
            display: none;
          }
        }
      }
    }

    // .product_info & {
    //   padding-bottom: 0;
    // }
  }
}

.tournament-table_tracks {
  color: $dark-gray;
  display: table;
  margin: 0;

  @media screen and (max-width: $screen-details-break) {
    margin: 0;
  }

  .product_info & {
    margin-right: 0;
    margin-left: 0;
  }

  dt {
    display: table-cell;
    font-weight: 400;
    margin: 0;
    padding-right: $padding-small;
    text-align: right;
    // width: 50%;
  }

  dd {
    display: table-cell;

    .tournament-table--game-page-heading .tournament-table_row--details & {
      color: $white;
      font-weight: 700;
    }

    ul {

      &:before {
        content: '(';
      }

      &::after {
        content: ')';
      }

      li {
        display: inline-block;

        &:not(:last-child) {
          border-right: 1px solid rgba($black, .15);
          margin-right: $padding-small;
          padding-right: $padding-small;
        }
      }
    }
  }
}

// .tournament-table_full {
//   color: $red;
//   font-size: 12px;
//   font-weight: 700;

//   .btn--is-disabled & {
//     opacity: .75;
//   }
// }

.tournament-table_payout-table {
  display: table;
  margin: 0 auto $padding-small;
  // font-size: 11px;
  // line-height: 1.5em;

  @media screen and (max-width: $screen-details-break) {
    margin: 0;
  }

  [class^="dw-"] {
    line-height: 0;

    & + span {
      padding-left: 2px;
    }
  }
}

.tournament-table_payout-table--large {
  display: block;
}

.tournament-table_payout-row {
  display: table-row;

  &:only-child,
  &:first-child:nth-last-child(2),
  &:first-child:nth-last-child(2) ~ dl,
  &:first-child:nth-last-child(3),
  &:first-child:nth-last-child(3) ~ dl,
  &:first-child:nth-last-child(4),
  &:first-child:nth-last-child(4) ~ dl,
  &:first-child:nth-last-child(5),
  &:first-child:nth-last-child(5) ~ dl {
    font-size: 24px;
    line-height: 20px;

    dt {
      font-size: 16px;
    }

    @media screen and (min-width: $screen-details-break) {
      dd,
      dt {
        padding-top: $padding-small;
      }
    }
  }

  .tournament-table_payout-table--large & {
    display: block;
  }

  dt {
    display: table-cell;
    margin: 0;
    text-align: right;
    vertical-align: top;
    padding-bottom: 8px;

    .product_info & {
      padding-bottom: 0;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      white-space: nowrap;
    }

    .tournament-table_payout-table--large & {
      font-weight: 400;
      display: block;
      margin-bottom: $padding-horizontal;
      text-align: center;
      color: $medium-gray;

      @media screen and (max-width: $screen-details-break) {
        text-align: left;
      }
    }
  }

  dd {
    color: $success-color;
    display: table-cell;
    font-weight: 700;
    text-align: left;
    padding: 0 0 8px $padding-horizontal;

    .product_info & {
      padding-bottom: 0;
    }

    span {
      display: inline-block;
      padding-right: 4px;
    }

    .tournament-table_payout-table--large & {
      font-size: 24px;
      display: block;
      padding-left: 0;
      text-align: center;

      @media screen and (max-width: $screen-details-break) {
        text-align: left;
      }
    }
  }
}

.tournament-table_payout-row--is-hidden {
  display: none;

  .tournament-table_payout--is-open & {
    display: table-row;
  }
}

.tournament-table_payout-note {
  font-size: 11px;
  font-weight: 300;
  color: $dark-gray;
  vertical-align: middle;
  display: inline-block;
  // padding: 0 4px;

  // &:last-child {
  //   padding: 0 0 0 4px;
  // }

  .tournament-table--game-page-heading .tournament-table_row--details & {
    color: $white;
  }
}

a.tournament-table_payout-toggle {
  display: block;
  font-size: 12px;
  line-height: 1em;
  color: $tournament-table-game-page-heading-link-color;
}
