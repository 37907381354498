//Typography

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background-color: $base-body-bkg-color;
  color: $base-font-color;
  height: 100%;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
}

.body--overflow-hidden,
.body--lightbox-is-open {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;

  &:only-child {
    margin-bottom: 0;
  }
}

h1 {
  
  @media screen and (max-width: $medium-screen) {
    font-size: 1.5em;
  }
}

h2,
h3 {
  
  @media screen and (max-width: $medium-screen) {
    font-size: 1em;
  }
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: all $base-duration $base-timing;

  &:hover {
    color: $dark-action-color;
    text-decoration: underline;
  }

  &:active,
  &:focus {
    text-decoration: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $padding-horizontal 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

::selection {
  background: $light-action-color;
}

strong {
  font-weight: bold;
}

