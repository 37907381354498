//Game Table

$tickes-scroll-width: 180px;
$ticket-cell-width: 56px;
$game-results-cell-width: 215px;
$game-results-cell-width-real-money: 235px;

.game-table_grid {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.game-table_grid-cell {
  @media screen and (max-width: $screen-leaderboard-break) {
    width: 100%;
  }

  @media (min-width: $screen-leaderboard-break + 1) and (max-width: $screen-lg-max) {
    width: calc(100% - 300px);
  }

  @media (min-width: $screen-wall-min) and (max-width: $screen-wall-max) {
    width: $game-table-width;
  }

  @media screen and (min-width: $screen-ss-min) {
    width: $game-table-ss-width;
  }
}

.game-table_ad {
  margin-bottom: $padding-vertical;
}

.game-table_header_placeholder {
  @media screen and (min-width: $screen-lg-max) {
    height: 62px;

    &.game-table_header--is-fixed {
      height: 88px;
    }

    .game-page--is-live-bankroll & {
      height: 78px;
    }

    .game-page--is-live-bankroll &.game-table_header--is-fixed {
      height: 100px;
    }
  }
}

.game-table_header_container {
  background-color: $base-header-bkg-color;
  position: relative;

  @media screen and (min-width: $screen-lg-max) {
    .game-table_header--is-fixed & {
      position: fixed;
      top: $header-height;
      padding-top: 45px;
      z-index: $game-table-header-z-index;

      &::after {
        background: linear-gradient(to bottom, rgba($black, 0.1), rgba($black, 0));
        border-top: 1px solid $transparent-gray;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: $padding-small;
      }
    }

    .notification--is-present .game-table_header--is-fixed & {
      top: $header-height + $notification-fixed-height;
    }
  }

  @media (min-width: $screen-wall-min) and (max-width: $screen-wall-max) {
    width: $game-table-width;
  }

  @media screen and (min-width: $screen-ss-min) {
    width: $game-table-ss-width;
  }
}

.game-table_header_container--is-static {
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}

.game-table_wrapper {
  background-color: $white;

  @media screen and (min-width: $screen-sm-min) {
    box-shadow: $component-box-shadow;
  }

  @media (min-width: $screen-wall-min) and (max-width: $screen-wall-max) {
    width: $game-table-width + 2;
  }

  @media screen and (min-width: $screen-ss-min) {
    width: $game-table-ss-width + 2;
  }

  @media (min-width: $screen-wall-min) and (max-width: $screen-wall-max) {
    // width: ($game-table-width + 2);
    width: $game-table-width;
  }

  @media screen and (min-width: $screen-ss-min) {
    // width: ($game-table-ss-width + 2);
    width: $game-table-ss-width;
  }
}

.game-table {
  border-collapse: collapse;
  display: table;
  color: $dark-gray;
  line-height: 1em;
  width: 100%;
  height: 43px;
  position: relative;

  @media screen and (max-width: $screen-sm-min) {
    display: block;
    height: auto;
  }

  @media (min-width: $screen-wall-min) and (max-width: $screen-wall-max) {
    width: $game-table-width;
  }

  @media screen and (min-width: $screen-ss-min) {
    width: $game-table-ss-width;
  }

  .tournament-table--is-cancelled & {
    opacity: .5;
  }

  .game-table_header_container & {
    height: auto;
  }
}

.game-table--label {
  min-height: 0;
}

.game-table--tickets {
  height: 46px !important;

  // .game-page--is-live-bankroll & {
  //   min-height: 87px !important;
  // }
}

.game-table--width-100 {
  width: 100%;
}

.game-table--width-auto {
  width: auto;
}

.game-table--race-status--final {
  background-color: $light-success-color !important;

  .tournament-table--is-open & {
    background-color: lighten($success-color, 40%) !important;
  }

  .game-table_cell--action {
    &::before {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        //target IE10+
        top: 18px;
      }

      @supports (-ms-ime-align: auto) {
        //target Edge
        top: 18px;
      }
    }
  }
}

.game-table_title {
  opacity: 0;
  font-size: 18px;
  color: $white;
  position: absolute;
  top: $padding-vertical;
  left: $padding-small;
  margin-bottom: 0;
  display: none;
  font-family: $base-font-family;

  @media screen and (max-width: $screen-xs-max) {
    line-height: 1em;
    margin-bottom: $padding-small;
  }

  @media screen and (min-width: $screen-lg-max) {
    display: block;

    .game-table_header--is-fixed & {
      transition: opacity $base-duration linear;
      opacity: 1;
    }
  }
}

.game-table_title--is-static {
  opacity: 1;
  position: static;
  display: block;
}

.game-table_row {
  display: table-row;

  @media screen and (max-width: $screen-sm-min) {
    display: block;
    position: relative;
    padding: 0 $ticket-cell-width 0 $padding-small;

    &.game-table_row--header,
    &.game-table_row--game-card {
      padding-left: 0;
      padding-right: 0;
      font-size: 10px;
    }

    &.game-table_row--horses {
      padding-left: 0;
      min-height: 65px;
    }

    .game-table--has-base &:not(.game-table_row--header) {
      padding-right: 165px;
    }

    .game-table--has-scroll &:not(.game-table_row--header) {
      padding-right: $tickes-scroll-width;
    }
  }
}

.game-table_row--header {
  color: $gray;
  font-size: 12px;

  @media screen and (max-width: $screen-sm-min) {
    .game-table--tickets & {
      min-height: 46px;
    }

    // .game-page--is-live-bankroll .game-table--tickets & {
    //   min-height: 87px;
    // }
  }

  .game-table_header_container & {
    color: $white;
  }

  .game-table--tickets & {
    border-top: 1px solid $transparent-gray;
  }
}

.game-table_row--horses {
  background-color: $white;

  &:nth-child(odd) {
    background-color: $lighter-gray;
  }

  &:first-child {
    .game-table_cell {
      border-top: 1px solid $base-border-color;
    }
  }

  &:last-child {
    .game-table_cell {
      padding-bottom: $padding-horizontal;
    }
  }

  &:hover {
    background-color: $light-copen-blue;
  }
}

.game-table_row--header-alt {
  background-color: rgba($gray, 0.1);

  &:first-child {
    .game-table_cell {
      border-bottom: 1px solid $base-border-color;
      border-top: 1px solid $base-border-color;
      padding-bottom: $padding-cell;
      padding-top: $padding-cell;
    }
  }
}

.game-table_row--winner {
  background-color: $light-success-color !important;
  border-bottom: 1px solid $lime;
  border-top: 1px solid $lime;
}

.game-table_row--scratched {
  color: $gray;
  text-decoration: line-through;
}

.game-table_row--horses-99 {
  .game-table_horse {
    &:before {
      background-color: transparent;
      border-color: transparent;
      color: transparent;
      content: '  ';
      outline-color: transparent;
    }
  }
}

.game-table_row--is-saved {
  animation: fadebkgthrulime 1.5s;
}

.game-table_cell {
  display: table-cell;
  padding: $padding-cell 2px;
  vertical-align: middle;

  @media screen and (max-width: $screen-sm-min) {
    display: inline-block;
    height: auto;
    position: static;

    .game-table_card & {
      &:empty {
        display: none;
      }
    }
  }

  &:last-child {
    border-right: 0;
  }

  .game-table_row--header & {
    padding-bottom: 0;
    padding-top: 0;

    @media screen and (min-width: $screen-sm-min) {
      height: auto;
    }
  }

  .game-table--cell-borders .game-table_row:not(.game-table_row--header) & {
    border: 1px solid $base-border-color;

    @media screen and (max-width: $screen-sm-min) {
      border: none;
    }
  }

  .game-table--cell-padding & {
    padding: $padding-small;
  }

  .game-table--cell-padding .game-table_row--header & {
    padding-bottom: 2px;
  }

  .game-table--valign-top & {
    vertical-align: top;
  }
}

.game-table_cell--action {
  position: relative;
  padding-left: $padding-vertical;

  @media screen and (max-width: $screen-sm-min) {
    padding-left: 0;
  }

  &::before {
    display: block;
    content: '\e9cf';
    position: absolute;
    left: 0;
    top: calc(50% - 12px);

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      //target IE10+
      top: 10px;
    }

    @supports (-ms-ime-align: auto) {
      //target Edge
      top: 10px;
    }

    font-family: 'derbicons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 22px;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;
    color: $gray;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: transform 100ms linear;

    @media screen and (max-width: $screen-sm-min) {
      display: none;
    }

    .tournament-table--is-transparent & {
      display: none;
    }
  }

  .game-table_row--header & {
    &::before {
      display: none;
    }
  }

  .tournament-table--is-open & {
    &::before {
      transform: rotate(90deg);
    }
  }
}

.game-table_cell--race {
  text-align: center;
  width: 35px;

  @media screen and (max-width: $screen-sm-min) {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;

    .game-table_row--header & {
      position: static;
    }

    .game-table_card & {
      top: 4px;
    }
  }

  &.game-table_cell--action {
    width: 49px;

    @media screen and (max-width: $screen-sm-min) {
      width: 25px;
      top: 3px;
      font-size: 18px;

      .game-table_row--header & {
        width: 27px;
        font-size: 10px;
      }
    }
  }

  .game-table_card & .game-summary_label {
    @media screen and (max-width: $screen-sm-min) {
      font-size: 10px;
      margin: -1px 0 0 -5px;
      display: block;
    }
  }
}

.game-table_cell--track {
  width: 170px;

  @media screen and (max-width: $screen-sm-min) {
    display: block;
    width: 100%;
    height: auto;
    padding: $padding-small $padding-small 2px 0;

    .game-table_row--header & {
      display: inline-block;
      width: auto;
    }

    .game-table_row:not(.game-table_row--header) & {
      padding-left: $padding-vertical;
    }
  }
}

.game-table_cell--overview {
  padding-right: $padding-small;

  @media screen and (max-width: $screen-sm-min) {
    display: block;
    width: 100%;
    padding: 0 $padding-small $padding-small $padding-vertical;

    .game-table_row--header & {
      display: none;
    }

    .survivor-stats {
      margin: $padding-small 0 0;
    }

    .game-table_label {
      margin: 0;
      max-width: none;
    }

    .game-table--has-base & {
      font-size: 14px;
    }
  }
}

.game-table_cell--spacer {
  padding: 0;
}

.game-table_cell--players {
  width: 75px;
}

.game-table_cell--nav {
  border-right: 1px solid $base-border-color;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 12px;

  .game-table_header_container &,
  .tournament-table--is-transparent & {
    border-right-color: $transparent-gray;
  }

  @media screen and (max-width: $screen-sm-min) {
    display: none;

    .game-table--has-scroll .game-table_header_container & {
      display: block;
      border: none;
      position: absolute;
      right: $tickes-scroll-width;
      top: 3px;

      &:last-child {
        right: 0;
      }
    }
  }
}

.game-table_cell--ticket {
  border-left: 1px solid $base-border-color;
  border-right: 1px solid $base-border-color;
  padding-left: $padding-small;
  padding-right: $padding-small;
  text-align: center;
  width: $ticket-cell-width;

  .game-page .game-table_header_container &,
  .tournament-table--is-transparent & {
    border-color: $transparent-gray !important;
  }

  @media screen and (max-width: $screen-sm-min) {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-top: $padding-small;
    padding-bottom: 0;

    .game-table--has-base &:nth-last-child(1) {
      right: 0;
    }

    .game-table--has-base &:nth-last-child(2) {
      right: 55px;
    }

    .game-table--has-base &:nth-last-child(3) {
      right: 110px;
    }

    .game-table--has-scroll &:nth-last-child(2) {
      right: 15px;
    }

    .game-table--has-scroll &:nth-last-child(3) {
      right: 70px;
    }

    .game-table--has-scroll &:nth-last-child(4) {
      right: 125px;
    }
  }

  // &:not(:first-child) {
  //   border-left: 1px solid $base-border-color;
  // }

  .game-table_cell--results + &,
  .game-table_cell--player-picks + & {
    border-left: 1px solid $base-border-color;
  }

  .game-table_row--header & {
    padding-left: 0;
    padding-right: 0;
  }
}

.game-table_cell--ticket-2x {
  width: $ticket-cell-width * 2;
}

.game-table_cell--ticket-3x {
  width: $ticket-cell-width * 3;
}

.game-table_cell--ticket-md {
  width: 71px;

  .btn {
    white-space: normal;
  }
}

.game-table_cell--ticket-subtle {
  border-right-style: dashed;
  border-left-width: 0;

  &:last-child,
  &:nth-last-child(2) {
    border-right-style: solid;
  }

  .game-table_cell--results + &,
  .game-table_cell--nav + & {
    border-left-width: 1px;
  }
}

.game-table_cell--horse-name {
  padding-left: 30px;
  width: 180px;

  @media screen and (max-width: $screen-sm-min) {
    display: block;
    width: 100%;
    padding-left: $padding-small;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-wall-min) {
    width: 160px;
  }

  .game-table_horse {
    word-break: break-word;
  }
}

.game-table_cell--horse-jockey {
  font-size: 12px;

  @media screen and (max-width: $screen-sm-min) {
    padding: 2px;
    width: calc(100% - 45px);

    .game-table_row--header & {
      display: none;
    }
  }
}

.game-table_cell--horse-trainer {
  font-size: 12px;

  @media screen and (max-width: $screen-sm-min) {
    padding: 2px;
    width: calc(100% - 45px);

    .game-table_row--header & {
      display: none;
    }
  }
}

.game-table_cell--horse-odds {
  font-size: 12px;

  @media screen and (max-width: $screen-sm-min) {
    padding: 2px $padding-small 2px $padding-small;
    width: 35px;
    text-align: center;
    vertical-align: top;

    .game-table_row--header & {
      display: none;
    }
  }
}

.game-table_cell--player-picks {
  width: $game-results-cell-width;
  padding-left: 0;
  font-size: 12px;

  .game-page--is-live-bankroll & {
    width: $game-results-cell-width-real-money;
  }

  .game-table_row--header & {
    border-left: none;
    padding-left: 2px;
  }

  @media screen and (max-width: $screen-sm-min) {
    padding: $padding-small 0 $padding-small $padding-small;
    position: static;
    width: 100%;

    &:empty {
      display: none;
    }

    .game-table_row--header & {
      display: none;
    }
  }
}

.game-table_cell--valign-top {
  vertical-align: top;
}

.game-table_cell--results {
  width: $game-results-cell-width;
  padding-right: $padding-small;

  .game-page--is-live-bankroll & {
    width: $game-results-cell-width-real-money;
  }

  @media screen and (max-width: $screen-sm-min) {
    display: block;
    width: 100%;
    padding: 2px $padding-small $padding-small 0;

    .game-table_row--header & {
      display: none;
    }

    .game-table_row--horses & {
      padding-left: $padding-small;
    }

    .game-table_status {
      padding-left: 21px;
    }
  }

  @media screen and (min-width: $screen-ss-min) {
    font-size: 14px;
    padding-left: 0;
  }

  .game-table_row--header & {
    font-size: 12px;
  }
}

.game-table_cell--stats {
  text-align: right;

  @media screen and (max-width: $screen-xs-max) {
    position: absolute;
    right: 0;
    top: calc(100% + #{$padding-horizontal});
    width: 40%;
  }
}

.game-table_cell--award {
  vertical-align: top;
  text-align: right;

  @media screen and (max-width: $screen-xs-max) {
    position: absolute;
    right: 50%;
    top: 100%;
    width: 24px;
    height: 24px;
  }
}

.game-table_cell--total {
  @media screen and (max-width: $screen-xs-max) {
    position: absolute;
    right: 0;
    top: 100%;
    width: 40%;
  }
}

.game-table_results-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  font-size: 12px;

  @media screen and (max-width: $screen-sm-min) {
    width: 195px;
    margin-left: $padding-vertical;

    .game-table--has-base & {
      width: auto;
      margin-right: $padding-small;
      margin-left: 0;
    }

    .game-table--has-scroll & {
      font-size: 11px;
    }
  }

  @media (min-width: $screen-iphone5-max) and (max-width: $screen-xs-max) {
    margin-left: $padding-vertical !important;
  }
}

.game-table_results-row {
  display: table-row;
}

.game-table_results-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 2px;

  @media screen and (max-width: $screen-sm-min) {
    .game-table_row--horses & {
      > .game-summary_label {
        position: relative;
        margin-top: 12px;
        display: inline-block;

        .game-table--has-base & {
          font-size: 11px;
        }

        &::before {
          color: $gray;
          content: 'Win';
          display: block;
          position: absolute;
          width: 100%;
          top: -13px;
          left: 0;
          font-size: 11px;
          font-weight: 100;
        }
      }

      &:nth-child(2) {
        > .game-summary_label {
          &::before {
            content: 'Place';
          }
        }
      }

      &:nth-child(3) {
        > .game-summary_label {
          &::before {
            content: 'Show';
          }
        }
      }
    }
  }

  &:first-child {
    padding-left: 0;
    text-align: left;
  }

  &:last-child {
    padding-right: 0;
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    text-align: right;
    width: 36px;

    .game-page--is-live-bankroll & {
      width: 42px;
    }
  }
}

.game-table_toggle-link--is-open {
  &::before {
    transform: rotate(90deg);
  }
}

.game-table_note {
  color: $white;
  font-size: 11px;
  line-height: 1.2em;
  margin: 0 $padding-horizontal;

  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: $padding-horizontal;
  }

  .btn {
    font-size: 10px;
    padding: 2px 5px;
  }

  .game-table_card & {
    color: $dark-gray;
  }
}

.game-table_meta {
  font-size: 14px;
  display: table;
  margin-bottom: $padding-horizontal;

  @media screen and (min-width: $screen-sm-min) {
    font-size: 18px;
    margin-bottom: $padding-vertical;
  }

  li {
    display: table-cell;
    vertical-align: middle;
    padding: 0 $padding-small;
    border-left: 1px solid $base-border-color;

    &:first-child {
      border-left: none;
      padding-left: 0;
    }
  }
}

.game-table_score {
  font-size: 12px;

  .towel + &,
  [class^="dw-"] + & {
    padding-top: 2px;
  }

  & + .towel {
    margin-top: $padding-small;
  }
}

.game-table_score--has-points {
  color: $ticket-color;
  font-weight: 700;
}

.game-table_label {
  display: block;
  margin: 0 auto;
  max-width: 190px;
}

.game-table_cta {
  color: $action-color;
  font-size: 12px;
  line-height: .75em;

  &:hover {
    color: $dark-action-color !important;
    text-decoration: underline;
  }

  [class^="dw-"] + & {
    color: $medium-gray;
    margin-top: 5px;
    display: inline-block;
  }
}

.game-table_status {
  text-align: left;
  width: 100%;
  display: inline-block;
  padding: 2px 0;

  @media screen and (min-width: $screen-sm-min) {
    padding: 0;
  }

  .game-table_cell--results & {
    font-size: 14px;
  }
}

.game-table_status--scratched {
  font-weight: 700;
  color: $red;
  text-decoration: none;

  @media screen and (min-width: $screen-sm-min) {
    text-align: center;
  }
}

.game-table--has-caution {
  animation: caution 2s ease-out;
}

.game-table--is-highlighted {
  animation: highlight 2s ease-out;
}

.game-table--header {
  border-bottom: 1px solid $base-border-color;
  color: $gray;
  font-size: 10px;
}

.game-table_details {
  padding-right: $padding-horizontal;
}

.game-table_race {
  margin-bottom: $padding-vertical;
  position: relative;
  padding-left: 35px;

  li {
    &:first-child {
      font-size: 32px;
      letter-spacing: -.05em;
      line-height: 1.5em;
      position: absolute;
      top: -$padding-horizontal;
      left: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      font-size: 18px;
      font-weight: 700;
      line-height: .85em;
      margin-bottom: $padding-small;
    }

    &:nth-child(4) {
      font-size: 12px;
      line-height: 1em;
    }
  }
}

.game-table_horse {
  line-height: 1em;
  position: relative;
  font-weight: 300;
  margin-top: 2px;

  &::before {
    background-color: $gray;
    border-color: $white;
    border-style: solid;
    border-width: 1px;
    color: $white;
    content: '0';
    left: 0;
    outline-color: $gray;
    outline-style: solid;
    outline-width: 1px;
    padding: 0 4px;
    text-align: right;
    width: 26px;
    font-size: 14px;
    line-height: 1.25em;
    display: inline-block;
    vertical-align: middle;
    margin: 1px 8px 0 1px;
  }

  li {
    &:first-child {
      font-weight: 700;
      line-height: 1em;
      display: inline-block;
      width: calc(100% - 35px);
      vertical-align: middle;

      .game-table_row--scratched & {
        text-decoration: line-through;
      }
    }

    &:nth-child(2) {
      color: $gray;
      line-height: 1em;
      display: none;
    }
  }
}

$player-picks-timing: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$player-picks-duration: 0.3s;

@keyframes playerpicksbar {
  0% {
    width: 0;
  }
}

@keyframes playerpicksnumber {
  0% {
    left: 0;
  }
}

.game-table_player-picks {
  position: relative;
  height: 15px;
  margin: $padding-vertical 0 $padding-small 0;
  line-height: 1em;

  .game-table--has-scroll & {
    @media screen and (max-width: $screen-iphone5-max) {
      margin-top: 25px;
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-right: 25px;
    height: 24px;
  }

  dt {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
    background-color: $gray;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 2px $gray;
    min-width: 2px;
    animation: playerpicksbar $player-picks-duration $player-picks-timing;
  }

  dd {
    margin: 0;
    font-size: 11px;
    font-weight: 300;
    position: absolute;
    z-index: 1;
    color: $dark-gray;
    left: 2px;
    top: calc(50% - 6px);
    padding-left: $padding-small;
    animation: playerpicksnumber $player-picks-duration $player-picks-timing;

    @media screen and (max-width: $screen-xs-max) {
      padding-left: 0;
      top: -15px;
      left: 0 !important;
      color: $gray;

      .game-table--has-scroll & {
        @media screen and (max-width: $screen-iphone5-max) {
          top: -25px;
        }
      }

      &::after {
        content: attr(data-player-picks);
      }
    }
  }
}

.game-table_horse-group {
  margin: 0 0 2px;
  padding: 0;
  position: relative;
  text-align: left;
  display: table;

  li {
    margin: 0;
    display: table-cell;
    vertical-align: middle;

    &:nth-child(1) {
      width: 17px;
      text-align: center;
    }

    &:nth-child(2) {
      padding-left: 3px;

      @media screen and (max-width: $screen-sm-min) {
        .game-table--has-base & {
          display: none;
        }
      }
    }
  }
}

.game-table_horse-name {
  font-size: 9px;
  line-height: 1em;
  white-space: nowrap;
  margin-top: 2px;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: $screen-sm-min) {
    .game-table--has-base & {
      display: none;
    }

    .game-page--is-live-bankroll & {
      max-width: 45px;
    }
  }
}

.game-table_award {
  font-size: 65px;
  position: relative;

  @media screen and (max-width: $screen-xs-max) {
    transform: scale(0.35);
    position: absolute;
    top: 0;
    left: 38px;
  }

  &::after {
    display: none;
    position: absolute;
    text-align: center;
    content: '$';
    color: $white;
    font-family: $heading-font-family;
    font-size: 20px;
    top: 14px;
    left: 0;
    width: 100%;
    font-weight: 700;

    @media screen and (max-width: $screen-xs-max) {
      font-size: 34px;
      top: 9px;
    }
  }
}

.game-table_card {
  background-color: $white;
  background-image: url(../public/images/backgrounds/bkg-game-card-default-#{$brand-name}.png), url(../public/images/backgrounds/bkg-game-card-default-#{$brand-name}.png);
  background-repeat: repeat-x, repeat-x;
  background-position: 0% -1px, bottom left;
  background-size: auto 12px, auto 12px;
  border: 1px solid $base-border-color;
  display: block;
  padding: $padding-vertical $padding-small;
  margin-bottom: $padding-vertical;
  color: $dark-gray;
  line-height: 1em;

  @media screen and (min-width: $screen-sm-min) {
    background-size: auto 15px, auto 15px;
    border: none;
    box-shadow: 0 0 8px rgba($black, 0.5);
    padding: 30px $padding-horizontal $padding-large;
  }

  &:hover,
  &:focus {
    color: $dark-gray;
    text-decoration: none;
    background-color: $lighter-copen-blue;

    .tournament-table {
      background-color: transparent;
      border-color: $white;

      &:nth-child(even) {
        border-color: $base-border-color;
      }
    }

    .game-table_title {
      color: $tournament-table-link-hover-color;
      text-decoration: underline;
    }
  }

  .tournament-table {
    padding: 0;
    transition: none;
  }

  .game-table_title {
    color: $tournament-table-link-color;
  }
}

.game-table_card--is-complete {
  background-image: url(../public/images/backgrounds/bkg-game-card-complete-#{$brand-name}.png), url(../public/images/backgrounds/bkg-game-card-complete-#{$brand-name}.png);

  &:hover,
  &:focus {
    background-color: $lighter-gray;

    .game-table_title {
      color: $gray;
    }
  }

  .game-table_title {
    color: $gray;
  }

  .game-table_award {
    color: $gray;
  }
}

.game-table_card--is-winner {
  background-image: url(../public/images/backgrounds/bkg-game-card-winner-#{$brand-name}.png), url(../public/images/backgrounds/bkg-game-card-winner-#{$brand-name}.png);

  &:hover,
  &:focus {
    background-color: $light-success-color;

    .game-table_title {
      color: $dark-success-color;
    }
  }

  .game-table_title {
    color: $dark-success-color;
  }

  .game-table_award {
    color: $dark-success-color;

    &::after {}
  }
}

.game-table_card--is-first-place {
  background-image: url(../public/images/backgrounds/bkg-game-card-first-place-#{$brand-name}.png), url(../public/images/backgrounds/bkg-game-card-first-place-#{$brand-name}.png);
  background-color: $lighter-yellow;

  &:hover,
  &:focus {
    background-color: $light-yellow;

    .game-table_title {
      color: $medium-yellow;
    }

    .game-table_award {
      &::after {
        color: $light-yellow;
      }
    }
  }

  .game-table_title {
    color: $medium-yellow;
  }

  .game-table_award {
    color: $medium-yellow;

    &::after {
      display: block;
      content: '#1';
      color: $lighter-yellow;

      @media screen and (max-width: $screen-xs-max) {
        content: '1';
      }
    }
  }
}

.game-table_card--is-second-place {
  background-image: url(../public/images/backgrounds/bkg-game-card-second-place-#{$brand-name}.png), url(../public/images/backgrounds/bkg-game-card-second-place-#{$brand-name}.png);
  background-color: $lighter-copen-blue;

  &:hover,
  &:focus {
    background-color: $light-copen-blue;

    .game-table_title {
      color: $copen-blue;
    }

    .game-table_award {
      &::after {
        color: $light-copen-blue;
      }
    }
  }

  .game-table_title {
    color: $copen-blue;
  }

  .game-table_award {
    color: $copen-blue;

    &::after {
      display: block;
      content: '#2';
      color: $lighter-copen-blue;

      @media screen and (max-width: $screen-xs-max) {
        content: '2';
      }
    }
  }
}

.game-table_card--is-third-place {
  background-image: url(../public/images/backgrounds/bkg-game-card-third-place-#{$brand-name}.png), url(../public/images/backgrounds/bkg-game-card-third-place-#{$brand-name}.png);
  background-color: $lighter-yellow;

  &:hover,
  &:focus {
    background-color: $light-yellow;

    .game-table_title {
      color: $dark-yellow;
    }

    .game-table_award {
      &::after {
        color: $light-yellow;
      }
    }
  }

  .game-table_title {
    color: $dark-yellow;
  }

  .game-table_award {
    color: $dark-yellow;

    &::after {
      display: block;
      content: '#3';
      color: $lighter-yellow;

      @media screen and (max-width: $screen-xs-max) {
        content: '3';
      }
    }
  }
}

.game-table_card-details {
  margin-bottom: $padding-horizontal;

  @media screen and (max-width: $screen-xs-max) {
    > [class^="batch-c"] {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        order: 5;
      }

      &:nth-child(4) {
        order: 2;
      }

      &:nth-child(5) {
        order: 3;
      }

      &:nth-child(6) {
        order: 4;
      }

      &:nth-child(7) {
        order: 1;
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    display: table;
    width: 100%;

    > [class^="batch-c"] {
      display: table-cell;
      width: auto;
      vertical-align: top;
    }
  }
}

.game-table_total {
  margin: 0 0 $padding-vertical;

  dt {
    font-weight: 100;
    color: $gray;
    margin: 0;
  }

  dd {
    color: $lime;
    font-weight: 700;
    padding-top: $padding-small;
    padding-left: 30px;
    font-size: 20px;
    line-height: 1em;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 36px;
      padding-left: 0;
    }
  }
}

.game-table_action {
  &:focus {
    background-color: $white;
    color: $action-color;

    .badge {
      color: $action-color;
      border-color: $action-color;
    }
  }
}

.game-table_action--is-active {
  border-color: $dark-action-color;
  background-color: $dark-action-color;
  color: $white;

  &:focus {
    border-color: $dark-action-color;
    background-color: $dark-action-color;
    color: $white;

    .badge {
      color: $white;
      border-color: $white;
    }
  }

  .badge {
    color: $white;
    border-color: $white;
  }
}

.game-table_action-label {
  span {
    &:nth-child(1) {
      padding-left: 4px;

      @media screen and (max-width: $screen-xs-min) {
        display: inline-block;
        vertical-align: middle;
        max-width: 72px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:nth-child(2) {
      margin-left: 4px;
      vertical-align: middle;
    }
  }
}

.game-table_import-picks {
  color: $action-color;
  padding-top: $padding-small;

  > i,
  > span {
    display: block;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

// .game-table_live-bankroll {
//   margin: 0;

//   > dt {
//     font-weight: $base-font-weight;
//     font-weight: 100;
//     color: rgba($base-font-color, .5);
//     padding: 0;
//   }

//   > dd {
//     font-weight: $heading-font-weight;
//     font-size: 14px;
//   }
// }

.game-table_wager {
  font-size: 12px;
  font-weight: $base-font-weight;
  line-height: 1.25;
  vertical-align: middle;
  padding: 3px $padding-cell 0;
  display: inline-block;
  margin-right: $padding-cell;

  &:last-child {
    margin-right: 0;
  }
}
