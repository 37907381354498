//Countdown

.countdown {
  display: inline-block;
  width: 70px;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 6px;
  color: $black;
  padding: 2px 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 1em;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  > li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 1px;
    max-height: 13px;
    overflow: hidden;
  }
}

.countdown--large {
  font-size: 50px;
  padding-left: $padding-horizontal;
  padding-right: $padding-horizontal;
  margin-bottom: $padding-large;
  width: 190px;

  > li {
    max-height: 50px;
  }

  .dw-clock-o {
    display: block;
    font-size: 34px;
    margin-right: $padding-small;
    opacity: .5;
  }
}

.countdown--is-caution {
  background-color: $bkg-caution-color;
  border-color: $caution-color;
  color: $black;
  animation-name: pulsecountdowncaution;
}

.countdown--is-warning {
  background-color: $error-color;
  border-color: $dark-error-color;
  color: $white;
  animation-name: pulsecountdownwarning;
}

.countdown_icon {
  opacity: .25;

  .countdown--is-warning & {
    opacity: .5;
  }
}

.countdown_cell {
  position: relative;
  height: 12px;
  width: 9px;

  .countdown--large & {
    height: 50px;
    width: 28px;
  }

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    top: 0;
    opacity: .65;
    border-bottom: 1px solid $white;

    .countdown--is-caution & {
      border-color: $bkg-caution-color;
    }

    .countdown--is-warning & {
      border-color: $error-color;
      opacity: .5;
    }
  }
}

.countdown_mtp {
  position: relative;
  height: 12px;
  width: 30px;
}

.countdown_digit {
  display: block;
  position: absolute;
  height: 13px;
  opacity: 0;
  transform: rotateX(-90deg);
  transform-origin: center center;

  .countdown_cell--is-animating & {
    animation-name: appeardown;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

.countdown_digit--9 {

  .countdown_cell--is-animating & {
    animation-delay: 1s;
  }
}

.countdown_digit--8 {

  .countdown_cell--is-animating & {
    animation-delay: 2.5s;
  }
}

.countdown_digit--7 {

  .countdown_cell--is-animating & {
    animation-delay: 4s;
  }
}

.countdown_digit--6 {

  .countdown_cell--is-animating & {
    animation-delay: 5.5s;
  }
}

.countdown_digit--5 {

  .countdown_cell--is-animating & {
    animation-delay: 7s;
  }
}

.countdown_digit--4 {

  .countdown_cell--is-animating & {
    animation-delay: 8.5s;
  }
}

.countdown_digit--3 {

  .countdown_cell--is-animating & {
    animation-delay: 10s;
  }
}

.countdown_digit--2 {

  .countdown_cell--is-animating & {
    animation-delay: 11.5s;
  }
}

.countdown_digit--1 {

  .countdown_cell--is-animating & {
    animation-delay: 13s;
  }
}

.countdown_digit--0 {

  .countdown_cell--is-animating & {
    animation-delay: 14.5s;
  }
}
