//Nag Notification

.nag-notification {
  bottom: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: $nag-notification-z-index;
  pointer-events: none;
  display: none;
}

.nag-notification_link {
  background-color: $red;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid $dark-error-color;
  border-bottom-width: 0;
  bottom: -60px;
  box-shadow: 0 0 8px rgba($black, .25);
  color: $white;
  display: inline-block;
  font-size: 14px;
  padding: $padding-small $padding-vertical;
  position: relative;
  pointer-events: all;

  &:hover {
    background-color: $dark-error-color;
    color: $light-error-color;
    text-decoration: none;
  }
}

.nag-notification_link--is-caution {
  background-color: $bkg-caution-color;
  border-color: $dark-yellow;
  color: $black;

  &:hover {
    background-color: $dark-yellow;
    color: $bkg-caution-color;
   }
}

.nag-notification_link--is-bouncing {
  animation: bounce 3s infinite ease-in 2s;
}
