//Game Feed

.game-feed {
  color: $dark-gray;
  font-size: 11px;
  line-height: 1.25em;
  height: 100%;
}

.game-feed_list {
  background-color: $white;
  height: 100%;
}

.game-feed_item {
  background-color: $white;
  position: relative;
}

.game-feed_item--subheading {
  padding: $padding-horizontal $padding-small $padding-small;
  border-bottom: solid 1px $base-border-color;
}

.game-feed_subheading {
  color: $game-feed-link-active-color;
  font-size: 12px;
  line-height: 1em;
  font-weight: 300;
  margin: 0;
}

.game-feed_link {
  color: $dark-gray;
  display: block;
  padding: $padding-horizontal $padding-small;
  transition: background-color $base-duration ease-out, padding-top .3s linear, padding-bottom .3s linear;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: transparent;
  border-bottom-color: rgba($medium-gray, .65);
  border-top-width: 1px;
  border-bottom-width: 1px;
  width: 100%;

  .game-feed_item:only-child & {
    border-bottom: none;
  }

  @media screen and (max-width: $screen-md-max) {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  .btn {

    &:focus {
      background-color: $white;
      color: $action-color;
    }
  }
}

.game-feed_link--mock {
  color: $medium-gray;
  font-size: 14px;
  line-height: 1.25em;
}

.game-feed_link--is-collapsed {
  padding-top: $padding-small;
  padding-bottom: 0;
}

a.game-feed_link,
button.game-feed_link {

  &:hover {
    border-top-color: $copen-blue;
    border-bottom-color: $copen-blue;
    background-color: $lighter-copen-blue;
    text-decoration: none;
  }
}

.game-feed_link--is-active {
  background-color: $game-feed-link-active-color;
  border-top-color: $game-feed-link-active-color !important;
  border-bottom-color: $game-feed-link-active-color !important;

  @media screen and (max-width: $screen-md-max) {
    border-left-color: $medium-gray;
    border-right-color: $medium-gray;
  }

  .game-feed_title,
  .game-feed_dl,
  .game-feed_ticket-table,
  .dw-warning5,
  .dw-star_outline {
    color: $white;
  }

  .towel--small {
    outline: 1px solid rgba($white, .3) !important;
    height: 13px;
    width: 13px;
    margin: 2px;
    line-height: 1.4em;
  }

  &:hover,
  &:focus {
    background-color: $game-feed-link-active-color !important;
    text-decoration: none;
  }
}

.game-feed_details {
  max-height: 600px;
  transition: max-height .2s linear;
  padding-left: 13px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {//target IE10+
    overflow: hidden;
  }

  .game-feed_link--is-collapsed & {
    max-height: 0;
    overflow: hidden;
  }
}

.game-feed_title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.25em;
  margin-bottom: $padding-horizontal;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: $screen-lg-min) {
    margin-bottom: $padding-small;
  }

  .game-feed_link--is-active & {
    font-weight: 600;
  }

  .game-feed_item--has-indicator & {
    padding-left: 12px;
    position: relative;

    &::before {
      height: 9px;
      width: 9px;
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      content: '';
      border: 1px solid $light-gray;
      border-radius: 50%;
    }
  }

  .game-feed_item--has-notice & {

    &::before {
      border-color: $light-gray;
      background-color: $light-gray;
    }
  }

  .game-feed_item--has-caution & {

    &::before {
      border-color: $bkg-caution-color;
      background-color: $bkg-caution-color;
    }
  }

  .game-feed_item--has-warning & {

    &::before {
      border-color: $error-color;
      background-color: $error-color;
    }
  }

  .game-feed_item--has-success & {

    &::before {
      border-color: $success-color;
      background-color: $success-color;
    }
  }
}

.game-feed_meta {
  display: table;
  width: 100%;
  margin: $padding-small 0;

  > li {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
  }
}

.game-feed_dl {
  color: $dark-gray;
  font-size: 11px;
  margin: 0;

  dt {
    font-weight: 300;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    padding-right: 2px;
  }

  dd {
    font-weight: $heading-font-weight;
    display: inline-block;
    vertical-align: middle;
    padding-right: $padding-small;
  }
}

.game-feed_leader {

  @media screen and (min-width: $screen-lg-min) {
    display: inline-block;
    vertical-align: middle;
    max-width: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.game-feed_ticket-table {
  display: table;
  width: 100%;
  color: $dark-gray;

  & + & {
    border-top: 1px solid $base-border-color;
  }
}

.game-feed_ticket-table-row {
  display: table-row;

  > li {
    display: table-cell;
    vertical-align: middle;
    padding: 2px;
    line-height: 1em;

    &:nth-child(1) {
      width: 25px;

      @media screen and (min-width: $screen-lg-min) {
        width: 25px;
      }
    }

    &:nth-child(2) {
      width: 70px;

      @media screen and (min-width: $screen-lg-min) {
        width: 50px;
      }
    }

    &:nth-child(4) {
      width: 38px;
      text-align: right;
    }
  }

  .dw-warning5 {
    margin: 0 2px;
  }
}

.game-feed_ticket {
  font-size: 16px;
  color: $ticket-color;
  position: relative;

  &:after {
    display: block;
    content: attr(data-ticket-number);
    font-family: $heading-font-family;
    font-size: 10px;
    font-weight: 300;
    line-height: 1em;
    position: absolute;
    color: $white;
    bottom: 2px;
    width: 100%;
    text-align: center;
  }
}

.game-feed_ticket--is-winner {
  color: $success-color;
}

.game-feed_ticket--is-eliminated {
  color: $error-color;
}
