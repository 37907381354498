//Blurb

.blurb {
  text-align: center;
  padding: 0 $padding-vertical;

  @media screen and (max-width: $large-screen) {
    font-size: 14px;
    line-height: 1.25em;
    padding: 0;
  }

  [class^="dw-"] {
    // color: $light-gray;
    display: inline-block;
    font-size: 60px;
    margin-bottom: $padding-horizontal;
    opacity: .5;

    @media screen and (min-width: $large-screen) {
      font-size: 100px;
      margin-bottom: $padding-medium;
    }

    // .l-row--blue &,
    // .l-row--gray & {
    //   color: rgba($white, .5);
    // }

    // .l-row--light-gray & {
    //   color: $medium-gray;
    // }
  }
}

.blurb_heading {
  margin-bottom: $padding-small;
  font-size: 20px;

  @media screen and (min-width: $large-screen) {
    font-size: 28px;
  }
}
