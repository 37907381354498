// Horse Race

$scale: .35;
$z-cloud: 1;
$z-finish-line: 2;
$z-horse: 3;
$z-starging-gate: 10;

@keyframes startbkg {
  0% {
    background-position-x: -1px;
  }

  100% {
    background-position-x: -35px;
  }
}

.horse-race {
  background-image: url(../public/images/404/8-bit-bkg.svg);
  background-size: auto 100%;
  background-position: 0 -9px;
  background-repeat: repeat-x;
  height: 300px;
  position: relative;
  overflow: hidden;
  animation: startbkg .5s infinite linear;
}

@keyframes startgate {
  0% {
    left: 30%;
  }

  100% {
    left: -20%;
  }
}

.horse-race_starting-gate {
  position: absolute;
  left: 30%;
  top: 145px;
  transform: scale($scale);
  transform-origin: 0 0;
  z-index: $z-starging-gate;
  animation: startgate 10s linear forwards;
}

.horse-race_finish-line {
  position: absolute;
  right: 5px;
  top: 100px;
  transform: scale($scale);
  transform-origin: top right;
  z-index: $z-finish-line;
}

@keyframes goleft {
  0% {
    left: 100%;
  }

  100% {
    left: -20%;
  }
}

@keyframes startcloud1 {
  0% {
    left: 9%;
  }

  100% {
    left: -20%;
  }
}

@keyframes startcloud2 {
  0% {
    left: 35%;
  }

  100% {
    left: -20%;
  }
}

@keyframes startcloud3 {
  0% {
    left: 65%;
  }

  100% {
    left: -20%;
  }
}

@keyframes startcloud4 {
  0% {
    left: 110%;
  }

  100% {
    left: -20%;
  }
}

.horse-race_cloud-1 {
  position: absolute;
  left: 9%;
  top: 104px;
  transform: scale($scale);
  transform-origin: 0 0;
  z-index: $z-cloud;
  animation: startcloud1 24s linear forwards, goleft 34s 15s infinite linear;
}

.horse-race_cloud-2 {
  position: absolute;
  left: 35%;
  top: 84px;
  transform: scale($scale);
  transform-origin: 0 0;
  z-index: $z-cloud;
  animation: startcloud2 25s linear forwards, goleft 38s 22s infinite linear;
}

.horse-race_cloud-3 {
  position: absolute;
  left: 65%;
  top: 104px;
  transform: scale($scale);
  transform-origin: 0 0;
  z-index: $z-cloud;
  animation: startcloud3 50s linear forwards, goleft 55s 60s infinite linear;
}

.horse-race_cloud-4 {
  position: absolute;
  left: 110%;
  top: 80px;
  transform: scale($scale);
  transform-origin: 0 0;
  z-index: $z-cloud;
  animation: startcloud4 34s linear forwards, goleft 45s 30s infinite linear;
}

@keyframes horseracing {
  0% {
    left: -5px;
  }

  100% {
    left: 80%;
  }
}

@keyframes starthorseitem {
  0% {
    left: 28%;
  }

  50% {
    left: 60%;
  }

  100% {
    left: 28%;
  }
}

.horse-race_horse-item {
  height: 228px;
  width: 323px;
  overflow: hidden;
  position: absolute;
  left: 28%;
  top: 140px;
  transform: scale($scale);
  transform-origin: 0 0;
  z-index: $z-horse;
  animation: starthorseitem 18s ease-in infinite;
}

.horse-race_horse-item--1 {
  animation-duration: 30s;
  animation-delay: 0;

  .horse-race_horse-image {
    animation-delay: .5s;
  }
}

.horse-race_horse-item--2 {
  animation-duration: 50s;
  animation-delay: 6s;
  top: 150px;

  .horse-race_horse-image {
    animation-delay: .3s;
  }
}

.horse-race_horse-item--3 {
  animation-duration: 24s;
  animation-delay: .5s;
  top: 160px;

  .horse-race_horse-image {
    animation-delay: .2s;
  }
}

.horse-race_horse-item--4 {
  animation-duration: 60s;
  animation-delay: 0;
  top: 170px;

  .horse-race_horse-image {
    animation-delay: 0;
  }
}

.horse-race_horse-item--5 {
  animation-duration: 65s;
  animation-delay: 1s;
  top: 180px;

  .horse-race_horse-image {
    animation-delay: .1s;
  }
}

@keyframes horsesprite {
  0% {
    left: 0px;
  }

  100% {
    left: -1000px;
  }
}

.horse-race_horse-image {
  position: absolute;
  left: 0;
  height: 100%;
  width: auto;
  max-width: none;
  animation: horsesprite .5s steps(3) infinite;
}
