//Tabs

$tab-font-size: 16px !default;
$tab-font-family: $heading-font-family !default;
$tab-font-weight: $base-font-weight !default;

.tabs_wrapper {
  margin-top: -$padding-small;

  @media screen and (max-width: $screen-md-max) {
    position: relative;
    min-height: 42px;
    margin-top: 0;
  }
}

.tabs {
  font-family: $tab-font-family;
  text-transform: uppercase;
  font-size: $tab-font-size;
  font-weight: $tab-font-weight;
  position: relative;

  @media screen and (max-width: $screen-md-max) {
    background-color: $tab-active-color;
    border: 1px solid $transparent-gray;
    border-bottom: none;

    &::before {
      border-top: 8px solid $tab-font-color;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      content: '';
      height: 0; 
      right: 15px;
      position: absolute;
      top: 17px;
      width: 0;
      transition: transform $base-duration linear;
    }

    &.tabs--is-open {

      &::before {
        transform: rotate(180deg);
      }
    }
  }

  @media (min-width: $screen-tab-size-max) and (max-width: $screen-md-max) {
    max-width: $tab-width;
  }
}

.tabs--is-open {
  
  @media screen and (max-width: $screen-md-max) {
    position: absolute;
    z-index: 1;
    box-shadow: 0 2px 10px rgba($black, .8);
    width: 100%;
  }
}

.tabs_tab {
  display: inline-block;
  margin-right: 2px;
  vertical-align: bottom;

  @media screen and (max-width: $screen-md-max) {
    display: none;
    margin-right: 0;

    .tabs--is-open &:not(.tabs_tab--is-active) {
      display: block;
      animation: appear $base-duration linear forwards;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.tabs_tab--menu-label {
  display: block;

  @media screen and (min-width: $screen-lg-min) {
    display: none;
  }
}

.tabs_link {
  background: $tab-bkg-color;
  color: $tab-font-color;
  display: block;
  line-height: .85em;
  padding: 17px 35px $padding-horizontal $padding-horizontal;
  position: relative;
  transition: all $base-duration $base-timing;
  border-bottom: 1px solid $transparent-gray;

  &:hover {
    color: $tab-font-color;
    text-decoration: none;
  }

  @media screen and (min-width: $screen-lg-min) {
    padding-top: 15px;
    padding-right: $padding-horizontal;
    border: none;

    &:hover {
      background-color: $tab-hover-color;
      line-height: 1em;
      padding-top: 17px;
    }
  }

  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    padding-left: 7px;
    padding-right: 7px;
  }

  .tabs_tab--menu-label & {
    background-color: transparent;
    color: $tab-font-color;
  }

  .tabs_tab--is-active & {
    display: none;

    @media screen and (min-width: $screen-lg-min) {
      color: $tab-font-color;
      background-color: $tab-active-color;
      display: inline-block;
      padding-top: 17px;
      line-height: 1em;
      z-index: 1;

      &:hover {
        background-color: $tab-active-color;
        cursor: default;
      }
    }
  }
}
