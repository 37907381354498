//Popup

.popup {
  position: relative;

  & + & {
    margin-left: 2px;
  }

  @media screen and (min-width: $screen-desktop-min) {

    &:hover {

      .popup_content {
        display: block;
        animation: showpopup .2s ease-out .5s forwards;
      }
    }
  }
}

.popup--immediate {

  @media screen and (min-width: $screen-desktop-min) {

    &:hover {

      .popup_content {
        animation-delay: 0s;
      }
    }
  }
}

.popup_content {
  display: none;
  background-color: $white;
  border-radius: $base-border-radius;
  border: 1px solid $medium-gray;
  box-shadow: 0 0 10px rgba($black, .4);
  position: absolute;
  left: calc(50% - 130px);
  z-index: $tooltip-z-index;
  text-align: left;
  width: 260px;
  opacity: 0;
  transform: scale(.1, .1);
  font-size: 12px;

  .popup--is-right & {
    left: 0;
  }

  .popup--medium & {
    width: 200px;
    left: calc(50% - 100px);
  }

  .popup--is-100 & {
    width: 100%;
    left: 0;
  }

  &::before {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid $medium-gray;
    content: '';
    display: block;
    left: calc(50% - 12px);
    bottom: -12px;
    position: absolute;

    .popup--is-right & {
      left: 2px;
    }
  }

  &::after {
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 13px solid $white;
    content: '';
    display: block;
    left: calc(50% - 13px);
    bottom: -10px;
    position: absolute;

    .popup--is-right & {
      left: 1px;
    }
  }

  h3 {
    margin-bottom: 0;
  }

  p {
    font-weight: $base-font-weight;
  }
}

.popup_title {
  background-color: $lighter-copen-blue;
  border-bottom: 1px solid $light-gray;
  padding: 9px $padding-horizontal 2px;
  border-top-left-radius: $base-border-radius;
  border-top-right-radius: $base-border-radius;
  font-size: 14px;
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  word-break: break-word;
}

.popup_body {
  padding: $padding-horizontal;

  table {
    margin-bottom: $padding-horizontal;
    font-size: 11px;

    th {
      font-weight: 100;
    }

    td {
      font-weight: 600;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  thead {
    font-size: 9px;
  }
}

.popup_username {
  
  span {

    .batch-g & {

      &:nth-child(1) {
        display: inline-block;
        vertical-align: middle;
        max-width: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:nth-child(2) {
      padding-left: 2px;
      vertical-align: middle;
    }
  }
}
