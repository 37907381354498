//PoP (Point of Purchase)

.pop {
  border: 1px solid $transparent-gray;
  outline: 1px solid transparent;
  max-width: 450px;
  margin-bottom: $padding-vertical;
  position: relative;

    @media screen and (max-width: $screen-xs-min) {
      padding-top: 54px;
    }

  .tournament-table_meta_cell--pop & {

    @media screen and (max-width: $screen-xs-max) {
      position: absolute;
      top: 15px;
      left: 0;
      width: 100%;
    }

    @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
      width: 420px;
      left: calc(50% - 210px);
    }
  }
}

.pop--success {
  animation: fadeborderthrulime 2s ease-out forwards;
}

.pop_table {
  display: table;
  width: 100%;
}

.pop_cell {
  display: table-cell;
  padding: $padding-small;
  vertical-align: top;
  text-align: center;
  line-height: 1em;
  height: 110px;
}

.pop_cell--your-entries {
  
  @media screen and (max-width: $screen-xs-min) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $light-gray;
    height: auto;

    .pop_item {

      dt,
      dd {
        display: inline-block;
        vertical-align: top;
      }

      dt {
        padding-top: 16px;
        padding-right: $padding-small;
      }
    }
  }

  @media screen and (min-width: $screen-xs-min) {
    width: 80px;
  }
}

.pop_cell--entries {
  width: 40%;

  @media screen and (min-width: $screen-xs-min) {
    width: 135px;
  }
}

.pop_cell--cta {
  vertical-align: middle;

  .btn {

    @media screen and (max-width: $screen-iphone5-max) {
      white-space: normal;
    }
  }
}

.pop_item {
  margin: 0;
  text-align: center;
  height: 100%;

  dt {
    color: $dark-gray;
    font-size: 12px;
    font-weight: 300;    margin: 0;
    padding: $padding-horizontal 0;

    .product_info &,
    .tournament-table--game-page-heading .tournament-table_row--details & {
      color: $white;
      opacity: .75;
    }
  }
}

.pop_total {
  display: table;
  margin: 0 auto;

  dt {
    color: $dark-gray;
    display: table-cell;
    font-size: 11px;
    font-weight: 300;
    margin: 0;
    padding: $padding-small 0;
    text-align: right;

    .product_info &,
    .tournament-table--game-page-heading .tournament-table_row--details & {
      color: $white;
      opacity: .75;
    }
  }

  dd {
    display: table-cell;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: $padding-small 0 $padding-small $padding-small;
    text-align: left;
  }
}

.pop_total--large {

  dt {
    color: $dark-gray;
    font-size: 14px;
    vertical-align: middle;

    .product &,
    .tournament-table--game-page-heading .tournament-table_row--details & {
      color: $white;
    }
  }
}

.pop_sub {
  color: $medium-gray;
  font-size: 14px;
  font-weight: 300;

  .product_info &,
  .tournament-table--game-page-heading .tournament-table_row--details & {
    color: $white;
    opacity: .75;
  }
}

.pop_label {
  display: inline-block;
  font-size: 28px;
  font-weight: 700;
  line-height: 1em;
  padding-top: $padding-horizontal;

  .pop--success & {
    animation: fadethrulime 2s ease-out forwards;
  }

  .product_info .pop--success & {
    animation: fadethrulimetowhite 2s ease-out forwards;
  }
}

.pop_entries {
  max-width: 112px;
  margin: 0 auto;

  .btn_group {
    margin-bottom: $padding-small;

    &:only-child {
      margin-top: $padding-horizontal;
    }
  }

  .btn--menu {
    width: 100%;

    &:only-child {
      margin-top: $padding-horizontal;
    }
  }
}

.pop_entry-unit {

  &:after {
    content: '$';
  }

  &.pop_entry-unit--points,
  &.pop_entry-unit--tokens {
      display: inline-block;
      position: relative;
      width: 1em;
      margin-right: 2px;

    &:after {
      bottom: -2px;
      font-weight: normal;
      font-family: 'derbicons';
      left: 0;
      line-height: 1;
      position: absolute;
    }
  }

  &.pop_entry-unit--points {

    &:after {
      content: '\e94f';
    }
  }

  &.pop_entry-unit--tokens {

    &:after {
      content: '\e932';
    }
  }
}
