//Lists

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ol {
  margin: 0;
  padding: 0 0 0 17px;

  li {
    margin-bottom: $padding-small;
  }
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: 600;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}

.list--is-formatted {
  margin-bottom: $padding-large;

  > li {
    padding: $padding-vertical $padding-horizontal;
  }
}

.list--has-vpadding {

  > li {
    padding-bottom: 2px;
    padding-top: 2px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.list--has-disc {
  padding-left: $padding-vertical;

  > li {
    list-style: disc;
    margin-bottom: $padding-horizontal;
  }
}

.list--is-ruled {

  > li {
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
    }
  }
}

.list--is-zebra {

  > li {
    background-color: $lighter-gray;

    &:nth-child(odd) {
      background-color: $white;
    }
  }
}
