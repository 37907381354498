//Badge

.badge {
  background-color: $light-gray;
  border: 1px solid $light-gray;
  border-radius: 12px;
  color: $dark-gray;
  display: inline-block;
  font-family: $base-font-family;
  font-size: 10px;
  font-weight: normal;
  line-height: 13px;
  margin-right: 4px;
  padding: 4px 6px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  min-width: 17px;

  &:only-of-type,
  &:last-child {
    margin-right: 0;
  }

  a {
    color: $dark-gray;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: rgba($dark-gray, .5);
      text-decoration: underline;
    }
  }

  [class^="dw-"] {

    & + div {
      display: inline-block;
      margin-left: 2px;
      vertical-align: bottom;
    }
  }
}

.badge--large {
  border-radius: 25px;
  font-size: 24px;
  line-height: .5em;
  padding: $padding-horizontal;

  [class^="dw-"] {

    & + div {
      vertical-align: middle;
    }
  }
}

.badge--icon {
  padding: 2px;
  height: 19px;
  width: 19px;

  .tournament-table_mobile-label &,
  .game-summary_label--stacked & {

    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 4px;
    }
  }

  &:not(.badge--ghost) {
    color: $white;
  }
}

.badge--number {
  padding: 1px 5px;

  .btn--small & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.badge--medium {
  font-size: 12px;
  padding: 4px 8px;
}

.badge--short {
  padding: 0 4px;
}

.badge--ghost {
  background-color: transparent;
  border-color: $light-gray;

  a {
    color: $dark-gray;

    &:hover,
    &:focus {
      color: $black;
    }
  }
}

.badge--block {
  display: block;
  margin-bottom: $padding-horizontal;
}

@mixin badge-variant($modifier, $background, $is-light: '') {

  $light-label-color: darken($background, 30%);
  
  $label-color: $white;
  $ghost-label-color: $background;
  @if $is-light == 'true' {
    $label-color: $light-label-color;
    $ghost-label-color: $light-label-color;
  }

  .badge--#{$modifier} {
    background-color: $background;
    border-color: $background;
    color: $label-color;

    a {
      color: inherit;

      &:hover,
      &:focus {
        color: rgba($label-color, .75);
      }
    }

    &.badge--ghost {
      background-color: transparent;
      color: $ghost-label-color;

      a {
        color: inherit;

        &:hover,
        &:focus {
          color: darken($ghost-label-color, 10%);
        }
      }
    }
  }
}

@include badge-variant('yellow', $yellow, 'true');

@include badge-variant('navy', $navy);

@include badge-variant('lime', $success-color);

@include badge-variant('dark-lime', $dark-success-color);

@include badge-variant('red', $red);

@include badge-variant('copen-blue', $copen-blue);

@include badge-variant('orange', $orange);

@include badge-variant('blue', $blue);

@include badge-variant('purple', $purple);

@include badge-variant('medium-gray', $medium-gray);

@include badge-variant('brown', $brown);

@include badge-variant('black', $black);

@include badge-variant('white', $white, 'true');

@include badge-variant('maroon', $maroon);

.badge--white {

  &.badge--ghost {
    color: $white;
  }

  .tournament-table--game-page-heading .tournament-table_row--details & {
    color: $white;
    border-color: $white;
  }
}

.badge--black {

  .tournament-table--game-page-heading .tournament-table_row--details & {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }
}
