//Leaderboard Grid

.leaderboard-grid {
  padding: $padding-medium 0;

  @media screen and (min-width: $screen-sm-min) {
    padding-top: $padding-xlarge;
    padding-bottom: $padding-xlarge;
  }

  & + .l-footer {
    display: none;
  }
}

.leaderboard-grid--is-basic {
  padding: $padding-horizontal 0;
}

.leaderboard-grid_heading {
  text-transform: uppercase;
  font-size: 42px;
  line-height: 1em;
  margin-bottom: $padding-horizontal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .leaderboard-grid--is-basic & {
    // white-space: normal;
    // overflow: visible;
    font-size: 68px;
    // line-height: 1.25em;
    padding: 2px 0;
    text-align: center;
    margin: 0;

    &:first-child {
      margin-top: $padding-vertical;
    }
  }
}

.leaderboard-grid_logo {
  display: block;
}

.leaderboard-grid_sub {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: $padding-small;

  .leaderboard-grid--is-basic & {
    font-size: 44px;
    line-height: 1.75em;
    text-align: center;
    margin-top: $padding-horizontal;
    border-top: 2px solid $white;
  }
}

.leaderboard-grid_item {
  margin-bottom: 30px;

  .leaderboard-grid--is-basic & {
    margin-bottom: 0;
  }
}

.leaderboard-grid_table {
  background-color: $white;
  color: $dark-gray;
  margin-bottom: 0;
  table-layout: auto;
  height: 84px;

  .leaderboard-grid--is-basic & {
    background-color: transparent !important;
    color: $white;
  }

  .leaderboard-grid_item--is-winner & {
    background-color: $light-success-color;
  }
}

.leaderboard-grid_cell {
  border-left: 2px solid $light-gray;
  border-bottom: $padding-horizontal solid $white;
  border-top: $padding-horizontal solid $white;
  padding-top: 0;
  padding-bottom: 0;

  .leaderboard-grid--is-basic & {
    border-color: transparent !important;
  }

  .leaderboard-grid_item--is-winner & {
    border-bottom-color: $light-success-color;
    border-top-color: $light-success-color;
  }
}

.leaderboard-grid_cell--rank {
  width: 65px;
  border-left: 0;

  .leaderboard-grid--is-basic & {
    width: 130px;
    color: $white;
  }
}

.leaderboard-grid_cell--avatar {
  width: 64px;

  .leaderboard-grid--is-basic & {
  //   display: none;
    width: 80px;
  }

  .avatar {
    margin: 0 auto;
    border-color: $gray;

    .leaderboard-grid--is-basic & {
      border-color: $white;
      outline: 2px $white solid;
    }
  }
}

.leaderboard-grid_cell--username {
  padding-right: $padding-small;
  border-left: 0;

  // .leaderboard-grid--is-basic & {
  //   border-left: 2px solid $light-gray;
  //   padding-left: $padding-horizontal;
  // }
}

.leaderboard-grid_cell--pick {
  padding-left: $padding-small;
  padding-right: $padding-small;
  text-align: center;
  font-size: 14px;
  line-height: 1em;
  width: 64px;
  display: none;

  div {
    margin-bottom: 2px;
  }
}

.leaderboard-grid_cell--score {
  padding-left: $padding-horizontal;
  padding-right: $padding-small-medium;
  width: 120px;

  .leaderboard-grid--is-basic & {
    width: 250px;
  }
}

.leaderboard-grid_rank {
  text-align: center;
  color: $navy;
  font-size: 30px;
  line-height: 1em;
  padding-top: 8px;

  .leaderboard-grid--is-basic & {
    font-size: 64px;
    color: $white;
  }

  .leaderboard-grid--is-basic .leaderboard-grid_item--is-winner & {
    color: lighten($success-color, 15%);
  }

  .leaderboard-grid_item--is-winner & {
    color: $forest-green;
  }
}

.leaderboard-grid_username {
  font-size: 30px;
  line-height: 1em;
  // padding-top: 8px;

  .leaderboard-grid--is-basic & {
    font-size: 64px;
  }

  .leaderboard-grid--is-basic .leaderboard-grid_item--is-winner & {
    color: lighten($success-color, 15%);
  }

  span {
    display: block;
    vertical-align: middle;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .leaderboard-grid--is-basic & {
      max-width: 590px;
    }

    &:first-child {
      padding-top: 8px;
    }
  }
}

.leaderboard-grid_content {
  text-align: center;
  padding: $padding-small;
}

.leaderboard-grid_score {
  font-size: 30px;
  line-height: 1em;
  font-weight: 700;
  text-align: right;

  .leaderboard-grid--is-basic & {
    font-size: 70px;
  }

  .leaderboard-grid--is-basic .leaderboard-grid_item--is-winner & {
    color: lighten($success-color, 15%);
  }

  .leaderboard-grid_item--is-winner & {
    color: $forest-green;
  }
}
