//Buttons

//Reset browser default <button> styles

button {
  color: inherit;
  appearance: none;
  display: inline;
  padding: 0;
  background: transparent;
  line-height: $base-line-height;
  border: none;
  text-align: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.btn {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  background-color: $white;
  border-radius: $base-border-radius;
  border: 1px solid $action-color;
  color: $action-color;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: 1;
  outline: none;
  padding: $padding-horizontal $padding-vertical;
  text-align: center;
  text-decoration: none;
  transition: all $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  & + .btn {
    margin-left: $padding-small;
  }

  &:hover,
  &:focus,
  &.btn--is-selected {
    background-color: $action-color;
    color: $white;
    text-decoration: none;

    > .btn_note_inline,
    > .btn_label > .btn_note_inline {
      opacity: 1;
    }

    .btn_state--current {
      display: none;
    }

    .btn_state--toggle {
      display: inline-block;
    }

    .badge {
      color: $white;
      border-color: $white;
    }
  }

  &:disabled,
  &.btn--is-disabled {
    border-color: $medium-gray;
    color: $medium-gray;
    cursor: not-allowed;
    opacity: 0.5;

    &:hover,
    &:focus {
      background-color: $white;
      border-color: $medium-gray;
      color: $medium-gray;
    }

    &.btn--is-selected {
      background-color: $medium-gray;
      color: $white;
    }
  }

  @mixin btn-loading-props {
    border-color: $copen-blue !important;
    color: $copen-blue !important;
    opacity: 1;
    background-image: 
      repeating-linear-gradient(
        45deg,
        rgba($copen-blue, .15),
        rgba($copen-blue, .15) 15px,
        $white 0,
        $white 30px /* determines size */
      ) !important;
    background-color: $white !important;
    background-size: 600px;
  };

  &.btn--is-loading {
    @include btn-loading-props();
    animation: loadingbtnbkg .5s infinite linear, loadingpulse 1.5s infinite linear;

      &:hover,
      &:focus,
      &.btn--is-selected {
        @include btn-loading-props();
        cursor: wait;
      }
  }
}

.btn--no-focus {

  &:focus {
    background-color: $white;
  }

  &.btn--clean {

    &:focus {
      border-color: $light-gray;
      color: $medium-gray;
    }
  }
}

.btn--is-block {
  display: block;
  width: 100%;
}

.btn--mock {
  cursor: default;

  &:hover,
  &:focus {
    background-color: $white;
    color: $action-color;
  }
}

.btn--ghost {
  background-color: rgba($black, 0.25);
  border-color: $base-font-color;
  color: $base-font-color;

  &.btn--large {
    border-width: 2px;
  }

  &:hover,
  &:focus,
  &.btn--is-selected {
    border-color: $action-color;
  }

  &:disabled,
  &.btn--is-disabled {

    &:hover,
    &:focus,
    &.btn--is-selected {
      background-color: rgba($black, 0.5);
    }
  }
}

.btn--primary {
  background-color: $action-color;
  border: 1px solid $action-color;
  color: $white;

  &:hover,
  &:focus,
  &.btn--is-selected {
    background-color: $dark-action-color;
    border-color: $dark-action-color;
  }

  &:disabled,
  &.btn--is-disabled {
    border-color: $action-color;
    color: $white;

    &:hover {
      background-color: $action-color;
      border-color: $action-color;
      color: $white;
    }
  }
}

.btn--clean {
  border-color: $light-gray;
  color: $dark-gray;

  &:hover {
    border-color: $action-color;
  }
}

.btn--transparent {
  background-color: transparent;

  &:hover,
  &:focus {
    color: $action-color;
    background-color: transparent;
    text-decoration: underline;
  }
}

.btn--transparent {
  background-color: transparent;
}

.btn--large {
  border-width: 2px;
  font-size: 24px;
  text-transform: uppercase;
  white-space: normal;

  @media screen and (min-width: $screen-sm-min) {
    font-size: 30px;
    padding: $padding-medium $padding-large;
    white-space: nowrap;
  }
}

.btn--small {
  font-size: 12px;
  padding: $padding-small $padding-horizontal;
}

.btn--tiny {
  font-size: 10px;
  padding: 4px 5px;
  line-height: .75em;
}

.btn--short {
  padding-top: 0;
  padding-bottom: 0;
}

.btn--emphasis {
  font-weight: 700;
  text-transform: uppercase;
}

.btn--has-notice {
  animation: btnpulsecopenblue 3s linear infinite;

  &:hover {
    animation: none;
  }
}

.btn--has-notification {
  position: relative;

  &::after {
    background-color: $white;
    border-radius: 10px;
    border: 1px solid $action-color;
    box-shadow: -1px 1px 2px rgba($black, .2);
    color: $action-color;
    content: attr(data-amount);
    display: block;
    font-size: 11px;
    height: 15px;
    line-height: 1.3em;
    min-width: 15px;
    padding: 0 3px;
    position: absolute;
    right: -6px;
    top: -6px;
  }

  &.btn--large {

    &::after {
      border-width: 2px;
      font-size: 16px;
      height: 23px;
      line-height: 1.3em;
      min-width: 23px;
    }
  }
}

.btn--has-caution-nag {
  position: relative;

  &::before {
    background-color: $bkg-caution-color;
    border-radius: 10px;
    border: 1px solid $caution-color;
    box-shadow: -1px 1px 2px rgba($black, .2);
    color: $black;
    content: attr(data-caution-nag-amount);
    display: block;
    font-size: 11px;
    height: 15px;
    line-height: 1.3em;
    min-width: 15px;
    padding: 0 3px;
    position: absolute;
    right: -6px;
    top: -6px;
    transition: right .3s ease-out;
    z-index: 1;
  }

  &.btn--large {

    &::after {
      border-width: 2px;
      font-size: 16px;
      height: 23px;
      line-height: 1.3em;
      min-width: 23px;
    }
  }

  &.btn--has-notice {
    animation: btnpulsecautioncolor 3s linear infinite;
  }

  &:hover {
    animation: none;
  }
}

.btn--has-error-nag {
  position: relative;

  &::after {
    background-color: $error-color;
    border-radius: 10px;
    border: 1px solid $dark-error-color;
    box-shadow: -1px 1px 2px rgba($black, .2);
    color: $white;
    content: attr(data-error-nag-amount);
    display: block;
    font-size: 11px;
    height: 15px;
    line-height: 1.3em;
    min-width: 15px;
    padding: 0 3px;
    position: absolute;
    right: -6px;
    top: -6px;
  }

  &::before {
    right: 15px;
  }

  &.btn--large {

    &::after {
      border-width: 2px;
      font-size: 16px;
      height: 23px;
      line-height: 1.3em;
      min-width: 23px;
    }
  }

  &.btn--has-notice {
    animation: btnpulseerrorcolor 3s linear infinite;
  }

  &:hover {
    animation: none;
  }
}

.btn--naked {
  border-color: transparent;
  background-color: transparent;
  color: $action-color;

  &:hover,
  &:focus,
  &.btn--is-selected {
    background-color: transparent;
    border-color: transparent;
    color: $dark-action-color;
  }

  &:disabled,
  &.btn--is-disabled {
    border-color: transparent;

    &:hover,
    &:focus,
    &.btn--is-selected {
      border-color: transparent;
    }
  }
}

.btn--load-more {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 0;
}

.btn--menu {
  padding-right: 25px;
  position: relative;
  max-height: 37px;

  &::before {
    border-top: 4px solid $action-color;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    content: '';
    height: 0; 
    right: 8px;
    position: absolute;
    top: calc(50% - 2px);
    width: 0;
  }

  &.btn--is-disabled {

    &::before {
      border-top-color: $medium-gray;
    }

    &:hover {

      &:before {
        border-top-color: $medium-gray;
      }
    }
  }

  &.btn--ghost {

    &::before {
      border-top-color: $white;
    }

    &:hover {
      background-color: $white;
      color: $action-color;

      &:before {
        border-top-color: $action-color;
      }
    }
  }

  &.btn--primary {

    &::before {
      border-top-color: $white;
    }
  }

  &.btn--clean {

    &::before {
      border-top-color: $dark-gray;
    }

    &:hover {
      border-color: $action-color;
    }
  }

  &.btn--is-block {
    text-align: left;
    padding-left: $padding-horizontal;
  }

  &:hover {

    &:before {
      border-top-color: $white;
    }
  }

  .form--is-vertical & {
    margin-bottom: $padding-vertical;
  }
}

.btn--menu--is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &::before {
    transform: rotate(180deg);
  }
}

.btn_list {
  background-color: $white;
  border: 1px solid $action-color;
  border-bottom-left-radius: $base-border-radius;
  border-bottom-right-radius: $base-border-radius;
  box-shadow: 0 3px 3px rgba($black, .2);
  display: none;
  left: -1px;
  min-width: calc(100% + 2px);
  position: absolute;
  top: 100%;
  z-index: 2;

  .btn--menu--is-open & {
    display: block;
  }

  .btn--menu--is-short & {
    max-height: 151px;
    overflow: auto;
  }
}

.btn_item {
  border-bottom: 1px solid $light-gray;

  &:last-child {
    border-bottom: 0;
  }
}

.btn_link {
  color: $dark-gray;
  display: block;
  padding: $padding-small $padding-horizontal $padding-small $padding-vertical;
  text-align: left;

  .btn--small & {
    padding-left: 15px;
  }

  &:hover {
    background-color: $action-color;
    color: $white;
    text-decoration: none;

    .btn_note_inline {
      opacity: 1;
    }
  }
}

.btn_link--is-selected {
  position: relative;
  font-weight: 700;

  &:before {
    position: absolute;
    content: '✓';
    left: 3px;
  }
}

.btn_note {
  display: block;
  font-size: .4em;
  padding-top: $padding-small;
  text-transform: capitalize;
}

.btn_icon {
  display: inline-block;
  font-size: 1.25em;
  margin-right: 2px;
  transition: transform .2s linear;

  .tournament-table--is-open &,
  .product_info--is-open & {
    transform: rotate(46deg);
  }
}

.btn_note_inline {
  display: inline-block;
  font-size: .8em;
  opacity: .6;
}

.btn_group {
  display: table;
  width: 100%;
}

.btn_group--sleek {

  .btn--small {

    @media screen and (min-width: $screen-lg-min) {
      padding: 4px 6px;
    }
  }
}

.btn_group--stacked-mobile {

  @media screen and (max-width: $screen-md-max) {
    display: block;

    .btn {
      padding: $padding-small; 
    }

    .btn_group_item {
      display: block;

      &:first-child {

        .btn {
          border-right-width: 1px;
          border-right-style: solid;
          border-bottom-left-radius: 0;
          border-top-right-radius: $base-border-radius;
        }
      }

      &:last-child {

        .btn {
          border-top-width: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: $base-border-radius;
          border-left-width: 1px;
          border-left-style: solid;
        }

        &:nth-child(n+3) {

          .btn {
            border-left-width: 1px;
            border-left-style: solid;
          }
        }
      }

      &:not(:first-child):not(:last-child) {

        .btn {
          border-bottom-width: 1px;
          border-top-width: 0;
          border-left-width: 1px;
        }
      }
    }
  }
}

.btn_group_item {
  display: table-cell;

  &:first-child {

    .btn {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:last-child {

    .btn {
      border-left-width: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &:nth-child(n+3) {

      .btn {
        border-left: 0;
      }
    }
  }

  &:not(:first-child):not(:last-child) {

    .btn {
      border-left-width: 0;
      border-radius: 0;
    }
  }

  &:only-child {

    .btn {
      border-radius: $base-border-radius;
      max-width: 45px;
    }
  }

  .btn {
    margin-left: 0;
    width: 100%;

    &.btn--is-selected {
      box-shadow: 0 0 8px $dark-action-color inset;

      &:disabled,
      &.btn--is-disabled {
        box-shadow: 0 0 8px $dark-gray inset;
        background-color: $medium-gray;
        border-color: $light-gray;
        color: $white;
      }
    }
  }
}

.btn_state--toggle {
  display: none;
}

.btn--error {//TODO create out success, warning, error button classes
  color: $error-color;
}
