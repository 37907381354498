//Forms

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 300;
  margin-bottom: calc($small-spacing / 2);
  opacity: .75;
  padding: 0;
}

label {
  display: block;
  font-weight: 300;
  font-size: 14px;
  line-height: 1em;
  margin-bottom: calc($small-spacing / 2);
  opacity: .75;

  .lightbox & {
    color: $dark-gray;
  }

  .form--is-horizontal & {

    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: -$padding-small;
    }
    
    @media screen and (min-width: $screen-sm-min) {
      text-align: right;
    }
  }

  &.btn {
    opacity: 1;
  }
}

label.label--inline {
  opacity: 1;
  // font-size: 16px;
  line-height: 1.25em;
  margin-bottom: $padding-vertical;
  padding-left: $padding-vertical;
  cursor: pointer;
  text-align: left;

  &:only-child {
    margin-bottom: 0;
  }

  [type="checkbox"] {
    margin-left: -20px;
  }
}

.label--radio {
  display: inline-block;
  margin-right: $padding-vertical;
  margin-bottom: $padding-horizontal;
  opacity: 1;

  &:last-child {
    margin-right: 0;
  }
}

input,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;

  .form--is-vertical &:not([type="checkbox"]):not([type="radio"]) {
    margin-bottom: $padding-vertical;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@mixin forminput-notification-maker($background-color, $message-color) {
  background-color: $background-color;
  border-color: $message-color;
  box-shadow: 0 0 2px 1px $white;

  &::placeholder {
    color: $message-color;
  }

  select {
    color: $message-color;
  }
}

.form--is-horizontal {

  > .batch-g {

    > [class^="batch-c"] {

      @media screen and (max-width: $screen-xs-max) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.form_input {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: 1;
  padding: 8px $padding-small;
  transition: border-color $base-duration $base-timing;
  width: 100%;
  color: $dark-gray;

  &.form_input-notification {
    border-width: 1px;
    border-style: solid;
    box-shadow: 0 0 2px 1px $white;
    @include forminput-notification-maker($light-notice-color, $copen-blue);
  }

  &.form_input-notification--has-caution {
    @include forminput-notification-maker($light-caution-color, $caution-color);
  }

  &.form_input-notification--has-error {
    @include forminput-notification-maker($light-error-color, $error-color);
  }

  &.form_input-notification--has-success {
    @include forminput-notification-maker($light-success-color, $success-color);
  }

  .form--is-vertical & + .form_message {
    margin-top: -$padding-vertical;
    margin-bottom: $padding-horizontal;
  }

  .form--is-vertical &.form_input--select + .form_message {
    margin-top: 0;
  }

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  .form--is-horizontal & {

    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: $padding-horizontal;
    }
  }
}

.form_input-prefix {
  position: relative;
  line-height: 1em;

  &::before {
    color: $dark-gray;
    display: block;
    content: attr(data-prefix);
    position: absolute;
    left: $padding-small;
    top: 11px;
  }

  .form_input {
    padding-left: $padding-small-medium;
  }
}

.form_input-notification {
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 2px 1px $white;
  @include forminput-notification-maker($light-notice-color, $copen-blue);

  .btn_label {
    color: $copen-blue;
  }

  &.form_input--select {
    color: $copen-blue;

    &::before {
      border-top-color: $copen-blue;
    }
  }

  &:hover {

    .btn_label {
      color: $white;
    }
  }
}

.form_input-notification--has-caution {
  @include forminput-notification-maker($light-caution-color, $caution-color);

  .btn_label {
    color: $caution-color;
  }

  &.form_input--select {
    color: $caution-color;

    &::before {
      border-top-color: $caution-color;
    }
  }
}

.form_input-notification--has-error {
  @include forminput-notification-maker($light-error-color, $error-color);

  .btn_label {
    color: $error-color;
  }

  &.form_input--select {
    color: $error-color;

    &::before {
      border-top-color: $error-color;
    }
  }
}

.form_input-notification--has-success {
  @include forminput-notification-maker($light-success-color, $success-color);

  .btn_label {
    color: $success-color;
  }

  &.form_input--select {
    color: $success-color;

    &::before {
      border-top-color: $success-color;
    }
  }
}

textarea {
  resize: vertical;
}

[type="search"] {
  appearance: none;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: calc($small-spacing / 2);
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

.form_input--select {
  padding: 0;
  height: 37px;
  transition: all $base-duration $base-timing;
  position: relative;
  display: inline-block;
  width: auto;
  text-align: left;

  &::before {
    border-top: 4px solid $dark-gray;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    content: '';
    height: 0; 
    right: 8px;
    position: absolute;
    top: calc(50% - 2px);
    width: 0;
  }

  &:hover {
    border-color: $action-color;
    background-color: $action-color;
    color: $white;

    &::before {
      border-top-color: $white;
    }

    select {
      color: $white;
    }
    option {
      color: $dark-gray;
    }
  }

  & + .form_input--select {
    margin-left: $padding-small;
  }

  .form--is-vertical & {
    margin-bottom: $padding-vertical;
  }
}

.form_input--is-block {
  display: block;
  text-align: center;
}

select {
  color: $dark-gray;
  border-radius: 0;
  padding: 0 25px 0 $padding-small;
  appearance: none;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: 1;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 35px;

  &::-ms-expand {
      display: none;
  }

  &:focus {
    outline: 2px solid $action-color;
  }
}

.form_action {
  padding-bottom: $padding-vertical;
  padding-top: $padding-vertical;

  .form--is-horizontal & {

    @media screen and (max-width: $screen-xs-max) {
      padding-top: 0;
    }
  }
}

.form_note {
  display: inline-block;
  padding: 0 $padding-horizontal;

  @media screen and (max-width: $screen-xs-max) {

    label {
      font-size: 12px;
    }
  }
}

.form_message {
  font-size: 14px;
  color: $copen-blue;
  padding: 2px $padding-small;

  // form & {
  //   margin-top: -$padding-horizontal;
  // }

  .form_action & {
    margin-top: 0;
  }
}

.form_message--has-caution {
  color: $bkg-caution-color;

  .l-row--white &,
  .l-row--light-gray & {
    color: $caution-color;
  }
}

.form_message--has-error {
  color: lighten($error-color, 30%);;

  .l-row--white &,
  .l-row--light-gray & {
    color: $error-color;
  }
}

.form_message--has-success {
  color: lighten($success-color, 20%);

  .l-row--white &,
  .l-row--light-gray & {
    color: $success-color;
  }
}
