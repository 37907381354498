//Collapsable

.collapsable {
  max-height: 0;
  overflow: hidden;
  transition: all .3s ease-out;
}

.collapsable--is-open {
  max-height: 20000px;
}
