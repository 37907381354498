//Survivor Stats

.survivor-stats {
  text-align: left;
  max-width: 196px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 1em;

  .accordion & {
    margin-top: $padding-horizontal;
    max-width: none;
  }

  .leaderboard_item & {
    // margin-top: $padding-horizontal;
    max-width: none;
  }
}

.survivor-stats--inline {
  display: inline-block;
  width: 100%;
  margin: 0;

  @media screen and (min-width: $screen-sm-min) {
    width: 190px;
  }
}

.survivor-stats_markers {
   position: relative;
   height: 14px;
}

.survivor-stats_mark {
  position: absolute;
  color: $ticket-color;
  transform: rotate(-45deg) translate(2px, 4px);
  transform-origin: (bottom left);

  &:after {
    display: block;
    content: attr(data-ticket-number);
    font-size: 8px;
    font-weight: 400;
    line-height: 1em;
    position: absolute;
    color: $white;
    bottom: 1px;
    width: 100%;
    text-align: center;
  }
}

.survivor-stats_mark--eliminated {
  color: $error-color;
}

.survivor-stats_mark--winner {
  color: $success-color;
}

.survivor-stats_graph {
  margin: 0;
  background-color: rgba($base-border-color, .6);
  color: $white;
  overflow: hidden;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.4em;
  display: table;
  width: 100%;
}

.survivor-stats_cell {
  box-shadow: 1px 0 1px rgba($black, .25);
  display: table-cell;
  padding: 2px 5px;

  &:not(.survivor-stats_cell--winner):not(.survivor-stats_cell--eliminated) {
    color: $black;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.survivor-stats_cell--winner {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: $success-color;
}

.survivor-stats_cell--eliminated {
  background-color: $error-color;
}

.survivor-stats_graph--short {
  height: 8px;
}
