//Accordion

.accordion {
  display: flex;

  @media screen and (max-width: $screen-md-max) {
    position: fixed;
    z-index: $accordion-mobile-nav-z-index;
    left: 0;
    height: $accordion-mobile-nav-height;
    width: 100%;
    bottom: 0;
    box-shadow: 0 -2px 2px rgba($black, .25);
    padding: $padding-horizontal;
    flex-direction: row-reverse;

    &::after {
      content: '';
      display: block;
      background-color: ($base-header-bkg-color);
      height: $accordion-mobile-nav-height;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    outline: 1px solid $base-header-bkg-color;
    position: fixed;
    right: 0;
    overflow: hidden;
    max-width: none;
    margin: 0;
    top: $header-height;
    right: $padding-horizontal;
    background-color: rgba($base-header-bkg-color, .25);
    width: $accordion-width;
    z-index: $accordion-z-index;
    flex-direction: column;
    height: calc(100vh - #{$header-height});

    .notification--is-present & {
      top: ($header-height + $notification-fixed-height + 1);
      height: calc(100vh - #{($header-height + $notification-fixed-height + 1)});
    }
  }
}

.accordion_item {
  margin-left: $padding-horizontal;
  &:last-child {
    margin-left: 0;
  }
  @media screen and (min-width: $screen-lg-min) {
    overflow: hidden;
    flex: 0 auto;
    padding-top: 31px;
    position: relative;
    margin-left: 0;
  }
}

.accordion_item--leaderboard {
  @media screen and (min-width: $screen-lg-min) {
    display: none;
  }
}

.accordion_item--is-expanded {
  @media screen and (min-width: $screen-lg-min) {
    flex: 1 auto;
  }
}

.accordion_content {
  left: 0;
  overflow: hidden;
  
  @media screen and (max-width: $screen-md-max) {
    background-color: rgba($lightbox-bkg-color, 0);
    position: fixed;
    top: 100%;
    width: 100%;
    height: calc(100vh - (#{$header-height-mobile} + #{$accordion-mobile-nav-height}));
    transition: top $drawer-speed-duration $drawer-timing, background-color $drawer-speed-duration $drawer-timing;
    z-index: -2;
  }
  
  @media (min-width: $screen-nav-desktop-min) and (max-width: $screen-md-max) {
    height: calc(100vh - (#{$header-height} + #{$accordion-mobile-nav-height}));
  }

  @media screen and (min-width: $screen-lg-min) {
    position: absolute;
    top: 31px;
    width: 100%;
    height: 0;
    transition: height .2s ease-in;
  }

  .accordion_item--is-expanded & {

    @media screen and (max-width: $screen-md-max) {
      background-color: $lightbox-bkg-color;
    }

    @media screen and (max-width: $screen-nav-mobile-max) {
      top: $header-height-mobile;
    }

    @media (min-width: $screen-nav-desktop-min) and (max-width: $screen-md-max) {
      top: $header-height;
    }

    @media screen and (min-width: $screen-lg-min) {
      height: calc(100% - 11px);
    }
  }
}

.accordion_body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: $screen-md-max) {
    margin: 0 auto;
    width: $leaderboard-width-large;
    height: calc(100% - 7vh);
  }

  @media screen and (max-width: $screen-iphone5-max) {
    width: 100%;
  }

  @media screen and (min-width: $screen-lg-min) {
    height: calc(100% - 16px);
  }

  .accordion_item--leaderboard & {
    overflow: hidden;
  }
}

// .accordion_title {
//   display: none;
//   background-color: $white;
//   padding: $padding-small $padding-vertical $padding-small $padding-small;
//   font-size: 14px;
//   font-weight: normal;
//   color: $medium-gray;
//   position: relative;
//   z-index: 1;
//   height: 45px;
//   transition: all $base-duration ease-out;

//   &:hover {
//     background-color: $dark-action-color;
//     text-decoration: none;
//     color: $medium-gray;

//     .accordion_icon {
//       color: $white;
//     }
//   }

//   .accordion--is-open & {
//     background-color: $white;
//     height: 30px;

//     &:hover {
//       background-color: $white;

//       .accordion_icon {
//         color: $dark-action-color;
//       }
//     }
//   }
// }

.accordion_heading {
  color: $accordion-font-color;
  font-size: 14px;
  font-weight: 300;
  line-height: 1em;
  pointer-events: none;
  padding: $padding-vertical $padding-small $padding-small;
  margin: 0 auto;
  width: $leaderboard-width-large;

  @media screen and (min-width: $screen-lg-min) {
    display: none;
  }
}

.accordion_button {

  @media screen and (max-width: $screen-md-max) {

    .badge {
      color: $action-color;
      border-color: $action-color;
    }

    &:focus {
      background-color: $white;
      color: $action-color;

      // .badge {
      //   color: $action-color;
      //   border-color: $action-color;
      // }
    }

    .accordion_item--is-expanded & {
      border-color: $dark-action-color;
      background-color: $dark-action-color;
      color: $white;

      &:focus {
        border-color: $dark-action-color;
        background-color: $dark-action-color;
        color: $white;

        .badge {
          color: $white;
          border-color: $white;
        }
      }

      .badge {
        color: $white;
        border-color: $white;
      }
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $accordion-bkg-color;
    border: none;
    border-top: 1px solid $base-header-bkg-color;
    display: block;
    width: 100%;
    padding: 7px $padding-small 8px 18px;
    transition: all $base-duration linear;
    color: $accordion-font-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    text-align: left;

    .accordion_item--is-expanded & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      [class^="dw-"] {

        &:before {
          transform: rotate(90deg);
        }
      }
    }

    &:focus {
      background-color: $accordion-bkg-color;
      border-color: $base-header-bkg-color;
    }

    &:hover {
      background-color: $accordion-hover-color;
    }

    &.btn--has-notice {
      animation: pulsecopenblue 3s linear infinite;
    }

    &.btn--has-notification {

      &::after {
        color: $white;
        background-color: transparent;
        border-color: $white;
        right: 8px;
        top: 7px;
      }
    }

    &.btn--has-caution-nag {

      &::before {
        border-color: $bkg-caution-color;
        right: 8px;
        top: 7px;
      }

      &.btn--has-notice {
        animation: pulsecautioncolor 3s linear infinite;
      }
    }

    &.btn--has-error-nag {

      &::after {
        border-color: $error-color;
        right: 8px;
        top: 7px;
      }

      &::before {
        right: 29px;
      }

      &.btn--has-notice {
        animation: pulseerrorcolor 3s linear infinite;
      }
    }

    [class^="dw-"] {
      position: absolute;
      left: 0;
      top: 0;

      &:before {
        content: '\e9cf';
        font-size: 15px;
        line-height: 1;
        position: absolute;
        left: 0;
        top: 8px;
        transition: transform 100ms linear;
        transform: rotate(0deg);
      }
    }
  }
}

.accordion_button-label {

  span {

    &:nth-child(1) {
      padding-left: 4px;

      @media screen and (max-width: $screen-xs-min) {
        display: inline-block;
        vertical-align: middle;
        max-width: 72px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:nth-child(2) {
      margin-left: 4px;
      vertical-align: middle;
    }
  }
}

.accordion_close {
  font-size: 24px;
  position: absolute;
  color: $accordion-close-color;
  top: 0;
  right: $padding-horizontal;
  opacity: 0;

  .accordion_item--is-expanded & {
    opacity: 1;
    transition: opacity $drawer-speed-duration $drawer-timing;
  }

  @media screen and (min-width: $screen-lg-min) {
    display: none;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    opacity: .5;
    color: $accordion-close-color;
  }
}
