//Logo List

.logo-list {
  display: table;
  margin: 0 auto;
  text-align: center;

 @media screen and (min-width: $medium-screen) {
  width: 80%;
 }

  > li {
    display: table-cell;
    vertical-align: middle;
    padding: 0 $padding-small;

    @media screen and (min-width: $medium-screen) {
      padding-left: $padding-medium;
      padding-right: $padding-medium;
    }
  }

  img {
    max-width: 200px;
    width: 100%;
  }
}
