//Intercom Overrides

body #intercom-container {

  .intercom-launcher-frame,
  .intercom-launcher-discovery-frame {
    right: auto !important;
    left: $padding-vertical !important;

    @media screen and (max-width: $screen-xs-max) {
      transform: scale(.75);
      left: $padding-small !important;
      bottom:  $padding-small !important;
    }
  }

  .intercom-gradient {
    right: auto !important;
    left: 0 !important;
    background: radial-gradient(ellipse at bottom left,rgba(29,39,54,.16) 0,rgba(29,39,54,0) 72%);
  }

  .intercom-notifications-frame {
    right: auto !important;
    left: $padding-vertical !important;

    @media screen and (max-width: $screen-xs-max) {
      transform: scale(.75);
      left: $padding-small !important;
      bottom:  $padding-small !important;
    }
  }

  .intercom-launcher-badge-frame {
    right: auto !important;
    left: $padding-vertical !important;
    box-shadow: 0 0 20px rgba($black, .5) !important;

    @media screen and (max-width: $screen-xs-max) {
      transform: scale(.75);
      left: $padding-small !important;
      bottom:  $padding-small !important;
    }
  }

  .intercom-messenger-frame,
  .intercom-note {
    box-shadow: 0 0 20px rgba($black, .5) !important;

    @media screen and (min-width: 450px) {
      right: auto !important;
      left: $padding-vertical !important;
    }
  }

  .intercom-notifications-dismiss-button {
    background-color: $action-color !important;
  }
}
