//Profile

.profile {

  @media screen and (max-width: $screen-xs-min) {
    position: relative;
    padding-bottom: 65px;
  }
}

.profile_picture {
  max-width: 150px;
  margin: 0 auto;
  display: block;
  position: relative;

  &:hover {
    text-decoration: none;

    .profile_label {
      opacity: 1;

      @media screen and (min-width: $screen-xs-min) {
        width: calc(100% - 2px);
      }
    }

    .profile_text {
      display: inline-block;
    }
  }
}

.profile_label {
  color: $white;
  display: block;
  position: absolute;
  opacity: .5;
  transition: opacity $base-duration linear;
  font-size: 14px;

  @media screen and (min-width: $screen-xs-min) {
    background: rgba($black, .75);
    bottom: 1px;
    left: 1px;
    padding: 2px $padding-horizontal;
  }
}

.profile_text {
  display: inline-block;
  padding-left: 2px;
  font-size: 10px;

  @media screen and (min-width: $screen-xs-min) {
    display: none;
    padding-left: $padding-small;
    font-size: 12px;
  }
}

.profile_pane {

  @media screen and (min-width: $screen-lg-min) {
    padding-left: $padding-horizontal;
  }

  a {
    color: $base-font-color;

    &:hover {
      text-decoration: none;
    }
  }
}

.profile_heading {
  opacity: .5;
  margin-bottom: 0;

  @media screen and (max-width: $screen-xs-min) {
    font-size: 12px;
  }
}

.profile_username {
  margin-bottom: 0;
  line-height: 1em;

  @media screen and (max-width: $screen-sm-min) {
    font-size: 18px;
    line-height: 1.25em;
  }

  > span {
   
    &:nth-child(1) {

      @media screen and (max-width: $screen-xs-min) {
        display: inline-block;
        vertical-align: middle;
        max-width: 185px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  a {
    font-size: .8em;
  }
}

.profile_stats {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: $padding-horizontal;
  // display: table;
  display: flex;

  @media screen and (min-width: $screen-xs-min) {
    position: static;
    margin-bottom: $padding-vertical;
  }
}

.profile_stats--static {
  position: static;
  margin-bottom: $padding-vertical;
}

.profile_stat {
  margin: 0;
  padding: 0 $padding-small;
  // display: table-cell;
  border-right: 1px solid rgba($white, .5);
  flex: 1 1 auto;

  @media screen and (min-width: $screen-md-min) {
    text-align: center;
    padding: 0 $padding-vertical;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border: 0;
  }

  dt {
    font-weight: normal;
    font-size: 10px;
    line-height: 1em;
    opacity: .5;
    margin: 0;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 12px;
    }
  }

  dd {
    font-weight: 600;
    font-size: 14px;
    margin: 0;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 28px;
    }
  }
}

.profile_badges {
  display: table;
  width: 100%;

  li {
    display: table-cell;
    vertical-align: middle;

    img {
      max-width: 60px;
    }
  }
}
