// Batch Flexbox Grid 2.0.0
// http://martskin.github.io/batch/
// GPL-2.0 License

// Breakpoints unless already defined in variables.
$medium-screen: $screen-sm-min !default;
$large-screen: $screen-lg-min !default;

// Cell Gutter
// Must be an even number
$batch-gutter: 10px;

// Cell Padding
$batch-padding: 5px;

// Cell Border
$batch-border: 1px;

// Cell Border Color
$batch-border-color: $light-gray;

// Cell Order Positions
$batch-order-positions: 9;

// Batch cells
$cell-sizes : (1: (1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 24),
  2: (3, 5),
  4 : (5),
  3: (4, 5, 8, 10, 16),
  5: (6, 8, 12, 16, 24),
  7: (8, 10, 12, 16, 24),
  9 : (10, 16),
  11: (12, 16, 24),
  13: (16, 24),
  17: (24),
  19: (24),
  23: (24));

// We'll loop through the $cell-sizes map to distill the cell classes.
// The key is the numerator and then we loop through the value array for each denominator.
// The key-value pair is used in the class name and is also used to calculate the width percentage.
// Default sizes are 3rds, 4ths, 6ths, 8ths, 12ths and 24ths:
// 1-1, 1-2, 1-3, 1-4, 1-6, 1-8, 1-12, 1-24
// 2-3
// 3-4, 3-8
// 5-6, 5-8, 5-12, 5-24
// 7-8, 7-12, 7-24
// 11-12, 11-24
// 13-24
// 17-24
// 19-24
// 23-24
@mixin small-batch($breakpoint: '') {
  @each $key, $cell-size in $cell-sizes {
    @for $i from 1 to (length($cell-size)+1) {
      $cell-size-percentage: (
        percentage(calc($key/nth($cell-size, $i)))
      );

    .batch-c#{$breakpoint}-#{$key}-#{nth($cell-size, $i)} {
      box-sizing: border-box;
      flex: 0 0 auto;
      width: $cell-size-percentage;

      .batch-g--gutter>& {
        margin: (calc($batch-gutter/2));
        width: calc(#{$cell-size-percentage} - #{$batch-gutter});
      }
    }
  }
}
}

// Flexbox cell ordering modifier classes
@mixin cell-order($breakpoint: '') {
  @for $i from 1 to ($batch-order-positions+1) {
    .batch-c-order#{$breakpoint}-#{$i} {
      order: $i;
    }
  }
}

/* Batch grid wrapper */
.batch-g {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* Batch grid modifiers */
.batch-g--border {
  >[class^="batch-c"] {
    border: $batch-border solid $batch-border-color;
  }
}

.batch-g--gutter {
  margin: 0 calc((-1 * $batch-gutter)/2);
}

.batch-g--padding {
  >[class^="batch-c"] {
    padding: $batch-padding;
  }
}

.batch-g--padding-horizontal {
  >[class^="batch-c"] {
    padding-left: $batch-padding;
    padding-right: $batch-padding;
  }
}

.batch-g--padding-vertical {
  >[class^="batch-c"] {
    padding-bottom: $batch-padding;
    padding-top: $batch-padding;
  }
}

.batch-g--reverse {
  flex-direction: row-reverse;
}

.batch-g--valign-top {
  align-items: flex-start;
}

.batch-g--valign-center {
  align-items: center;
}

.batch-g--valign-bottom {
  align-items: flex-end;
}

.batch-g--align-center {
  justify-content: center;
}

.batch-g--align-right {
  justify-content: flex-end;
}

/* Base cells */
@include small-batch();

/* Medium screen cells */
@media screen and (min-width: $medium-screen) {
  @include small-batch('-md');
}

/* Large screen cells */
@media screen and (min-width: $large-screen) {
  @include small-batch('-lg');
}

/* Medium screen cell ordering */
@media screen and (min-width: $medium-screen) {
  @include cell-order('-md');
}

/* Large screen cell ordering */
@media screen and (min-width: $large-screen) {
  @include cell-order('-lg');
}

/* Batch cell modifiers */
.batch-c--valign-self-top {
  align-self: flex-start;
}

.batch-c--valign-self-bottom {
  align-self: flex-end;
}

.batch-c--valign-self-center {
  align-self: center;
}
