//Media
.media {
  margin-bottom: $padding-horizontal;
  overflow: hidden;
}

.media--padded {

  @media screen and (min-width: $large-screen) {
    padding-left: $padding-vertical;
    padding-right: $padding-vertical;
  }
}

.media_graphic {
  float: left;
  margin-right: $padding-horizontal;
  min-width: 55px;
  text-align: center;

  @media screen and (min-width: $large-screen) {
    margin-right: $padding-vertical;
  }

  img {
    display: block;
    max-width: 80px;
  }

  .media--small & {
    margin-right: $padding-horizontal;
    min-width: 0;
  }

  .media--medium & {
    margin-right: $padding-horizontal;
  }

  .media--quote & {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid $medium-gray;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    @media screen and (min-width: $large-screen) {
      height: 100px;
      width: 100px;
    }
  }

  [class^="dw-"] {
    // color: $light-gray;
    opacity: .4;
    display: block;
    font-size: 40px;
    min-width: 24px;

    @media screen and (min-width: $large-screen) {
      font-size: 80px;
      margin-top: -5px;
    }

    .media--small & {
      margin-top: 0;
      font-size: 1.5em;
    }

    .media--medium & {
      font-size: 3em;
    }

    // .l-row--blue &,
    // .l-row--gray & {
    //   color: rgba($white, .5);
    // }

    // .l-row--light-gray & {
    //   color: $medium-gray;
    // }
  }

  [class^="dw-ticket"] {
    margin-top: -13px;

    .media--small & {
      margin-top: -5px;
    }
  }
}

.media_body {
  overflow: hidden;

  @media screen and (max-width: $large-screen) {
    font-size: 14px;
  }

  .media--small & {
    font-size: 12px;
  }

  .media--medium & {
    font-size: 14px;
  }
}

.media_heading {
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 0;

  @media screen and (min-width: $large-screen) {
    font-size: 32px;
    margin-bottom: $padding-small;
  }

  .media--small & {
    font-size: 18px;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  .media--medium & {
    font-size: 22px;
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.media_text {

  .media--quote & {
    color: $medium-gray;

    &:before,
    &:after {
      display: inline-block;
      vertical-align: bottom;
      font-size: 3em;
      font-family: Georgia, serif;
      line-height: 0;
      color: $light-gray;
    }

    &:before {
      content: '“';
      padding-right: $padding-small;
    }

    &:after {
      content: '”';
      padding-left: $padding-small;
    }
  }
}
