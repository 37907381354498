//Leaderboard

$leaderboard-border-color: $base-header-bkg-color;

.leaderboard_placeholder {
  position: relative;

  @media screen and (max-width: $screen-md-max) {
    height: 100%;
    display: none;

    .accordion & {
      display: block;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    height: ($leaderboard-list-height + 49px);
  }
}

.leaderboard {
  color: $white;

  @media screen and (max-width: $screen-md-max) {
    height: 100%;

    .lightbox {
      padding-top: ($header-height-mobile + $padding-horizontal);
      padding-bottom: ($accordion-mobile-nav-height + $padding-horizontal);
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    padding-left: $padding-small;
    width: $leaderboard-width;
    max-width: none;
    margin: 0;
    position: fixed;
    top: $header-height + $padding-vertical;
    // background-color: $base-body-bkg-color;
    z-index: $leaderboard-z-index;

    .notification--is-present & {
      top: ($header-height + $padding-vertical + $notification-fixed-height);
    }
  }

  @media screen and (min-width: 1415px) {
    width: $leaderboard-width-large;
  }

  @media screen and (min-width: 1410px) {
    padding-left: $padding-vertical;
  }
}

.leaderboard_heading {

  @media screen and (min-width: $screen-lg-min) {
    background-color: $base-header-bkg-color;
    border: $leaderboard-border-color 1px solid;
    border-bottom: none;
  }
}

.leaderboard_subheading {
  font-size: 14px;
  display: none;
  padding: $padding-small $padding-small 0;

  @media screen and (min-width: $screen-lg-min) {
    display: block;
    margin: 0;
  }
}

.leaderboard_note {
  color: rgba($white, .5);
  font-size: 12px;
  padding-left: $padding-small;
}

.leaderboard_list {
  color: $white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  border: $leaderboard-border-color 1px solid;
  background-color: $leaderboard-list-bkg-color;

  @media screen and (max-width: $screen-md-max) {
    background-color: $leaderboard-list-bkg-color;
    height: 100%;
  }

  @media screen and (min-width: $screen-lg-min) {
    transition: max-height $base-duration linear;
    max-height: calc(100vh - #{$header-height} - 86px);
    box-shadow: $component-box-shadow;

    .notification--is-present & {
      max-height: calc(100vh - #{$header-height + $notification-fixed-height} - 86px);
    }
  }
}

@mixin lightenelements() {

  .leaderboard_player {

    .leaderboard_movement--is-up  {
      color: $white;

      &::before {
        border-bottom-color: $white;
      }
    }

    .leaderboard_movement--is-down  {
      color: $white;

      &::before {
        border-top-color: $white;
      }
    }

    .leaderboard_movement--no-change {
      opacity: 1;

      &:after {
        color: $white;
      }
    }
  }
}

.leaderboard_link {
  border-bottom: 1px solid rgba($white, .15);
  color: $white;
  cursor: pointer;
  display: block;
  // transition: all $base-duration $base-timing;
  width: inherit;

  &:hover,
  &:focus {
    background-color: $copen-blue;
    text-decoration: none;

    @include lightenelements();
  }
}

.leaderboard_link--is-open {
  background-color: $copen-blue;

  @include lightenelements();
}

.leaderboard_link--is-highlighted {
  animation: highdark 1.25s ease-out;
}

.leaderboard_link--current-user {
  background-color: rgba($leaderboard-current-user-row-color, .5);

  &:hover,
  &:focus,
  &.leaderboard_link--is-open {
    background-color: $leaderboard-current-user-row-color;
  }
}

.leaderboard_link--is-eliminated {
  background-color: rgba($error-color, .25);
  border-color: rgba($error-color, .5);

  &:hover,
  &:focus,
  &.leaderboard_link--is-open {
    background-color: $error-color !important;
  }
}

.leaderboard_link--is-winner {
  background-color: $success-color;
  border-color: $dark-success-color;

  &:hover,
  &:focus,
  &.leaderboard_link--is-open {
    background-color: $dark-success-color !important;

    // .leaderboard_player_cell--winnings {
    //   color: $white;
    // }
  }
}

.leaderboard_link--is-disabled {

  &:hover {
    cursor: default;
    background-color: inherit;
  }
}

.leaderboard_player_header {
  color: $white;
  display: table;
  font-size: 11px;
  width: 100%;

  > li {
    display: table-cell;
    padding: $padding-small 2px 0;
    vertical-align: bottom;

    &:first-child {
      width: 45px;
      text-align: left;
    }

    &:nth-child(2) {
      width: 125px;

      @media screen and (min-width: $screen-ss-min) {
        width: 150px;
      }

      @media (min-width: $screen-xs-min) and (max-width: $screen-md-max) {
        width: 150px;
      }
    }

    &:nth-child(3) {
      text-align: center;
      width: 55px;
    }

    &:last-child {
      padding-right: $padding-horizontal;
      text-align: right;
    }
  }

  .btn--menu {
    border: none;
    padding: 0 15px 0 0;
    color: inherit;

    &::before {
      border-top-color: $gray;
      right: 4px;
    }

    &.btn--menu--is-open,
    &:hover {
      color: $white;

      &::before {
        border-top-color: $white;
      }
    }

    .btn_list {
      left: auto;
      right: -1px;
      top: calc(100% + 2px);
    }
  }
}

.leaderboard_player {
  display: table;
  font-size: 12px;
  width: 100%;
}

.leaderboard_player_cell {
  display: table-cell;
  padding: 2px;
  vertical-align: middle;

  .tooltip {
    display: table;
  }
}

.leaderboard_player_cell--rank {
  text-align: center;
  width: 26px;
}

.leaderboard_player_cell--movement {
  padding-left: 0;
  padding-right: 0;
  width: 19px;
}

.leaderboard_player_cell--name {
  width: 125px;
  word-break: break-word;
  padding-left: 0;

  @media screen and (min-width: $screen-ss-min) {
    width: 150px;
  }

  @media (min-width: $screen-xs-min) and (max-width: $screen-md-max) {
    width: 150px;
  }
}

.leaderboard_player_cell--pick {
  font-size: 10px;
  line-height: 1.5em;
  padding-left: 0;
  width: 55px;
  text-align: center;
}

.leaderboard_player_cell--winnings {
  // color: $success-color;
  font-size: 14px;
  font-weight: 700;
  line-height: 1em;

  [class^="dw-"] {
    position: absolute;

    & + span {
      padding-left: 16px;
      display: block;
    }
  }
}

.leaderboard_player_cell--score {
  font-weight: 700;
  padding-right: $padding-horizontal;
  text-align: right;
}

.leaderboard_player_cell--action {
  width: 28px;
  text-align: left;
}

.leaderboard_username {
  display: table;
  line-height: 1.25em;
}

.leaderboard_username_cell {
  display: table-cell;
  vertical-align: middle;

  &:nth-child(2) {
    padding-left: 4px;
  }

  .avatar {
    border-color: $base-border-color;
  }
}

.leaderboard_movement {
  display: block;
  height: 12px;
  position: relative;
  font-size: 9px;
  line-height: 1em;
  width: 19px;

  &::after {
    top: -1px;
    content: attr(data-leaderboard-movement);
    position: absolute;
    text-align: center;
    width: 100%;
  }

  &::before {
    bottom: 0;
    content: '';
    height: 0;
    left: calc(50% - 4px);
    position: absolute;
    width: 0;
  }
}

.leaderboard_movement--is-up {
  color: $success-color;

  .leaderboard_player & {
    color: $white;
    opacity: .75;
  }

  &::before {
    border-bottom: 4px solid $success-color;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    .leaderboard_player & {
      border-bottom-color: $white;
    }
  }
}

.leaderboard_movement--is-down {
  color: $red;

  .leaderboard_player & {
    color: $white;
    opacity: .75;
  }

  &::after {
    top: auto;
    bottom: -3px;
  }

  &::before {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid $red;
    top: 0;
    bottom: auto;

    .leaderboard_player & {
      border-top-color: $white;
    }
  }
}

.leaderboard_movement--no-change {
  opacity: .75;

  &::after {
    content: '--';
    letter-spacing: .15em;
    top: 3px;
  }

  &::before {
    display: none;
  }
}

.leaderboard_name {
  display: block;
  // font-weight: 700;
  line-height: 1em;
  letter-spacing: .05em;

  span {

    &:nth-child(1) {
      display: inline-block;
      vertical-align: middle;
      max-width: 94px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: $screen-xs-min) {
        max-width: 69px;
      }

      @media (min-width: $screen-lg-min) and (max-width: $screen-ss-min) {
        max-width: 69px;
      }
    }

    &:nth-child(2) {
      padding-left: 2px;
      vertical-align: middle;
    }
  }
}

.leaderboard_ticket {
  display: block;
  font-size: 9px;
  opacity: .5;
}

.leaderboard_meta {
  color: gray;
  display: none;

  li {
    display: inline-block;
    padding-right: $padding-small;
  }
}

.leaderboard_social {

  &:hover {
    text-decoration: none;
  }
}


.leaderboard_picks {
  background-color: $white;
  max-height: 0;
  overflow: hidden;
  color: $black;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {//target IE10+
    transition: max-height .1s ease-out;
  }
  @supports (-ms-ime-align:auto) {//target Edge
    transition: max-height .1s ease-out;
  }

  .leaderboard_link--is-open & {
    animation: slidedowntall 1s ease-out forwards;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {//target IE10+
      animation: none;
      max-height: 700px;
    }
    @supports (-ms-ime-align:auto) {//target Edge
      animation: none;
      max-height: 700px;
    }
  }
}

.leaderboard_picks_table {
  display: table;
  font-size: 10px;
  line-height: 12px;
  width: 100%;

  &:nth-child(even):not(.leaderboard_picks_table--header) {
    background-color: rgba($gray, .08);
  }
}

.leaderboard_picks_row {
  display: table-row;
}

.leaderboard_picks_cell {
  display: table-cell;
  vertical-align: middle;
  padding: 2px;
}

.leaderboard_picks_cell--race {
  font-size: 10px;
  text-align: center;
  width: 27px;

  .leaderboard_picks_table--header & {
    text-align: left;
  }
}

.leaderboard_picks_cell--track {
  width: 40px;
}

.leaderboard_picks_cell--movement {
  width: 19px;
  padding-left: 0;
  padding-right: 0;
}

.leaderboard_picks_cell--rank {
  width: 24px;
  text-align: center;

  .leaderboard_picks_table--header & {
    text-align: left;
  }
}

.leaderboard_picks_cell--horse {
  width: 70px;
  word-break: break-all;
  line-height: 1em;

  @media screen and (min-width: $screen-ss-min) {
    width: 90px;
  }
}

.leaderboard_picks_cell--pick {
  padding-left: 0;
  width: 25px;
  text-align: center;
}

.leaderboard_picks_cell--odds {
  font-size: 10px;
  width: 26px;
}

.leaderboard_picks_cell--score {
  padding-right: 10px;
  text-align: right;
}

.leaderboard_picks_cell--results {
  width: 20px;
}

.leaderboard_towel {
  display: inline-block;
  padding: 0 1px;
  width: 25px;
  vertical-align: top;

  > div {
    line-height: 1.25em;
    padding-top: 2px;
  }
}

.leaderboard_picks_table--header {
  background-color: rgba($gray, .1);
  color: $gray;

  > li {
    border-bottom: 1px solid $light-gray;
    border-top: 1px solid $light-gray;
  }
}

.leaderboard_label {
  color: $success-color;
  font-size: 14px;
  line-height: 1em;
  font-weight: 700;
  position: relative;
  top: -1px;
  text-align: left;
}

.leaderboard_icon {
  position: absolute;
  left: -28px;
  width: 24px;
  top: 0;
  text-align: right;
  font-size: 15px;
}

.leaderboard_action {
  display: none;
  font-size: 24px;
  position: absolute;
  color: $white;
  right: $padding-horizontal;

  &:hover,
  &:focus {
    text-decoration: none;
    opacity: .5;
    color: $white;
  }
}
