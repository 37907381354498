//Notification

$notification-icon-top: 14px !default;
$notification-subtle-icon-top: 9px !default;

.notification_placeholder {
  height: $notification-fixed-height;
}

.notification {
  background-color: $light-notice-color;
  color: $notice-color;
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-out;

  .lightbox &:not(.notification--subtle) {
    margin: (-$padding-vertical) (-$padding-vertical) $padding-vertical;
  }

  a {
    color: $notice-color;

    &:hover {
      opacity: .6;
    }
  }

  .btn {
    border-color: $notice-color;

    &:hover,
    &:focus {
      color: $notice-color;
      opacity: 1;
    }
  }

  p {
    margin-bottom: $padding-horizontal;
  }

  p + .btn {
    margin-top: $padding-horizontal;
  }

  ol,
  ul {
    margin-bottom: $padding-vertical;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .tournament-table_row--details & {
    margin-left: -5px;
    margin-right: -5px;
  }

  & + h3 {
    margin-top: $padding-vertical;
  }
}

.notification--subtle {
  border: 1px solid $notice-color;
  font-size: 14px;
  line-height: 1.25em;
  margin-bottom: $padding-horizontal;

  &.notification:not(.notification--is-visible) {
    border: 0;
  }

  .notification_content {
    padding-bottom: 10px;
    padding-top: 15px;
    padding-left: 30px;
  }

  ol,
  ul {
    margin-bottom: $padding-horizontal;
  }

  .notification_icon {
    font-size: 20px;
    top: $notification-subtle-icon-top;
    left: 7px;
  }

  .notification_title {
    font-size: 18px;
  }
}

.notification--is-visible {
  max-height: 800px;
}

.notification--is-fixed {
  position: fixed;
  width: 100%;
  z-index: $notification-z-index;
}

@mixin notification-variant($modifier, $background-color, $color, $light-color) {
  .notification--#{$modifier} {
    background-color: $background-color;
    color: $color;

    a {
      color: $color;
    }

    .btn {
      border-color: $color;

      &:hover,
      &:focus {
        color: $color;
      }
    }

    &.notification--subtle {
      background-color: $light-color;
      @if $modifier == "caution" {
        border-color: $color;
      } @else {
        border-color: $background-color;
        color: $background-color;

        a {
          color: $background-color;
        }

        .btn {
          border-color: $background-color;
          color: $background-color;

          &:hover,
          &:focus {
            color: $background-color;
          }
        }
      }
    }
  }
}

@include notification-variant("caution", $bkg-caution-color, $dark-gray, $light-caution-color);

@include notification-variant("error", $error-color, $white, $light-error-color);

@include notification-variant("success", $success-color, $white, $light-success-color);

.notification_content {
  @include wrapper();
  @include wrapper-ss();
  position: relative;
  padding: $padding-vertical 30px 16px 50px;
}

.notification_icon {
  position: absolute;
  left: 20px;
  top: $notification-icon-top;
  font-size: 25px;
  line-height: 1em;
}

.notification_title {
  margin-bottom: $padding-small;
  line-height: 1em;

  > span {
    padding-right: $padding-horizontal;
  }

  .btn {

    @media screen and (max-width: $screen-xs-max) {
      font-size: 10px;
      padding: 4px 5px;
      line-height: .75em;
    }

    @media screen and (min-width: $screen-sm-min) {
      vertical-align: bottom;
      top: -3px;
      position: relative;
    }
  }
}

.notification_dismiss {
  position: absolute;
  right: $padding-horizontal;
  top: 18px;
  font-size: 20px;
  line-height: 1;

  @media screen and (max-width: $screen-xs-max) {
    top: 15px;
  }

  &:hover {
    text-decoration: none;
  }
}

.notification_body {

  p {

    &:last-child {
      margin-bottom: 0;
    }
  }
}
