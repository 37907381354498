//Product

.product_heading_content {

  @media screen and (max-width: $screen-xs-min) {
    border-bottom: 1px solid $transparent-gray;
    padding-bottom: $padding-small;
  }

  @media screen and (min-width: $screen-lg-min) {
    border-bottom: 1px solid $transparent-gray;
  }
}

.product_heading {
  cursor: pointer;
  position: relative;

  .tournament-table_meta_cell--cta {

    .btn {
      min-width: 105px;

      @media screen and (max-width: $screen-xs-min) {
        width: 100%;
        max-width: 320px;
      }
    }
  }

  @media screen and (max-width: $screen-xs-min) {
    margin-bottom: 82px;
  }
}

.product_title {
  font-size: 30px;
  line-height: 1em;
  margin-bottom: 0;

  @media screen and (max-width: $screen-xs-max) {
    font-size: 20px;
    margin-bottom: $padding-small;
    padding-right: 36px;
  }

  .game-page--is-live-bankroll & {
    color: $lime;
  }
}

.product_subtitle {
  margin-bottom: 0;

  @media screen and (max-width: $screen-wall-max) {
    margin-bottom: 25px;
    font-size: 14px;
  }
}

.product_subtitle_label {
  padding-right: $padding-horizontal;
  opacity: .5;
}

.product_subtitle_link {
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;

  @media screen and (max-width: $screen-xs-min) {
    display: block;
    position: absolute;
    left: 0;
    bottom: -82px;
    width: 100%;
    text-align: center;
  }

  &:hover {
    color: $action-color;
    text-decoration: underline;
  }
}

.product_share {
  display: table;
  line-height: 1em;
  margin: 0;
  position: absolute;
  right: 0;
  top: $padding-vertical;

  dt {
    color: $gray;
    display: table-cell;
    font-size: 10px;
    font-weight: 300;
    margin: 0;
    padding-right: $padding-small;
    vertical-align: middle;
  }

  dd {
    display: table-cell;
    font-size: 18px;
  }
}

.product_share_link {
  color: $gray;
  margin-right: $padding-small;

  &:hover,
  &:focus {
    color: $action-color;
  }
}

.product_share_link--facebook {

  &:hover,
  &:focus {
    color: $navy;
  }
}

.product_share_link--twitter {

  &:hover,
  &:focus {
    color: $copen-blue;
  }
}

.product_info {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s $base-timing;
  position: relative;
  
  .product_info--is-open & {
    max-height: none;
    overflow: visible;
  }
}

.product_info_rules {
  font-size: 12px;
  line-height: 1.25em;
  margin: 0 0 $padding-horizontal;
  // padding-left: $padding-small;
  padding: 0 $padding-small;
  text-align: left;

  // @media screen and (max-width: $screen-md-max) {
  //   padding: 0 $padding-small;
  // }

  dt {
    font-weight: 300;
    margin: 0;
    color: $white;
    opacity: .75;
  }
}

.product_info_rules--is-inline {
  margin-bottom: 0;

  dt {
    display: inline-block;
    padding-right: $padding-horizontal;
  }

  dd {
    display: inline-block;
  }
}
