// Towel

@mixin createTowels($number, $background, $color) {
  .game-table_row--horses-#{$number} {

    .game-table_horse {

      &:before {
        background-color: $background;
        border-color: $color;
        color: $color;
        content: '#{$number}';
        outline-color: $background;
      }
    }

    .game-table_player-picks {

      dt {
        background-color: $background;
      }
    }
  }

  .towel--#{$number} {
    background-color: $background;
    border-color: $color;
    color: $color;
    outline-color: $background;

    &:after {
      content: '#{$number}';
    }
  }
}

.towel {
  background-color: $gray;
  border-color: $white;
  border-style: solid;
  border-width: 1px;
  color: $white;
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  height: 19px;
  line-height: 1.5em;
  margin: 2px;
  outline-color: $gray;
  outline-style: solid;
  outline-width: 1px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 19px;

  &:after {
    content: '0';
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }
}

.towel--empty {
  background-color: $light-gray;
  border: none;
  color: $dark-gray;
  outline: none;

  &:after {
    content: '--';
    letter-spacing: .08em;
  }
}

@mixin outlineTowel($color: $black) {
  outline-width: 1px;
  outline-style: solid;
  outline-color: $color;
  width: 13px;
  height: 13px;
  line-height: 1.3em;
}

.towel--small {
  outline: none;
  border: none;
  width: 15px;
  height: 15px;
  font-size: 10px;
  margin: 1px;

  &.towel--2 {
    @include outlineTowel();
  }

  .leaderboard &.towel--1,
  .leaderboard &.towel--3,
  .leaderboard &.towel--5,
  .leaderboard &.towel--6,
  .leaderboard &.towel--12,
  .leaderboard &.towel--17 {
    @include outlineTowel(rgba($white, .5));
  }
}

.towel--medium {
  height: 24px;
  width: 24px;
  font-size: 15px;
}

.towel--large {
  height: 31px;
  width: 31px;
  font-size: 21px;
}

.towel--is-saved {
  animation: appearscaleup .3s;
}

@include createTowels("1", $red, $white);
@include createTowels("2", $white, $black);
@include createTowels("3", $blue, $white);
@include createTowels("4", $yellow, $black);
@include createTowels("5", $green, $white);
@include createTowels("6", $black, $white);
@include createTowels("7", $orange, $white);
@include createTowels("8", $pink, $black);
@include createTowels("9", $turquoise, $black);
@include createTowels("10", $purple, $white);
@include createTowels("11", $gray, $white);
@include createTowels("12", $lime, $white);
@include createTowels("13", $brown, $white);
@include createTowels("14", $maroon, $white);
@include createTowels("15", $khaki, $black);
@include createTowels("16", $copen-blue, $white);
@include createTowels("17", $navy, $white);
@include createTowels("18", $forest-green, $white);
@include createTowels("19", $moonstone, $black);
@include createTowels("20", $fuchsia, $white);
