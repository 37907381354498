//Steps

.steps {
  font-size: 12px;
  color: $base-font-color;
  text-align: center;
  margin: $padding-horizontal auto 50px;
  border-top: 1px solid rgba($base-font-color, .4);
  position: relative;
  max-width: 600px;
  opacity: .85;
  width: 95%;

  @media screen and (max-width: $screen-xs-max) {
    margin-top: $padding-horizontal;
    margin-bottom: $padding-vertical;
  }
}

.steps_table {
  display: table;
  width: 100%;
  position: relative;

  &::before {
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    content: '';
    height: 6px;
    width: 1px;
    border-left: 1px solid $base-font-color;
  }

  &::after {
    display: block;
    position: absolute;
    content: '';
    height: 7px;
    width: 7px;
    border-radius: 50%;
    border: 1px solid $base-font-color;
    left: -3px;
    top: -12px;
  }
}

.steps_cell {
  padding: $padding-small;
  display: table-cell;
  opacity: .4;
  position: relative;

  .steps--has-2 & {
    width: 50%;
  }

  .steps--has-3 & {
    width: 33.33%;
  }

  .steps--has-4 & {
    width: 25%;
  }

  &::before {
    position: absolute;
    display: none;
    content: '';
    height: 1px;
    width: 50%;
    border-top: 1px solid $base-font-color;
    top: -1px;
    left: 0;
  }

  &:first-child {
    text-align: left;
    padding-left: 0;
    opacity: 1;

    &::before {
      display: block;
      width: 19px;
    }

    .steps--is-step-2 &,
    .steps--is-step-3 &,
    .steps--is-step-4 & {

      &::before {
        width: 100%;
      }
    }
  }

  &:nth-child(2) {

    .steps--is-step-2 &,
    .steps--is-step-3 &,
    .steps--is-step-4 &,
    .steps--has-3.steps--is-step-2 & {
      opacity: 1;

      &::before {
        display: block;
      }
    }

    .steps--is-step-3 &,
    .steps--is-step-4 & {

      &::before {
        width: 100%;
      }
    }
  }

  &:nth-child(3) {

    .steps--is-step-3 &,
    .steps--is-step-4 & {
      opacity: 1;

      &::before {
        display: block;
      }
    }

    .steps--is-step-4 & {

      &::before {
        width: 100%;
      }
    }
  }

  &:last-child {
    text-align: right;
    padding-right: 0;

    .steps--is-step-4 &,
    .steps--has-2.steps--is-step-2 &,
    .steps--has-3.steps--is-step-3 & {
      opacity: 1;

      &::before {
        display: block;
        width: 100%;
      }
    }
  }
}

.steps_icon-indicator {
  position: absolute;
  top: -18px;
  left: 10px;
  font-size: 18px;
  transition: left $base-duration ease-out;

  .steps--has-3.steps--is-step-2 & {
    left: calc(50% - 9px);
  }

  .steps--has-4.steps--is-step-2 & {
    left: calc(37.5% - 9px);
  }

  .steps--has-4.steps--is-step-3 & {
    left: calc(62.5% - 9px);
  }

  .steps--is-step-4 &,
  .steps--has-2.steps--is-step-2 &,
  .steps--has-3.steps--is-step-3 & {
    left: auto;
    right: 5px;
  }
}

.steps_icon-finish {
  position: absolute;
  right: -13px;
  top: -14px;
  font-size: 14px;
  opacity: .4;

  .steps--is-step-4 &,
  .steps--has-2.steps--is-step-2 &,
  .steps--has-3.steps--is-step-3 & {
    opacity: 1;
  }
}
