//Nav
$nav-desktop-link-border-color: $action-color !default;
$nav-link-cta-bkg-color: $action-color !default;
$nav-link-font-weight: 300 !default;
$nav-font-family: $base-font-family !default;
$nav-text-transform: none !default;
$nav-link-desktop-font-size: 16px !default;
// $nav-link-secondary-font-size: 12px !default;
$nav-item-padding: $padding-small !default;

.nav {
  padding: 0 $padding-small;
  font-family: $nav-font-family;
  text-transform: $nav-text-transform;
  @include wrapper();
  @include wrapper-ss();

  @media screen and (min-width: $screen-nav-desktop-min) {
    padding: 0 $padding-horizontal;
  }
}

.nav_table {
  display: table;
  width: 100%;
  text-align: center;
  
  @media screen and (min-width: $screen-nav-desktop-min) {
    table-layout: fixed;
  }
}

.nav_table--sub {
  margin: 0 auto;
  width: auto;

  @media screen and (max-width: $screen-nav-mobile-max) {
    background-color: $nav-mobile-bkg-color;
    position: absolute;
    top: 44px;
    left: 0;
    padding-bottom: 2px;
    width: 100%;
  }
}

.nav_item {
  display: table-cell;
  vertical-align: middle;
  padding: $nav-item-padding;
  transition: width .15s linear;
}

.nav_item--secondary {
  display: none;

  @media screen and (min-width: $screen-lg-min) {
    display: table-cell;
  }
}

.nav_item--logo {
  width: 90px;
  padding: $padding-small 0;
  
  @media screen and (min-width: $screen-nav-desktop-min) {
    width: 134px;
  }
}

.nav_item--login {
  width: 75px;
  
  @media screen and (min-width: $screen-nav-desktop-min) {
    width: 85px;
  }
}

.nav_item--username {
  width: 45px;

  @media screen and (min-width: $screen-nav-desktop-min) {
    width: 215px;
  }
}

.nav_item--deposit {
  width: 64px;

  @media screen and (min-width: $screen-nav-desktop-min) {
    width: 165px;
  }
}

.nav_item--alarm {
  width: 60px;
}

.nav_item--action {
  display: none;
  width: 55px;
}

.nav_logo {
  display: block;
  line-height: 0;
  width: 90px;
  height: 34px;
  transition: all $base-duration linear;
  background: $brand-nav-logo-bkg-values;

  @media screen and (min-width: $screen-nav-desktop-min) {
    width: 170px;
    height: 50px;
  }

  &:hover {
    transform: scale(1.05, 1.05);
  }
}

.nav_link {
  display: inline-block;
  color: $nav-mobile-link-color;
  font-size: 12px;
  font-weight: $nav-link-font-weight;
  line-height: 1em;
  padding: $padding-small;

  @media screen and (min-width: $screen-nav-desktop-min) {
    border: 1px solid transparent;
    border-radius: $base-border-radius;
    font-size: $nav-link-desktop-font-size;
    padding: $padding-small 10px;
    color: $nav-desktop-link-color;

    &:hover {
      border-color: $nav-desktop-link-border-color;
    }
  }

  &:hover {
    color: $nav-desktop-link-color;
    text-decoration: none;
  }

  // .nav_item--secondary & {
  //   border: 0;
  //   display: block;
  //   font-size: $nav-link-secondary-font-size;
  //   opacity: .75;
  //   padding: 0;

  //   &:hover {
  //     opacity: 1;
  //   }
  // }
}

.nav_link--cta {
  border: 1px solid transparent;
  border-radius: $base-border-radius;
  border-color: $action-color;
  background-color: $nav-link-cta-bkg-color;
  white-space: nowrap;

  &:hover {
    background-color: $dark-action-color;
    border-color: $dark-action-color;
    color: $white;
  }
}

.nav_link--icon {
  padding: 0 $padding-small 2px;
  font-size: 25px;
}

.nav_menu {
  display: table;

  @media screen and (max-width: $screen-nav-mobile-max) {

    &.nav_menu--is-open {
      background-color: $nav-mobile-menu-bkg-color;
      outline: 5px solid $nav-mobile-menu-bkg-color;
    }
  }

  @media screen and (min-width: $screen-nav-desktop-min) {
    position: relative;
  }
}

.nav_menu-label {

  .nav_link {
    position: relative;
    display: block;
    padding-right: 23px;
    z-index: #{$nav-menu-z-index + 3};

    @media screen and (max-width: $screen-nav-mobile-max) {
      padding: 0;
      width: 10px;
    }

    .nav_menu--is-open & {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:after {
      border-top: 4px solid $white;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      content: '';
      height: 0; 
      right: -1px;
      position: absolute;
      top: calc(50% - 2px);
      width: 0;

      @media screen and (min-width: $screen-nav-desktop-min) {
        right: 8px;
      }

      .nav_menu--is-open & {
        border-top: 0;
        border-bottom: 4px solid $white;
      }
    }
  }

  &:hover {
    text-decoration: none;

    .nav_link {
      border-color: $action-color;
    }
  }

  .nav_menu--is-open & {

    .nav_link {
      border-color: $action-color;
      background-color: $action-color;
    }
  }
}

.nav_menu-label--has-notification {

  &:after {
    background-color: $action-color;
    border-radius: 10px;
    border: 1px solid $brand-dark-primary-color;
    box-shadow: -1px 1px 2px rgba($black, .2);
    color: $white;
    content: attr(data-amount);
    display: block;
    font-size: 10px;
    height: 18px;
    line-height: 1em;
    min-width: 18px;
    padding: 3px;
    position: absolute;
    left: 24px;
    top: 4px;
    z-index: #{$nav-menu-z-index + 4};
    animation: pulsate .3s ease-out;

    .nav_menu--is-open & {
      background-color: $white;
      color: $action-color;
      border-color: $action-color;
    }
  }

  .nav_link {

    &:after {
      display: none;
    }
  }
}

.nav_menu-content {
  display: none;
  background-color: $nav-mobile-menu-bkg-color;
  position: fixed;
  z-index: $nav-menu-z-index;
  right: 0;
  opacity: 0;
  min-width: 100%;
  height: 100%;
  top: 41px;
  color: $nav-mobile-menu-font-color;

  @media screen and (min-width: $screen-nav-desktop-min) {
    box-shadow: 0 2px 10px rgba($black, .8);
    background-color: $white;
    top: auto;
    border-radius: $base-border-radius;
    border-top-right-radius: 0;
    border: 1px solid $action-color;
    position: absolute;
    height: auto;
    color: $dark-gray;
  }

  .nav_menu--is-open & {
    display: block;
    animation: appear $base-duration linear forwards;
  }
}

.nav_username {
  display: table;
  margin: 0;

  dt,
  dd {
    display: table-cell;
    vertical-align: middle;
    margin: 0;

    @media screen and (min-width: $screen-nav-desktop-min) {
      vertical-align: bottom;
    }
  }

  dt {
    width: 35px;
  }

  img {
    background-color: $white;
  }

  .avatar {
    display: block;
    border-color: $light-gray;
    transition: width $base-duration linear;
    width: 28px;
    height: 28px;

    @media screen and (max-width: $screen-nav-mobile-max) {
      margin: 0 auto;
    }
  }
}

.nav_username-label {

  @media screen and (max-width: $screen-nav-mobile-max) {
    display: none;
  }
}

.nav_balance {

  @media screen and (max-width: $screen-nav-mobile-max) {
    display: none;
  }

  color: $white;
  display: inline-block;
  vertical-align: middle;
  margin: 0 $padding-horizontal 0 0;

  dt {
    font-weight: normal;
    font-size: 10px;
    margin: 0;
    opacity: .75;
  }

  dd {
    font-weight: 600;
    font-size: 12px;
    line-height: 1.25em;
  }
}

.nav_menu-list {
  text-align: left;
  font-size: 18px;
  padding: $padding-horizontal 0;

  @media screen and (min-width: $screen-nav-desktop-min) {
    font-size: 14px;
    padding-top: $padding-small;
    padding-bottom: $padding-small;
  }
}

.nav_menu-item--info {

  @media screen and (min-width: $screen-nav-desktop-min) {
    display: none;
  }
}

.nav_menu-item--border-top {
  border-top: 1px solid $transparent-gray;

  @media screen and (min-width: $screen-nav-desktop-min) {
    border-bottom: 1px solid $light-gray;
  }
}

.nav_menu-item--border-bottom {
  border-bottom: 1px solid $transparent-gray;

  @media screen and (min-width: $screen-nav-desktop-min) {
    border-bottom: 1px solid $light-gray;
  }
}

.nav_menu-link {
  padding: 8px 15px;
  display: block;
  white-space: nowrap;
  font-weight: 400;
  color: $nav-mobile-menu-item-link-color;

  @media screen and (min-width: $screen-nav-desktop-min) {
    padding: 2px $padding-horizontal 2px $padding-small;
    font-weight: 100;
    color: $action-color;
  }

  &:hover {
    text-decoration: none;
    background-color: $action-color;
    color: $white;
  }

  > [class^="dw-"] {
    width: 20px;
    display: inline-block;
    text-align: center;
    opacity: .65;

    @media screen and (min-width: $screen-nav-desktop-min) {
      opacity: .5;
    }
  }
}

.nav_menu-link-not {
  padding: 8px 15px;
  display: block;

  @media screen and (min-width: $screen-nav-desktop-min) {
    padding: 2px $padding-horizontal 2px $padding-small;
  }
}

.nav_menu_action {
  color: $nav-mobile-menu-close-color;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: $padding-horizontal;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.nav_menu-username {
  margin: 0;
}
