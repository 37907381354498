//Selector

.selector_container {
  margin-bottom: $padding-vertical;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media screen and (min-width: $screen-sm-min) {
    margin-bottom: $padding-large;
  }
}

.selector {
  color: $dark-gray;
  // display: inline-block;
  // vertical-align: middle;
  width: 50%;
  // min-width: 150px;
  // margin: $padding-small 8px;
  margin: $padding-small 0;
  padding: 0 8px;
  position: relative;

  @media screen and (min-width: $screen-sm-min) {
    width: 25%;
  }
}

.selector--mock {
  width: auto;
}

.selector_input {
  display: none;
}

.selector_label {
  border-radius: $base-border-radius;
  border: 4px solid $light-gray;
  background-color: $white;
  padding: $padding-horizontal $padding-vertical;
  height: 170px;
  opacity: .85;
  text-align: center;
  transition: all .1s linear;

  &:hover,
  &:focus {
    cursor: pointer;
    border-color: rgba($action-color, .5);
    opacity: 1;
    transform: scale(1.05);

    .btn {
      background-color: $action-color;
      color: $white;
    }
  }

  .selector--is-basic & {
    height: 140px;
  }

  .selector--mock & {

    &:hover,
    &:focus {
      cursor: default;
    }
  }

  .form_input-prefix {
    margin-bottom: $padding-horizontal;
    font-weight: 400;
  }

  .selector_input:checked + &,
  .selector--mock & {
    border-color: $action-color;
    box-shadow: 0 0 8px $action-color;
    opacity: 1;
    transform: scale(1.05);

    @media screen and (min-width: $screen-sm-min) {
      transform: scale(1.1);
    }

    .selector_bonus {
      margin-bottom: $padding-small;
    }

    .form_input-prefix {
      margin-bottom: 3px;
    }

    .selector_icon {
      display: block;
      animation: pulsate .3s linear;
    }

    .btn {
      display: none;

       & + .selector_bonus {
        margin-top: $padding-horizontal;
      }
    }
  }
}

.selector_amount {
  font-family: $heading-font-family;
  font-weight: 700;
  font-size: 40px;
  line-height: 1em;
  display: block;

  .selector--is-basic & {
    padding-top: 12px;
  }
}

.selector_bonus {
  color: $success-color;
  font-weight: 400;
  font-size: 18px;
  display: block;
  margin-bottom: $padding-medium;

  .btn + & {
    margin-top: $padding-small-medium;
  }
}

.selector_icon {
  display: none;
  font-size: 55px;
  color: $action-color;
}

.selector_note {
  display: block;
  font-size: 16px;
  // color: $gray;
  margin-bottom: $padding-horizontal;
}
