//Game Summary

.game-summary_pick {
  border-right: 1px solid $light-gray;
  padding-bottom: $padding-horizontal;
  padding-top: $padding-horizontal;

  &:last-child {
    border: 0;
  }
}

.game-summary_pick--is-selected {
  background-color: rgba($copen-blue, .2);
  opacity: 1;
}

.game-summary_label {
  font-weight: 700;
}

.game-summary_label--stacked {
  display: inline-block;
  position: relative;
  margin-top: 14px;

  &::before {
    color: $gray;
    content: attr(aria-label);
    display: block;
    position: absolute;
    width: 100%;
    top: -18px;
    left: 0;
    font-size: 12px;
    font-weight: 100;
  }

  &:empty {
    display: none;
  }
}

.game-summary_tracks {

  > li {

    @media screen and (min-width: $screen-lg-min) {
      display: inline-block;

      &::after {
        display: inline-block;
        padding-right: $padding-small;
        content: ',';
      }

      &:last-child {

        &::after {
          display: none;
        }
      }
    }
  }
}
